import { css, unsafeCSS } from 'lit';

const UUIBlinkKeyframes = css`
  @keyframes uui-blink {
    0%,
    100% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
  }
`;
const UUIBlinkAnimationValue = unsafeCSS("uui-blink 0.9s infinite both");

const UUIHorizontalPulseKeyframes = css`
  @keyframes pulse {
    0% {
      -webkit-transform: translate(-50%, -50%) scale(0.2);
      transform: translate(-50%, -50%) scale(0.2);
      opacity: 0.9;
    }
    80% {
      -webkit-transform: translate(-50%, -50%) scale(1.2);
      transform: translate(-50%, -50%) scale(1.2);
      opacity: 0;
    }
    100% {
      -webkit-transform: translate(-50%, -50%) scale(2.2);
      transform: translate(-50%, -50%) scale(2.2);
      opacity: 0;
    }
  }
`;
const UUIHorizontalPulseAnimationValue = unsafeCSS(
  "pulse 0.8s ease-in-out infinite both"
);

const UUIHorizontalShakeKeyframes = css`
  @keyframes uui-horizontal-shake {
    10%,
    90% {
      transform: translateX(-1px);
    }

    20%,
    80% {
      transform: translateX(1px);
    }

    30%,
    50%,
    70% {
      transform: translateX(-2px);
    }

    40%,
    60% {
      transform: translateX(2px);
    }
  }
`;
const UUIHorizontalShakeAnimationValue = unsafeCSS(
  "uui-horizontal-shake 600ms ease backwards"
);

export { UUIBlinkAnimationValue, UUIBlinkKeyframes, UUIHorizontalPulseAnimationValue, UUIHorizontalPulseKeyframes, UUIHorizontalShakeAnimationValue, UUIHorizontalShakeKeyframes };
