import { defineElement } from '@umbraco-ui/uui-base/lib/registration';
import { demandCustomElement } from '@umbraco-ui/uui-base/lib/utils';
import { css, html } from 'lit';
import { UUIInputElement } from '@umbraco-ui/uui-input/lib';
import { iconLock, iconUnlock } from '@umbraco-ui/uui-icon-registry-essential/lib/svgs';
import { property } from 'lit/decorators.js';
import { UUIEvent } from '@umbraco-ui/uui-base/lib/events';

class UUIInputLockEvent extends UUIEvent {
  constructor(evName, eventInit = {}) {
    super(evName, {
      ...{ bubbles: true },
      ...eventInit
    });
  }
}
UUIInputLockEvent.LOCK_CHANGE = "lock-change";

var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp(target, key, result);
  return result;
};
let UUIInputLockElement = class extends UUIInputElement {
  constructor() {
    super();
    this.locked = true;
    this.readonly = true;
  }
  connectedCallback() {
    super.connectedCallback();
    demandCustomElement(this, "uui-icon");
    demandCustomElement(this, "uui-button");
  }
  _onLockToggle() {
    this.readonly = this.locked = !this.locked;
    this.pristine = false;
    this.dispatchEvent(new UUIInputLockEvent(UUIInputLockEvent.LOCK_CHANGE));
  }
  renderIcon() {
    return this.locked === true ? html`<uui-icon name="lock" .fallback=${iconLock.strings[0]}></uui-icon>` : html`<uui-icon
          name="unlock"
          .fallback=${iconUnlock.strings[0]}></uui-icon>`;
  }
  renderPrepend() {
    return html`<uui-button
      .disabled=${this.disabled}
      @click=${this._onLockToggle}
      compact
      id="lock"
      label="${this.locked ? "Unlock input" : "Lock input"}">
      ${this.renderIcon()}
    </uui-button>`;
  }
};
UUIInputLockElement.styles = [
  ...UUIInputElement.styles,
  css`
      #lock {
        height: 100%;

        --uui-button-padding-left-factor: 0.75;
        --uui-button-padding-right-factor: 0.75;
        font-size: 12px;
      }

      :host([locked]) #input {
        cursor: not-allowed;
        opacity: 0.55;
      }
    `
];
__decorateClass([
  property({ type: Boolean, reflect: true })
], UUIInputLockElement.prototype, "locked", 2);
UUIInputLockElement = __decorateClass([
  defineElement("uui-input-lock")
], UUIInputLockElement);

export { UUIInputLockElement, UUIInputLockEvent };
