import { defineElement } from '@umbraco-ui/uui-base/lib/registration';
import { css, LitElement, html } from 'lit';
import { property, state } from 'lit/decorators.js';

var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp(target, key, result);
  return result;
};
let UUIDialogLayoutElement = class extends LitElement {
  constructor() {
    super(...arguments);
    this.headline = null;
    this._headlineSlotHasContent = false;
    this._headlineSlotChanged = (e) => {
      this._headlineSlotHasContent = e.target.assignedNodes({ flatten: true }).length > 0;
    };
  }
  /**
   * Renders a h3 with the headline slot nested
   * @returns {TemplateResult}
   * @protected
   * @method
   */
  renderHeadline() {
    return html` <h3
      style=${this._headlineSlotHasContent || this.headline !== null ? "" : "display: none"}>
      ${this.headline}
      <slot name="headline" @slotchange=${this._headlineSlotChanged}></slot>
    </h3>`;
  }
  /**
   * Renders default slot
   * @returns {TemplateResult}
   * @protected
   * @method
   */
  renderContent() {
    return html`<slot></slot>`;
  }
  /**
   * Renders actions slot
   * @returns {TemplateResult}
   * @protected
   * @method
   */
  renderActions() {
    return html`<slot id="actions" name="actions"></slot>`;
  }
  render() {
    return html`${this.renderHeadline()} ${this.renderContent()}
    ${this.renderActions()} `;
  }
};
UUIDialogLayoutElement.styles = [
  css`
      :host {
        display: block;
        padding: var(--uui-size-10,30px) var(--uui-size-14,42px);
        color: var(--uui-color-text,#060606);
      }

      #actions {
        margin-top: var(--uui-size-8,24px);
        display: flex;
        justify-content: end;
        gap: var(--uui-size-4,12px);
      }
    `
];
__decorateClass([
  property({ type: String })
], UUIDialogLayoutElement.prototype, "headline", 2);
__decorateClass([
  state()
], UUIDialogLayoutElement.prototype, "_headlineSlotHasContent", 2);
UUIDialogLayoutElement = __decorateClass([
  defineElement("uui-dialog-layout")
], UUIDialogLayoutElement);

export { UUIDialogLayoutElement };
