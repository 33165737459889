import { defineElement } from '@umbraco-ui/uui-base/lib/registration';
import { UUITextStyles } from '@umbraco-ui/uui-css/lib';
import { css, LitElement, html } from 'lit';
import { property, state } from 'lit/decorators.js';

var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp(target, key, result);
  return result;
};
let UUIToastNotificationLayoutElement = class extends LitElement {
  constructor() {
    super(...arguments);
    this.headline = "";
    this._headlineSlotHasContent = false;
    this._headlineSlotChanged = (e) => {
      this._headlineSlotHasContent = e.target.assignedNodes({ flatten: true }).length > 0;
    };
  }
  render() {
    return html`
      <div id="message" class="uui-text">
        <h5
          style=${this._headlineSlotHasContent || this.headline && this.headline !== "" ? "" : "display: none"}>
          ${this.headline}
          <slot name="headline" @slotchange=${this._headlineSlotChanged}></slot>
        </h5>
        <slot></slot>
        <slot id="actions" name="actions"></slot>
      </div>
    `;
  }
};
UUIToastNotificationLayoutElement.styles = [
  UUITextStyles,
  css`
      #message {
        margin-bottom: calc(var(--uui-size-space-1,3px) * -1);
      }
      #message::after {
        content: '';
        display: block;
        clear: both;
      }
      #actions {
        /*
        display: flex;
        width: 100%;
        justify-content: flex-end;
        */
        display: block;
        float: right;

        margin-top: var(--uui-size-space-4,12px);
        margin-bottom: calc(var(--uui-size-space-2,6px) * -1);
      }
    `
];
__decorateClass([
  property({ type: String })
], UUIToastNotificationLayoutElement.prototype, "headline", 2);
__decorateClass([
  state()
], UUIToastNotificationLayoutElement.prototype, "_headlineSlotHasContent", 2);
UUIToastNotificationLayoutElement = __decorateClass([
  defineElement("uui-toast-notification-layout")
], UUIToastNotificationLayoutElement);

export { UUIToastNotificationLayoutElement };
