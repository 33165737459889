import { css, LitElement, html } from 'lit';
import { property } from 'lit/decorators.js';
import { defineElement } from '@umbraco-ui/uui-base/lib/registration';
import { clamp } from '@umbraco-ui/uui-base/lib/utils';

var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp(target, key, result);
  return result;
};
let UUILoaderBarElement = class extends LitElement {
  constructor() {
    super(...arguments);
    this._progress = 0;
    this._animationDuration = 1;
  }
  get progress() {
    return this._progress;
  }
  set progress(newVal) {
    const oldVal = this._progress;
    this._progress = clamp(newVal, 0, 100);
    this.requestUpdate("progress", oldVal);
  }
  get animationDuration() {
    return this._animationDuration;
  }
  set animationDuration(newVal) {
    const oldVal = this._animationDuration;
    this._animationDuration = newVal >= 0 ? newVal : 1;
    this.requestUpdate("animationDuration", oldVal);
  }
  render() {
    return html`
      ${this.progress ? html`<div
            id="bar"
            style="max-width: ${this.progress.toString()}%;"></div>` : ""}
      <div
        id="bar-anim"
        class=${this.progress ? "" : "animate"}
        style="animation-duration: ${this.animationDuration}s"></div>
      <div id="bar-background"></div>
    `;
  }
};
UUILoaderBarElement.styles = [
  css`
      :host {
        position: relative;
        display: block;
        width: 100%;
        height: 4px;
        overflow: hidden;
        color: var(--uui-color-default,#1b264f);
      }

      #bar,
      #bar-anim,
      #bar-background {
        position: absolute;
        inset: 0; /* top, left, bottom and right = 0*/
        height: 100%;
      }

      #bar-background,
      #bar {
        background: currentColor;
      }

      #bar {
        transition: max-width 120ms ease;
      }

      #bar-background {
        opacity: 0.3;
      }

      #bar-anim {
        transform: scaleX(0.4);
        animation: bar-loading 1s infinite linear;
        background: linear-gradient(
          -90deg,
          white 0%,
          white 25%,
          transparent 100%
        );
      }

      #bar-anim.animate {
        background: linear-gradient(
          -90deg,
          currentColor 0%,
          currentColor 25%,
          transparent 100%
        );
      }

      @keyframes bar-loading {
        0% {
          transform-origin: -175% 0%;
        }
        100% {
          transform-origin: 175% 0%;
        }
      }
    `
];
__decorateClass([
  property({ type: Number })
], UUILoaderBarElement.prototype, "progress", 1);
__decorateClass([
  property({ type: Number })
], UUILoaderBarElement.prototype, "animationDuration", 1);
UUILoaderBarElement = __decorateClass([
  defineElement("uui-loader-bar")
], UUILoaderBarElement);

export { UUILoaderBarElement };
