import { defineElement } from '@umbraco-ui/uui-base/lib/registration';
import { slotHasContent, demandCustomElement } from '@umbraco-ui/uui-base/lib/utils';
import { UUICardElement } from '@umbraco-ui/uui-card/lib';
import { css, html, nothing } from 'lit';
import { property, state } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';

var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __typeError = (msg) => {
  throw TypeError(msg);
};
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp(target, key, result);
  return result;
};
var __accessCheck = (obj, member, msg) => member.has(obj) || __typeError("Cannot " + msg);
var __privateAdd = (obj, member, value) => member.has(obj) ? __typeError("Cannot add the same private member more than once") : member instanceof WeakSet ? member.add(obj) : member.set(obj, value);
var __privateMethod = (obj, member, method) => (__accessCheck(obj, member, "access private method"), method);
var _UUICardUserElement_instances, renderButton_fn, renderLink_fn;
let UUICardUserElement = class extends UUICardElement {
  constructor() {
    super(...arguments);
    __privateAdd(this, _UUICardUserElement_instances);
    this.name = "";
    this._avatarSlotHasContent = false;
    this._avatarSlotChanged = (e) => {
      this._avatarSlotHasContent = slotHasContent(e.target);
    };
  }
  connectedCallback() {
    super.connectedCallback();
    demandCustomElement(this, "uui-avatar");
  }
  render() {
    return html`
      ${this._avatarSlotHasContent ? nothing : html`<uui-avatar
            id="avatar"
            name=${this.name}
            size="m"></uui-avatar>`}
      <slot
        name="avatar"
        id="avatar"
        @slotchange=${this._avatarSlotChanged}></slot>
      ${this.href ? __privateMethod(this, _UUICardUserElement_instances, renderLink_fn).call(this) : __privateMethod(this, _UUICardUserElement_instances, renderButton_fn).call(this)}
      <slot></slot>
      <slot name="tag"></slot>
      <slot name="actions"></slot>
    `;
  }
};
_UUICardUserElement_instances = new WeakSet();
renderButton_fn = function() {
  return html`<div
      id="open-part"
      tabindex=${this.disabled ? nothing : "0"}
      @click=${this.handleOpenClick}
      @keydown=${this.handleOpenKeydown}>
      <span> ${this.name} </span>
    </div>`;
};
renderLink_fn = function() {
  return html`<a
      id="open-part"
      tabindex=${this.disabled ? nothing : "0"}
      href=${ifDefined(!this.disabled ? this.href : void 0)}
      target=${ifDefined(this.target || void 0)}
      rel=${ifDefined(
    this.rel || ifDefined(
      this.target === "_blank" ? "noopener noreferrer" : void 0
    )
  )}>
      <span>${this.name}</span>
    </a>`;
};
UUICardUserElement.styles = [
  ...UUICardElement.styles,
  css`
      :host {
        min-width: 250px;
        flex-direction: column;
        justify-content: space-between;
        padding: var(--uui-size-3,9px);
        align-items: center;
      }

      slot:not([name])::slotted(*) {
        font-size: var(--uui-type-small-size,12px);
        line-height: var(--uui-size-6,18px);
      }

      ::slotted(*) {
        text-align: center;
      }

      slot[name='tag'] {
        position: absolute;
        top: 6px;
        right: 6px;
        display: flex;
        justify-content: right;
      }

      slot[name='actions'] {
        position: absolute;
        top: var(--uui-size-4,12px);
        right: var(--uui-size-4,12px);
        display: flex;
        justify-content: right;

        opacity: 0;
        transition: opacity 120ms;
      }
      :host(:focus) slot[name='actions'],
      :host(:focus-within) slot[name='actions'],
      :host(:hover) slot[name='actions'] {
        opacity: 1;
      }

      #avatar {
        margin: var(--uui-size-3,9px);
      }

      slot[name='icon']::slotted(*) {
        font-size: 1.2em;
      }

      #open-part {
        display: flex;
        position: relative;
        font-weight: 700;
        align-items: center;
        cursor: pointer;
        margin: 0 0 3px 0;
      }

      :host([disabled]) #open-part {
        pointer-events: none;
      }

      #open-part > span {
        vertical-align: center;
        margin-top: 3px;
      }

      #open-part:hover {
        text-decoration: underline;
        color: var(--uui-color-interactive-emphasis,#3544b1);
      }
    `
];
__decorateClass([
  property({ type: String })
], UUICardUserElement.prototype, "name", 2);
__decorateClass([
  state()
], UUICardUserElement.prototype, "_avatarSlotHasContent", 2);
UUICardUserElement = __decorateClass([
  defineElement("uui-card-user")
], UUICardUserElement);

export { UUICardUserElement };
