import { defineElement } from '@umbraco-ui/uui-base/lib/registration';
import { query, property, state } from 'lit/decorators.js';
import { LitElement, html, nothing, css } from 'lit';
import { UUIFormControlMixin } from '@umbraco-ui/uui-base/lib/mixins';
import { demandCustomElement } from '@umbraco-ui/uui-base/lib/utils';
import { iconDelete } from '@umbraco-ui/uui-icon-registry-essential/lib/svgs';
import { repeat } from 'lit/directives/repeat.js';

var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp(target, key, result);
  return result;
};
let UUIInputFileElement = class extends UUIFormControlMixin(LitElement) {
  constructor() {
    super();
    this.accept = "";
    this.multiple = false;
    this._files = [];
    this._updateFileWrappers = (data) => {
      let newFileWrappers = [];
      for (const file of data) {
        if (this.multiple) {
          newFileWrappers.push(file);
        } else {
          newFileWrappers = [file];
        }
      }
      this._files = newFileWrappers;
    };
    this.addEventListener("dragenter", () => this._setShowDropzone(true));
    this.addEventListener("dragleave", () => this._setShowDropzone(false));
    this.addEventListener("drop", () => this._setShowDropzone(false));
  }
  get value() {
    return super.value;
  }
  set value(newValue) {
    super.value = newValue;
    if (newValue instanceof FormData) {
      const data = this.multiple ? newValue.getAll(this.name) : [newValue.get(this.name)];
      this._updateFileWrappers(data);
      return;
    }
    if (newValue instanceof File) {
      this._updateFileWrappers([newValue]);
      return;
    }
  }
  connectedCallback() {
    super.connectedCallback();
    demandCustomElement(this, "uui-icon");
    demandCustomElement(this, "uui-file-dropzone");
    demandCustomElement(this, "uui-button");
    demandCustomElement(this, "uui-action-bar");
    demandCustomElement(this, "uui-file-preview");
  }
  getFormElement() {
    return this._dropZone;
  }
  /**
   * Removes focus from the input.
   */
  async blur() {
    await this.updateComplete;
    this._dropzone.blur();
  }
  /**
   * This method enables <label for="..."> to focus the input
   */
  async focus() {
    await this.updateComplete;
    this._dropzone.focus();
  }
  async click() {
    await this.updateComplete;
    this._dropzone.browse();
  }
  _handleClick(e) {
    e.stopImmediatePropagation();
    this._dropzone.browse();
  }
  async _handleFilesChange(event) {
    const entries = event.detail.files;
    const files = entries.filter(
      (entry) => entry instanceof File || entry.isFile
    );
    if (!this.multiple) {
      const entry = files[0];
      const isFile = entry instanceof File;
      const file = isFile ? entry : await this._getFile(entry);
      if (this.value instanceof File) {
        this.value = file;
        return;
      }
      if (this.value instanceof FormData) {
        this.value.delete(this.name);
        this.value.append(this.name, file);
        this._updateFileWrappers([file]);
        return;
      }
    }
    let newValue = this.value;
    if (files.length > 0 && !(this.value instanceof FormData)) {
      newValue = new FormData();
    }
    if (newValue instanceof FormData) {
      for (const entry of files) {
        const isFile = entry instanceof File;
        newValue.append(this.name, isFile ? entry : await this._getFile(entry));
      }
    }
    this.value = newValue;
  }
  async _getFile(fileEntry) {
    return await new Promise(
      (resolve, reject) => fileEntry.file(resolve, reject)
    );
  }
  _removeFile(index) {
    const fileToRemove = this._files[index];
    if (this.value instanceof FormData) {
      const files = this.value.getAll(this.name);
      const filteredFiles = files.filter((file) => file !== fileToRemove);
      if (filteredFiles.length === 0) {
        this.value = "";
      } else {
        this.value.delete(this.name);
        for (const file of filteredFiles) {
          this.value.append(this.name, file);
        }
      }
      this._updateFileWrappers(filteredFiles);
    }
    if (this.value instanceof File) {
      this.value = "";
      this._updateFileWrappers([]);
    }
  }
  _setShowDropzone(show) {
    if (show) {
      this._dropZone.style.display = "flex";
    } else {
      this._dropZone.style.display = "none";
    }
  }
  _renderFileItem(file, index) {
    return html`<uui-file-preview .file="${file}">
      <uui-action-bar slot="actions">
        <uui-button
          @click=${() => this._removeFile(index)}
          color="danger"
          label=${`Delete ${file.name}`}>
          <uui-icon name="delete" .fallback=${iconDelete.strings[0]}></uui-icon>
        </uui-button>
      </uui-action-bar>
    </uui-file-preview>`;
  }
  _renderFiles() {
    return html`${repeat(
      this._files,
      (file) => file.name + file.size,
      (file, index) => this._renderFileItem(file, index)
    )}`;
  }
  render() {
    return html`
      <uui-file-dropzone
        id="dropzone"
        ?multiple=${this.multiple}
        .accept=${this.accept}
        @change=${this._handleFilesChange}
        label="Drop files here"></uui-file-dropzone>
      <div id="files">
        ${this._renderFiles()}
        ${this._files.length === 0 || this.multiple ? html`<uui-button
              @click=${this._handleClick}
              id="add-button"
              look="placeholder"
              label="Add"></uui-button>` : nothing}
      </div>
    `;
  }
};
UUIInputFileElement.styles = [
  css`
      :host {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        box-sizing: border-box;
        border: 1px solid var(--uui-color-border,#d8d7d9);
      }

      #input {
        position: absolute;
        width: 0px;
        height: 0px;
        opacity: 0;
        display: none;
      }

      #files {
        display: grid;
        box-sizing: border-box;
        justify-items: center;
        width: 100%;
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        grid-auto-rows: min-content;
        gap: 16px;
        padding: 16px;
        overflow: auto;
        max-height: 100%;
      }

      #dropzone {
        display: none;
        position: absolute;
        inset: 0px;
        z-index: 10;
        justify-content: center;
        align-items: center;
      }

      #add-button {
        width: 150px;
        height: 150px;
        display: flex;
        padding: 16px;
        box-sizing: border-box;
        justify-content: center;
        align-items: stretch;
      }
    `
];
__decorateClass([
  query("#dropzone")
], UUIInputFileElement.prototype, "_dropzone", 2);
__decorateClass([
  query("#dropzone")
], UUIInputFileElement.prototype, "_dropZone", 2);
__decorateClass([
  property({ type: String })
], UUIInputFileElement.prototype, "accept", 2);
__decorateClass([
  property({ type: Boolean })
], UUIInputFileElement.prototype, "multiple", 2);
__decorateClass([
  state()
], UUIInputFileElement.prototype, "_files", 2);
UUIInputFileElement = __decorateClass([
  defineElement("uui-input-file")
], UUIInputFileElement);

export { UUIInputFileElement };
