import { css, LitElement } from 'lit';
import { defineElement } from '@umbraco-ui/uui-base/lib/registration';
import { property, state } from 'lit/decorators.js';
import { UUITextStyles } from '@umbraco-ui/uui-css/lib';
import { html, unsafeStatic } from 'lit/static-html.js';

var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp(target, key, result);
  return result;
};
function slotHasContent(target) {
  return target ? target.assignedNodes({ flatten: true }).length > 0 : false;
}
let UUIBoxElement = class extends LitElement {
  constructor() {
    super(...arguments);
    this.headline = null;
    this._headlineVariantTag = "h5";
    this._headlineSlotHasContent = false;
    this._headlineSlotChanged = (e) => {
      this._headlineSlotHasContent = slotHasContent(e.target);
    };
    this._headerSlotHasContent = false;
    this._headerSlotChanged = (e) => {
      this._headerSlotHasContent = slotHasContent(e.target);
    };
    this._headerActionsSlotHasContent = false;
    this._headerActionsSlotChanged = (e) => {
      this._headerActionsSlotHasContent = slotHasContent(e.target);
    };
  }
  set headlineVariant(value) {
    this._headlineVariantTag = value;
  }
  get headlineVariant() {
    return this._headlineVariantTag;
  }
  /**
   * Renders a header with the `header`-slot, `header-actions`-slot, headline and `headline`-slot within
   * @returns {TemplateResult}
   * @protected
   * @method
   */
  renderHeader() {
    return html`<div
      id="header"
      class="uui-text"
      style=${this._headerSlotHasContent || this._headlineSlotHasContent || this._headerActionsSlotHasContent || this.headline !== null ? "" : "display: none"}>
      <${unsafeStatic(this._headlineVariantTag)}
        id="headline"
        class="uui-h5"
        style=${this._headlineSlotHasContent || this.headline !== null ? "" : "display: none"}>
        ${this.headline}
        <slot name="headline" @slotchange=${this._headlineSlotChanged}></slot>
      </${unsafeStatic(this._headlineVariantTag)}>
      <slot name="header" @slotchange=${this._headerSlotChanged}></slot>
      <slot name="header-actions" @slotchange=${this._headerActionsSlotChanged}></slot>
    </div>`;
  }
  render() {
    return html`
      ${this.renderHeader()}
      <slot></slot>
    `;
  }
};
UUIBoxElement.styles = [
  UUITextStyles,
  css`
      :host {
        display: block;
        border: var(--uui-box-border-width, 0) solid
          var(--uui-box-border-color, var(--uui-color-divider-standalone,#e9e9eb));
        box-shadow: var(--uui-box-box-shadow, var(--uui-shadow-depth-1,0 1px 3px rgba(0,0,0,0.12) , 0 1px 2px rgba(0,0,0,0.24)));
        border-radius: var(--uui-box-border-radius, var(--uui-border-radius,3px));
        background-color: var(--uui-color-surface,#fff);
      }

      #header {
        display: flex;
        column-gap: var(--uui-size-space-5,18px);
        border-bottom: 1px solid var(--uui-color-divider-standalone,#e9e9eb);
        padding: var(
          --uui-box-header-padding,
          var(--uui-size-space-4,12px) var(--uui-size-space-5,18px)
        );
      }

      slot:not([name]) {
        display: block;
        padding: var(--uui-box-default-padding, var(--uui-size-space-5,18px));
      }

      slot[name='header-actions'] {
        display: block;
        margin-left: auto;
      }
    `
];
__decorateClass([
  property({ type: String })
], UUIBoxElement.prototype, "headline", 2);
__decorateClass([
  property({ attribute: "headline-variant" })
], UUIBoxElement.prototype, "headlineVariant", 1);
__decorateClass([
  state()
], UUIBoxElement.prototype, "_headlineVariantTag", 2);
__decorateClass([
  state()
], UUIBoxElement.prototype, "_headlineSlotHasContent", 2);
__decorateClass([
  state()
], UUIBoxElement.prototype, "_headerSlotHasContent", 2);
__decorateClass([
  state()
], UUIBoxElement.prototype, "_headerActionsSlotHasContent", 2);
UUIBoxElement = __decorateClass([
  defineElement("uui-box")
], UUIBoxElement);

export { UUIBoxElement };
