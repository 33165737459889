import { defineElement } from '@umbraco-ui/uui-base/lib/registration';
import { LitElement, html, svg, css } from 'lit';
import { UUIFormControlMixin } from '@umbraco-ui/uui-base/lib/mixins';
import { property, state, query } from 'lit/decorators.js';
import { UUIEvent } from '@umbraco-ui/uui-base/lib/events';
import { clamp } from '@umbraco-ui/uui-base/lib/utils';

class UUIRangeSliderEvent extends UUIEvent {
  constructor(evName, eventInit = {}) {
    super(evName, {
      ...{ bubbles: true },
      ...eventInit
    });
  }
}
UUIRangeSliderEvent.INPUT = "input";
UUIRangeSliderEvent.CHANGE = "change";

var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __typeError = (msg) => {
  throw TypeError(msg);
};
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp(target, key, result);
  return result;
};
var __accessCheck = (obj, member, msg) => member.has(obj) || __typeError("Cannot " + msg);
var __privateAdd = (obj, member, value) => member.has(obj) ? __typeError("Cannot add the same private member more than once") : member instanceof WeakSet ? member.add(obj) : member.set(obj, value);
var __privateMethod = (obj, member, method) => (__accessCheck(obj, member, "access private method"), method);
var _UUIRangeSliderElement_instances, transferValueToInternalValues_fn;
const Z_INDEX = {
  TOP: 3,
  CENTER: 2,
  BACK: 1
};
const THUMB_SIZE = 18;
const TRACK_PADDING = 12;
const STEP_MIN_WIDTH = 24;
let UUIRangeSliderElement = class extends UUIFormControlMixin(LitElement, "") {
  /** Constructor and Validator */
  constructor() {
    super();
    __privateAdd(this, _UUIRangeSliderElement_instances);
    this.disabled = false;
    this.readonly = false;
    this._min = 0;
    this._max = 0;
    this.hideStepValues = false;
    this._step = 1;
    this._movement = false;
    this.startPoint = {
      mouse: 0,
      low: 0,
      high: 0
    };
    this._lowInputValue = 0;
    this._highInputValue = 0;
    this._trackWidth = 0;
    this._lowValuePercentStart = 0;
    this._highValuePercentEnd = 100;
    /** Events */
    this._onKeypress = (e) => {
      if (e.key == "Enter") {
        this.submit();
      }
    };
    /** Touch Event */
    this._onTouchStart = (e) => {
      if (this.disabled) return;
      const target = e.composedPath()[0];
      if (target === this._outerTrack) return;
      if (target === this._innerColor || target === this._innerColorThumb) {
        window.addEventListener("touchend", this._onTouchEnd);
        window.addEventListener("touchcancel", this._onTouchEnd);
        window.addEventListener("touchmove", this._onTouchMove);
        this._movement = true;
        this._saveStartPoints(e.touches[0].pageX);
      } else {
        const value = this._getClickedValue(e.touches[0].pageX);
        const diffLow = Math.abs(this._lowInputValue - value);
        const diffHigh = Math.abs(this._highInputValue - value);
        if (diffLow < diffHigh) {
          this.setValueLow(value);
        } else {
          this.setValueHigh(value);
        }
      }
    };
    this._onTouchMove = (e) => {
      this._dragBothValuesByMousePos(e.touches[0].pageX);
    };
    this._onTouchEnd = () => {
      this._movement = false;
      this.onChanged();
      window.removeEventListener("touchend", this._onTouchEnd);
      window.removeEventListener("touchcancel", this._onTouchEnd);
      window.removeEventListener("touchmove", this._onTouchMove);
    };
    /** Mouse Event */
    this._onMouseDown = (e) => {
      if (this.disabled) return;
      if (this.readonly) return;
      const target = e.composedPath()[0];
      if (target === this._outerTrack) return;
      if (target === this._innerColor || target === this._innerColorThumb) {
        window.addEventListener("mouseup", this._onMouseUp);
        window.addEventListener("mousemove", this._onMouseMove);
        this._movement = true;
        this._saveStartPoints(e.pageX);
      } else {
        const value = this._getClickedValue(e.pageX);
        const diffLow = Math.abs(this._lowInputValue - value);
        const diffHigh = Math.abs(this._highInputValue - value);
        if (diffLow < diffHigh) {
          this.setValueLow(value);
        } else {
          this.setValueHigh(value);
        }
      }
    };
    this._onMouseMove = (e) => {
      e.preventDefault();
      this._dragBothValuesByMousePos(e.pageX);
    };
    this._onMouseUp = () => {
      this._movement = false;
      this.onChanged();
      window.removeEventListener("mouseup", this._onMouseUp);
      window.removeEventListener("mousemove", this._onMouseMove);
    };
    this.addEventListener("keypress", this._onKeypress);
    this.addEventListener("mousedown", this._onMouseDown);
    this.addEventListener("touchstart", this._onTouchStart);
    window.addEventListener("resize", () => {
      this._trackWidth = this._outerTrack?.offsetWidth;
    });
  }
  get min() {
    return this._min;
  }
  set min(newVal) {
    this._min = newVal;
    __privateMethod(this, _UUIRangeSliderElement_instances, transferValueToInternalValues_fn).call(this);
  }
  get max() {
    return this._max;
  }
  set max(newVal) {
    this._max = newVal;
    __privateMethod(this, _UUIRangeSliderElement_instances, transferValueToInternalValues_fn).call(this);
  }
  get step() {
    return this._step;
  }
  set step(newVal) {
    this._step = newVal;
    __privateMethod(this, _UUIRangeSliderElement_instances, transferValueToInternalValues_fn).call(this);
  }
  get minGap() {
    return this._minGap;
  }
  set minGap(newVal) {
    this._minGap = newVal;
    __privateMethod(this, _UUIRangeSliderElement_instances, transferValueToInternalValues_fn).call(this);
  }
  get maxGap() {
    return this._maxGap;
  }
  set maxGap(newVal) {
    this._maxGap = newVal;
    __privateMethod(this, _UUIRangeSliderElement_instances, transferValueToInternalValues_fn).call(this);
  }
  get value() {
    return super.value;
  }
  set value(newVal) {
    super.value = newVal;
    __privateMethod(this, _UUIRangeSliderElement_instances, transferValueToInternalValues_fn).call(this);
  }
  setValueLow(low) {
    low = clamp(
      low,
      this.maxGap ? this._highInputValue - this.maxGap > this.min ? this._highInputValue - this.maxGap : this.min : this.min,
      this.minGap ? this._highInputValue - this.minGap : this._highInputValue - this.step
    );
    this.setValue(low, this._highInputValue);
  }
  setValueHigh(high) {
    high = clamp(
      high,
      this.minGap ? this._lowInputValue + this.minGap : this._lowInputValue + this.step,
      this.maxGap ? this.maxGap + this._lowInputValue < this.max ? this.maxGap + this._lowInputValue : this.max : this.max
    );
    this.setValue(this._lowInputValue, high);
  }
  setValue(low, high, lockValueRange) {
    if (lockValueRange) {
      const length = this.startPoint.high - this.startPoint.low;
      low = clamp(low, this.min, this.max - length);
      high = clamp(high, this.min + length, this.max);
    }
    this._inputLow.value = low.toString();
    this._inputHigh.value = high.toString();
    this.value = `${low},${high}`;
  }
  getFormElement() {
    return this._currentFocus ? this._currentFocus : this._inputLow;
  }
  async focus() {
    await this.updateComplete;
    this.getFormElement().focus();
  }
  async blur() {
    await this.updateComplete;
    this.getFormElement().blur();
  }
  connectedCallback() {
    super.connectedCallback();
    if (!this.value) {
      this.value = `${this._min},${this._max}`;
    }
  }
  firstUpdated(changedProperties) {
    super.updated(changedProperties);
    this._trackWidth = this._outerTrack.offsetWidth;
    this._runPropertiesChecks();
  }
  _runPropertiesChecks() {
    const regex = new RegExp(/^-?\d+(\.\d+)?,-?\d+(\.\d+)?$/);
    if (!regex.test(this.value))
      console.error(`Range slider (Value error occurred): Bad input`);
    if (this._highInputValue === this._lowInputValue) {
      console.error(
        `Range slider (Value error occurred): Low-end and high-end value should never be equal. Use <uui-slider></uui-slider> instead.`
      );
    }
    if (this._lowInputValue > this._highInputValue) {
      console.error(
        `Range slider (Value error occurred): Low-end value should never be higher than high-end value.`
      );
    }
    if (this._highInputValue > this._max || this._highInputValue < this._min) {
      this.setValueHigh(this._max);
      console.warn(
        `Conflict with the high-end value and max value. High-end value has been converted to the max value (${this._max})`
      );
    }
    if (this._lowInputValue < this._min || this._lowInputValue > this._max) {
      this.setValueLow(this._min);
      console.warn(
        `Conflict with the low-end value and min value. Low-end value has been converted to the min value (${this._min})`
      );
    }
    if (this._step <= 0) {
      this._step = 1;
      console.warn(
        `Property step needs a value higher than 0. Converted the step value to 1 (default)`
      );
    }
    if ((this._max - this._min) / this._step % 1 !== 0) {
      console.error(
        `Conflict with step value and the min and max values. May experience bad side effects`
      );
    }
    if (this._minGap && this._minGap < this._step) {
      this._minGap = void 0;
      console.warn(
        `Conflict with min-gap and step value. The min-gap needs to be higher than the step value. Removed the min-gap property.`
      );
    }
    if (this._minGap && this._maxGap && this._minGap > this._maxGap) {
      this._minGap = void 0;
      this._maxGap = void 0;
      console.warn(
        `Conflict with min-gap and max-gap. Removed the min-gap and max-gap properties.`
      );
    }
    if (this._minGap && this._max - this._min < this._minGap) {
      this._minGap = void 0;
      console.warn(
        `Conflict with the min-gap and the total range. Removed the min-gap.`
      );
    }
    if (this._maxGap && this._highInputValue - this._lowInputValue > this._maxGap) {
      this.setValueHigh(this._lowInputValue + this._maxGap);
      console.warn(
        `Conflict with value and max-gap. High-end value has been converted to the highest possible value based on the low-end value and the max gap value (${this._highInputValue})`
      );
    }
    if (this._minGap && this._highInputValue - this._lowInputValue < this._minGap) {
      const minGap = this._minGap;
      if (this._highInputValue - minGap < this._min) {
        this.setValueHigh(this._lowInputValue + minGap);
        console.warn(
          `Conflict with value and min gap. High-end value has been converted to the lowest possible value based on the low-end value and the min gap value (${this._highInputValue}).`
        );
      } else {
        this.setValueLow(this._highInputValue - minGap);
        console.warn(
          `Conflict with value and min gap. Low-end value has been converted to the highest possible value based on the high-end value and the min gap value (${this._lowInputValue}).`
        );
      }
    }
  }
  _updateInnerColor() {
    const scopeLength = this._max - this._min;
    const scopedLow = this._lowInputValue - this._min;
    const scopedHigh = this._highInputValue - this._min;
    const leftPercent = scopedLow / scopeLength * 100;
    const rightPercent = 100 - scopedHigh / scopeLength * 100;
    this._lowValuePercentStart = clamp(leftPercent, 0, 100);
    this._highValuePercentEnd = clamp(rightPercent, 0, 100);
  }
  _getClickedValue(pageX) {
    const outerTrackMargin = this._outerTrack.getBoundingClientRect().left;
    const mouseXPosition = pageX - outerTrackMargin - TRACK_PADDING;
    const clickPercent = mouseXPosition / (this._trackWidth - TRACK_PADDING * 2);
    const clickedValue = clickPercent * (this._max - this._min) + this._min;
    return Math.round(clickedValue / this._step) * this._step;
  }
  /** Drag both thumbs logics */
  _saveStartPoints(pageX) {
    this.startPoint = {
      mouse: pageX,
      low: this._lowInputValue,
      high: this._highInputValue
    };
  }
  _dragBothValuesByMousePos(pageX) {
    const trackWidth = this._outerTrack.offsetWidth;
    const drag = pageX - this.startPoint.mouse;
    const trackDiff = this._max - this._min;
    const dragPercent = drag / (trackWidth + TRACK_PADDING * 2);
    const dragValue = Math.round(dragPercent * trackDiff / this._step) * this._step;
    const newValueLow = this.startPoint.low + dragValue;
    const newValueHigh = this.startPoint.high + dragValue;
    this.setValue(newValueLow, newValueHigh, true);
    this.dispatchEvent(new UUIRangeSliderEvent(UUIRangeSliderEvent.INPUT));
  }
  /** Input Events */
  _onLowInput(e) {
    if (this.disabled) e.preventDefault();
    if (this.readonly) e.preventDefault();
    this._currentFocus = this._inputLow;
    const newValue = Number(e.target.value);
    this.setValueLow(newValue);
    this.dispatchEvent(new UUIRangeSliderEvent(UUIRangeSliderEvent.INPUT));
  }
  _onHighInput(e) {
    if (this.disabled) e.preventDefault();
    if (this.readonly) e.preventDefault();
    this._currentFocus = this._inputHigh;
    const newValue = Number(e.target.value);
    this.setValueHigh(newValue);
    this.dispatchEvent(new UUIRangeSliderEvent(UUIRangeSliderEvent.INPUT));
  }
  /** Change Events */
  _onLowChange() {
    this.setValueLow(Number(this._inputLow.value));
    this.onChanged();
  }
  _onHighChange() {
    this.setValueHigh(Number(this._inputHigh.value));
    this.onChanged();
  }
  onChanged() {
    this.pristine = false;
    this.dispatchEvent(new UUIRangeSliderEvent(UUIRangeSliderEvent.CHANGE));
  }
  /** Render */
  render() {
    return html`
      <div id="range-slider">
        ${this._renderNativeInputs()}
        <div id="inner-track">
          <div
            id="inner-color-thumb"
            class="${this._movement ? "active" : ""}"
            style="left: ${this._lowValuePercentStart}%; right: ${this._highValuePercentEnd}%">
            ${this._renderThumbValues()}
            <div class="${this._movement ? "color active" : "color"}"></div>
          </div>
          ${this._renderSteps()}
        </div>
      </div>
    `;
  }
  _renderThumbValues() {
    return html`<div class="thumb-values">
      <span><span>${this._lowInputValue}</span></span>
      <span><span>${this._highInputValue}</span></span>
    </div>`;
  }
  _renderSteps() {
    const stepAmount = (this._max - this._min) / this._step;
    const stepWidth = (this._trackWidth - TRACK_PADDING * 2) / stepAmount;
    if (stepWidth < STEP_MIN_WIDTH) return;
    if (stepAmount % 1 !== 0) return;
    let index = 0;
    const stepPositions = new Array(stepAmount + 1).fill(stepWidth).map((stepWidth2) => stepWidth2 * index++);
    return html`<div class="step-wrapper">
      <svg height="100%" width="100%">
        <rect x="9" y="9" height="3" rx="2" />
        ${this._renderStepCircles(stepPositions)}
      </svg>
      ${this._renderStepValues(stepAmount)}
    </div>`;
  }
  _renderStepValues(stepAmount) {
    if (this.hideStepValues || stepAmount > 20) return;
    let index = 0;
    const stepValues = new Array(stepAmount + 1).fill(this._step).map((step) => this._min + step * index++);
    return html`<div class="step-values">
      ${stepValues.map((value) => html`<span><span>${value}</span></span>`)}
    </div>`;
  }
  _renderStepCircles(stepPositionArray) {
    const trackValue = this._trackWidth / (this._max - this._min);
    return svg`${stepPositionArray.map((position) => {
      const cx = position + TRACK_PADDING;
      const colorStart = this._lowInputValue - this._min;
      const colorEnd = this._highInputValue - this._min;
      if (cx / trackValue >= colorStart && cx / trackValue <= colorEnd) {
        return svg`<circle class="track-step filled" cx="${cx}" cy="5" r="4.5" />`;
      } else {
        return svg`<circle class="track-step regular" cx="${cx}" cy="5" r="4.5" />`;
      }
    })}`;
  }
  _renderNativeInputs() {
    return html` <div class="native-wrapper">
      <input
        id="inputLow"
        class="${this._movement ? "focus" : ""}"
        type="range"
        min=${this._min}
        max=${this._max}
        step=${this._step}
        .value=${this._lowInputValue.toString()}
        aria-label="${this.label} low-end value"
        ?disabled="${this.disabled || this.readonly}"
        @input=${this._onLowInput}
        @change=${this._onLowChange} />
      <input
        id="inputHigh"
        class="${this._movement ? "focus" : ""}"
        type="range"
        min="${this._min}"
        max="${this._max}"
        step="${this._step}"
        .value=${this._highInputValue.toString()}
        aria-label="${this.label} high-end value"
        ?disabled="${this.disabled || this.readonly}"
        @input=${this._onHighInput}
        @change=${this._onHighChange} />
    </div>`;
  }
};
_UUIRangeSliderElement_instances = new WeakSet();
transferValueToInternalValues_fn = function() {
  const valueSplit = this.value.split(",");
  let low = Number(valueSplit[0]) || 0;
  let high = Number(valueSplit[1]) || 0;
  high = clamp(high, this._min, this._max);
  low = this._min + Math.round((low - this._min) / this._step) * this._step;
  high = this._min + Math.round((high - this._min) / this._step) * this._step;
  this._lowInputValue = clamp(
    low,
    this._min,
    this._minGap ? high - this._minGap : high - this._step
  );
  this._highInputValue = clamp(
    high,
    this._minGap ? this._lowInputValue + this._minGap : this._lowInputValue + this._step,
    Math.min(this._maxGap ? low + this._maxGap : this._max, this._max)
  );
  this._updateInnerColor();
  this.requestUpdate();
};
UUIRangeSliderElement.formAssociated = true;
/** Style */
UUIRangeSliderElement.styles = [
  css`
      :host {
        --color-interactive: var(--uui-color-selected,#3544b1);
        --color-hover: var(--uui-color-selected-emphasis,rgb(70, 86, 200));
        --color-focus: var(--uui-color-focus,#3879ff);
        min-height: 30px;
      }

      :host([error]) {
        --color-interactive: var(--uui-color-danger-standalone,rgb(191, 33, 78));
        --color-hover: var(--uui-color-danger,#d42054);
      }

      #range-slider {
        min-height: 30px;
        box-sizing: border-box;
        position: relative;
        display: flex;
        align-items: center;
      }

      /** Track */

      #inner-track {
        user-select: none;
        background-color: var(--uui-color-border-standalone,#c2c2c2);
        position: absolute;
        height: 3px;
        left: ${TRACK_PADDING}px; /* Match TRACK_MARGIN */
        right: ${TRACK_PADDING}px; /* Match TRACK_MARGIN */
      }

      :host(:not([disabled]):hover) #inner-track,
      :host(:not([disabled]):active) #inner-track {
        background-color: var(--uui-color-border-emphasis,#a1a1a1);
      }

      #inner-color-thumb {
        margin: -9px 0 0;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: ${THUMB_SIZE}px;
        cursor: grab;
        user-select: none;
        z-index: ${Z_INDEX.CENTER};
      }

      :host([disabled]) #inner-color-thumb,
      :host([readonly]) #inner-color-thumb {
        cursor: default;
      }

      /** Colored part of track */

      :host([disabled]) #range-slider #inner-color-thumb .color {
        background-color: var(--uui-palette-mine-grey,#3e3e3e) !important;
      }

      #inner-color-thumb:focus .color {
        background-color: var(--color-focus);
      }

      #inner-color-thumb:hover .color,
      #inner-color-thumb .color.active {
        background-color: var(--color-hover);
      }

      :host(:not([readonly])) #inner-color-thumb:hover .color {
        height: 5px;
        background-color: var(--color-hover);
      }

      .color {
        user-select: none;
        position: absolute;
        inset-inline: 0;
        height: 3px;
        transform: translateY(2px);
        background-color: var(--color-interactive);
        transition: height 60ms;
      }

      :host([error]) .color {
        background-color: var(--uui-color-danger-standalone,rgb(191, 33, 78));
      }
      :host([error]) #inner-color-thumb:hover ~ .color,
      :host([error]) #inner-color-thumb:focus ~ .color {
        background-color: var(--uui-color-danger-emphasis,rgb(226, 60, 107));
      }

      /** Steps */
      .step-wrapper {
        margin: 0 ${-1 * TRACK_PADDING}px;
        height: 11px;
        position: absolute;
        left: 0;
        right: 0;
        top: -10px;
      }

      /** Step circles */
      .track-step {
        fill: var(--uui-color-border,#d8d7d9);
      }

      :host(:not([disabled]):hover) #inner-track .track-step.regular,
      :host(:not([disabled]):active) #inner-track .track-step.regular {
        fill: var(--uui-color-border-emphasis,#a1a1a1);
      }

      :host .track-step.filled {
        fill: var(--color-interactive);
      }

      :host([error]) .track-step.filled {
        fill: var(--uui-color-danger-emphasis,rgb(226, 60, 107));
      }

      :host #inner-color-thumb.active ~ .step-wrapper .track-step.filled,
      :host #inner-color-thumb:hover ~ .step-wrapper .track-step.filled {
        fill: var(--color-hover);
      }

      :host([disabled]) #range-slider .track-step.filled {
        fill: var(--uui-palette-mine-grey,#3e3e3e) !important;
      }

      /** Step values */

      .step-values {
        box-sizing: border-box;
        margin: 0 ${TRACK_PADDING}px; /* Match TRACK_MARGIN */
        display: flex;
        justify-content: space-between;
        font-size: var(--uui-type-small-size,12px);
      }

      .step-values > span {
        position: relative;
        color: var(--uui-color-disabled-contrast,#c4c4c4);
      }

      .step-values > span > span {
        position: absolute;
        transform: translateX(-50%);
      }

      /** Input */

      .native-wrapper {
        position: relative;
        width: 100%;
        inset-inline: 0px;
        margin: -22px 5px 0 1px;
      }

      input {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        pointer-events: none;
        position: absolute;
        cursor: pointer;
        background-color: transparent;
        inset-inline: 0;
        width: 100%;
        border-radius: 20px;
      }

      input:focus-within {
        outline: none;
      }

      /** Thumb values */
      .thumb-values {
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        color: var(--color-interactive);
        font-weight: bold;
        transition: 120ms opacity;
        opacity: 0;
      }

      .thumb-values > span {
        width: 0;
      }

      .thumb-values > span > span {
        bottom: 15px;
        position: absolute;
        transform: translateX(-50%);
      }

      :host([disabled]) .thumb-values {
        color: var(--uui-palette-mine-grey,#3e3e3e);
      }

      :host([readonly]) .thumb-values {
        opacity: 1;
      }

      #range-slider:hover .thumb-values {
        opacity: 1;
      }

      /** Native thumbs */
      /** Chrome */
      input::-webkit-slider-thumb {
        -webkit-appearance: none;
        pointer-events: all;
        cursor: grab;
        position: relative;
        z-index: ${Z_INDEX.TOP};
        width: ${THUMB_SIZE}px;
        height: ${THUMB_SIZE}px;
        border-radius: 24px;
        border: none;
        background-color: var(--color-interactive);
        overflow: visible;
        box-shadow:
          inset 0 0 0 2px var(--color-interactive),
          inset 0 0 0 4px var(--uui-color-surface,#fff);
      }

      :host([disabled]) input::-webkit-slider-thumb,
      :host([readonly]) input::-webkit-slider-thumb {
        cursor: default;
      }

      input:focus-within::-webkit-slider-thumb,
      input.focus::-webkit-slider-thumb {
        background-color: var(--color-focus);
        box-shadow:
          inset 0 0 0 2px var(--color-focus),
          inset 0 0 0 4px var(--uui-color-surface,#fff),
          0 0 0 2px var(--color-focus);
      }
      input::-webkit-slider-thumb:hover {
        background-color: var(--color-hover);
        box-shadow:
          inset 0 0 0 2px var(--color-hover),
          inset 0 0 0 4px var(--uui-color-surface,#fff);
      }

      :host([disabled]) #range-slider input::-webkit-slider-thumb {
        background-color: var(--uui-palette-mine-grey,#3e3e3e);
        box-shadow:
          inset 0 0 0 2px var(--uui-palette-mine-grey,#3e3e3e),
          inset 0 0 0 4px var(--uui-color-surface,#fff);
      }

      /** Mozilla */

      input::-moz-range-thumb {
        -moz-appearance: none;
        pointer-events: all;
        cursor: grab;
        position: relative;
        z-index: ${Z_INDEX.TOP};
        width: ${THUMB_SIZE}px;
        height: ${THUMB_SIZE}px;
        border-radius: 24px;
        border: none;
        background-color: var(--color-interactive);
        overflow: visible;
        box-shadow:
          inset 0 0 0 2px var(--color-interactive),
          inset 0 0 0 4px var(--uui-color-surface,#fff);
      }
      :host([disabled]) input::-moz-range-thumb,
      :host([readonly]) input::-moz-range-thumb {
        cursor: default;
      }

      input:focus-within::-moz-range-thumb,
      input.focus::-moz-range-thumb {
        background-color: var(--color-focus);
        box-shadow:
          inset 0 0 0 2px var(--color-focus),
          inset 0 0 0 4px var(--uui-color-surface,#fff),
          0 0 0 2px var(--color-focus);
      }
      input::-moz-range-thumb:hover {
        background-color: var(--color-hover);
        box-shadow:
          inset 0 0 0 2px var(--color-hover),
          inset 0 0 0 4px var(--uui-color-surface,#fff);
      }

      :host([disabled]) #range-slider input::-moz-range-thumb {
        background-color: var(--uui-palette-mine-grey,#3e3e3e);
        box-shadow:
          inset 0 0 0 2px var(--uui-palette-mine-grey,#3e3e3e),
          inset 0 0 0 4px var(--uui-color-surface,#fff);
      }
    `
];
__decorateClass([
  property({ type: String })
], UUIRangeSliderElement.prototype, "label", 2);
__decorateClass([
  property({ type: Boolean, reflect: true })
], UUIRangeSliderElement.prototype, "disabled", 2);
__decorateClass([
  property({ type: Boolean, reflect: true })
], UUIRangeSliderElement.prototype, "readonly", 2);
__decorateClass([
  property({ type: Number })
], UUIRangeSliderElement.prototype, "min", 1);
__decorateClass([
  property({ type: Number })
], UUIRangeSliderElement.prototype, "max", 1);
__decorateClass([
  property({ type: Boolean, attribute: "hide-step-values" })
], UUIRangeSliderElement.prototype, "hideStepValues", 2);
__decorateClass([
  property({ type: Number })
], UUIRangeSliderElement.prototype, "step", 1);
__decorateClass([
  property({ type: Number, attribute: "min-gap" })
], UUIRangeSliderElement.prototype, "minGap", 1);
__decorateClass([
  property({ type: Number, attribute: "max-gap" })
], UUIRangeSliderElement.prototype, "maxGap", 1);
__decorateClass([
  property({ type: String })
], UUIRangeSliderElement.prototype, "value", 1);
__decorateClass([
  state()
], UUIRangeSliderElement.prototype, "_movement", 2);
__decorateClass([
  state()
], UUIRangeSliderElement.prototype, "_lowInputValue", 2);
__decorateClass([
  state()
], UUIRangeSliderElement.prototype, "_highInputValue", 2);
__decorateClass([
  state()
], UUIRangeSliderElement.prototype, "_trackWidth", 2);
__decorateClass([
  state()
], UUIRangeSliderElement.prototype, "_lowValuePercentStart", 2);
__decorateClass([
  state()
], UUIRangeSliderElement.prototype, "_highValuePercentEnd", 2);
__decorateClass([
  query("#range-slider")
], UUIRangeSliderElement.prototype, "_outerTrack", 2);
__decorateClass([
  query("#inputLow")
], UUIRangeSliderElement.prototype, "_inputLow", 2);
__decorateClass([
  query("#inputHigh")
], UUIRangeSliderElement.prototype, "_inputHigh", 2);
__decorateClass([
  query(".color")
], UUIRangeSliderElement.prototype, "_innerColor", 2);
__decorateClass([
  query("#inner-color-thumb")
], UUIRangeSliderElement.prototype, "_innerColorThumb", 2);
UUIRangeSliderElement = __decorateClass([
  defineElement("uui-range-slider")
], UUIRangeSliderElement);

export { UUIRangeSliderElement };
