import { defineElement } from '@umbraco-ui/uui-base/lib/registration';
import { findAncestorByAttributeValue } from '@umbraco-ui/uui-base/lib/utils';
import { css, LitElement, html } from 'lit';
import { property, state } from 'lit/decorators.js';

var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __typeError = (msg) => {
  throw TypeError(msg);
};
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp(target, key, result);
  return result;
};
var __accessCheck = (obj, member, msg) => member.has(obj) || __typeError("Cannot " + msg);
var __privateGet = (obj, member, getter) => (__accessCheck(obj, member, "read from private field"), getter ? getter.call(obj) : member.get(obj));
var __privateAdd = (obj, member, value) => member.has(obj) ? __typeError("Cannot add the same private member more than once") : member instanceof WeakSet ? member.add(obj) : member.set(obj, value);
var __privateSet = (obj, member, value, setter) => (__accessCheck(obj, member, "write to private field"), setter ? setter.call(obj, value) : member.set(obj, value), value);
var __privateMethod = (obj, member, method) => (__accessCheck(obj, member, "access private method"), method);
var _targetElement, _scrollParents, _onBeforeToggle, _initUpdate, _updatePosition, _updatePadding, _UUIPopoverContainerElement_instances, flipPlacement_fn, startScrollListener_fn, stopScrollListener_fn, getScrollParents_fn;
let UUIPopoverContainerElement = class extends LitElement {
  constructor() {
    super(...arguments);
    __privateAdd(this, _UUIPopoverContainerElement_instances);
    this.margin = 0;
    this._placement = "bottom-start";
    this._open = false;
    this._actualPlacement = this._placement;
    __privateAdd(this, _targetElement, null);
    __privateAdd(this, _scrollParents, []);
    __privateAdd(this, _onBeforeToggle, (event) => {
      this._open = event.newState === "open";
      __privateSet(this, _targetElement, findAncestorByAttributeValue(
        this,
        "popovertarget",
        this.id
      ));
      __privateMethod(this, _UUIPopoverContainerElement_instances, getScrollParents_fn).call(this);
      __privateGet(this, _targetElement)?.dispatchEvent(
        new CustomEvent("uui-popover-before-toggle", {
          bubbles: false,
          composed: false,
          detail: {
            oldState: event.oldState,
            newState: event.newState
          }
        })
      );
      if (!this._open) {
        __privateMethod(this, _UUIPopoverContainerElement_instances, stopScrollListener_fn).call(this);
        return;
      }
      __privateMethod(this, _UUIPopoverContainerElement_instances, startScrollListener_fn).call(this);
      requestAnimationFrame(() => {
        __privateGet(this, _initUpdate).call(this);
      });
    });
    __privateAdd(this, _initUpdate, () => {
      if (!this._open) return;
      this._actualPlacement = this._placement;
      this.style.opacity = "0";
      __privateGet(this, _updatePosition).call(this, 3);
    });
    __privateAdd(this, _updatePosition, (iteration) => {
      __privateGet(this, _updatePadding).call(this);
      iteration--;
      if (__privateGet(this, _targetElement) === null) return;
      const isTopPlacement = this._actualPlacement.indexOf("top") !== -1;
      const isBottomPlacement = this._actualPlacement.indexOf("bottom") !== -1;
      const isLeftPlacement = this._actualPlacement.indexOf("left") !== -1;
      const isRightPlacement = this._actualPlacement.indexOf("right") !== -1;
      const isStart = this._actualPlacement.indexOf("-start") !== -1;
      const isEnd = this._actualPlacement.indexOf("-end") !== -1;
      const targetRect = __privateGet(this, _targetElement).getBoundingClientRect();
      const popoverRect = this.getBoundingClientRect();
      let top = 0;
      let left = 0;
      if (isBottomPlacement) {
        top = targetRect.top + targetRect.height;
        if (isStart) {
          left = targetRect.left;
        }
        if (isEnd) {
          left = targetRect.left + targetRect.width - popoverRect.width;
        }
        if (!isStart && !isEnd) {
          left = targetRect.left + targetRect.width / 2 - popoverRect.width / 2;
        }
      }
      if (isTopPlacement) {
        top = targetRect.top - popoverRect.height;
        if (isStart) {
          left = targetRect.left;
        }
        if (isEnd) {
          left = targetRect.left + targetRect.width - popoverRect.width;
        }
        if (!isStart && !isEnd) {
          left = targetRect.left + targetRect.width / 2 - popoverRect.width / 2;
        }
      }
      if (isLeftPlacement) {
        left = targetRect.left - popoverRect.width;
        if (isStart) {
          top = targetRect.top;
        }
        if (isEnd) {
          top = targetRect.top + targetRect.height - popoverRect.height;
        }
        if (!isStart && !isEnd) {
          top = targetRect.top + targetRect.height / 2 - popoverRect.height / 2;
        }
      }
      if (isRightPlacement) {
        left = targetRect.left + targetRect.width;
        if (isStart) {
          top = targetRect.top;
        }
        if (isEnd) {
          top = targetRect.top + targetRect.height - popoverRect.height;
        }
        if (!isStart && !isEnd) {
          top = targetRect.top + targetRect.height / 2 - popoverRect.height / 2;
        }
      }
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
      const topTargetVsScreenTop = Math.min(
        0,
        targetRect.top + targetRect.height
      );
      const topTargetVsScreenBottom = Math.max(
        Math.min(top, screenHeight - popoverRect.height),
        targetRect.top - popoverRect.height
      );
      const topClamp = Math.max(topTargetVsScreenTop, topTargetVsScreenBottom);
      if (topClamp !== top && (isTopPlacement || isBottomPlacement) && iteration > 0) {
        __privateMethod(this, _UUIPopoverContainerElement_instances, flipPlacement_fn).call(this);
        __privateGet(this, _updatePosition).call(this, iteration);
        return;
      }
      top = Math.max(topTargetVsScreenTop, topTargetVsScreenBottom);
      const leftTargetVsScreenLeft = Math.min(
        0,
        targetRect.left + targetRect.width
      );
      const leftTargetVsScreenRight = Math.max(
        Math.min(left, screenWidth - popoverRect.width),
        targetRect.left - popoverRect.width
      );
      const leftClamp = Math.max(leftTargetVsScreenLeft, leftTargetVsScreenRight);
      if (leftClamp !== left && (isLeftPlacement || isRightPlacement) && iteration > 0) {
        __privateMethod(this, _UUIPopoverContainerElement_instances, flipPlacement_fn).call(this);
        __privateGet(this, _updatePosition).call(this, iteration);
        return;
      }
      left = leftClamp;
      const isCompletelyOutsideScreen = top + popoverRect.height < 0 || top > screenHeight || left + popoverRect.width < 0 || left > screenWidth;
      if (isCompletelyOutsideScreen) {
        this.hidePopover();
      }
      this.style.transform = `translate(${left}px, ${top}px)`;
      this.style.opacity = "1";
    });
    __privateAdd(this, _updatePadding, () => {
      const oppositeSides = {
        top: "bottom",
        bottom: "top",
        left: "right",
        right: "left"
      };
      let side = this._actualPlacement.split("-")[0];
      side = oppositeSides[side] || side;
      side = side.charAt(0).toUpperCase() + side.slice(1);
      const paddingSide = `padding${side}`;
      this.style.padding = "0";
      this.style[paddingSide] = `${this.margin}px`;
    });
  }
  get open() {
    return this._open;
  }
  get placement() {
    return this._placement;
  }
  set placement(newValue) {
    this._placement = newValue;
    this._actualPlacement = newValue;
    __privateGet(this, _initUpdate).call(this);
  }
  connectedCallback() {
    if (!this.hasAttribute("popover")) {
      this.setAttribute("popover", "");
    }
    super.connectedCallback();
    this.addEventListener("beforetoggle", __privateGet(this, _onBeforeToggle));
  }
  disconnectedCallback() {
    super.disconnectedCallback();
    this.removeEventListener("beforetoggle", __privateGet(this, _onBeforeToggle));
    __privateMethod(this, _UUIPopoverContainerElement_instances, stopScrollListener_fn).call(this);
  }
  render() {
    return html`<slot></slot>`;
  }
};
_targetElement = new WeakMap();
_scrollParents = new WeakMap();
_onBeforeToggle = new WeakMap();
_initUpdate = new WeakMap();
_updatePosition = new WeakMap();
_updatePadding = new WeakMap();
_UUIPopoverContainerElement_instances = new WeakSet();
flipPlacement_fn = function() {
  const [direction, position] = this._actualPlacement.split("-");
  const oppositeDirection = direction === "top" ? "bottom" : direction === "bottom" ? "top" : direction === "left" ? "right" : "left";
  this._actualPlacement = `${oppositeDirection}-${position}`;
};
startScrollListener_fn = function() {
  __privateGet(this, _scrollParents).forEach((el) => {
    el.addEventListener("scroll", __privateGet(this, _initUpdate), { passive: true });
  });
  document.addEventListener("scroll", __privateGet(this, _initUpdate), { passive: true });
};
stopScrollListener_fn = function() {
  __privateGet(this, _scrollParents).forEach((el) => {
    el.removeEventListener("scroll", __privateGet(this, _initUpdate));
  });
  document.removeEventListener("scroll", __privateGet(this, _initUpdate));
};
getScrollParents_fn = function() {
  if (!__privateGet(this, _targetElement)) return;
  let style = getComputedStyle(__privateGet(this, _targetElement));
  if (style.position === "fixed") {
    return;
  }
  const excludeStaticParent = style.position === "absolute";
  const overflowRegex = /(auto|scroll)/;
  let el = __privateGet(this, _targetElement);
  while (el = el.parentElement) {
    style = getComputedStyle(el);
    if (excludeStaticParent && style.position === "static") {
      continue;
    }
    if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) {
      __privateGet(this, _scrollParents).push(el);
    }
    if (style.position === "fixed") {
      return;
    }
  }
  __privateGet(this, _scrollParents).push(document.body);
};
UUIPopoverContainerElement.styles = [
  css`
      :host {
        margin: 0;
        width: fit-content;
        height: fit-content;
        border: none;
        border-radius: 0;
        padding: 0;
        background-color: none;
        background: none;
        overflow: visible;
        color: var(--uui-color-text,#060606);
      }
    `
];
__decorateClass([
  property({ type: Number })
], UUIPopoverContainerElement.prototype, "margin", 2);
__decorateClass([
  property({ type: Boolean })
], UUIPopoverContainerElement.prototype, "open", 1);
__decorateClass([
  property({ type: String, reflect: true })
], UUIPopoverContainerElement.prototype, "placement", 1);
__decorateClass([
  state()
], UUIPopoverContainerElement.prototype, "_placement", 2);
__decorateClass([
  state()
], UUIPopoverContainerElement.prototype, "_open", 2);
__decorateClass([
  state()
], UUIPopoverContainerElement.prototype, "_actualPlacement", 2);
UUIPopoverContainerElement = __decorateClass([
  defineElement("uui-popover-container")
], UUIPopoverContainerElement);

export { UUIPopoverContainerElement };
