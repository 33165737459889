import { UUIFormControlMixin } from '@umbraco-ui/uui-base/lib/mixins';
import { defineElement } from '@umbraco-ui/uui-base/lib/registration';
import { demandCustomElement } from '@umbraco-ui/uui-base/lib/utils';
import { UUIComboboxListEvent } from '@umbraco-ui/uui-combobox-list/lib';
import { iconRemove } from '@umbraco-ui/uui-icon-registry-essential/lib/svgs';
import { LitElement, html, css, nothing } from 'lit';
import { property, query, queryAssignedElements, state } from 'lit/decorators.js';
import { UUIEvent } from '@umbraco-ui/uui-base/lib/events';

class UUIComboboxEvent extends UUIEvent {
  constructor(evName, eventInit = {}) {
    super(evName, {
      ...{ bubbles: true },
      ...eventInit
    });
  }
}
UUIComboboxEvent.SEARCH = "search";
UUIComboboxEvent.CHANGE = "change";

var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __typeError = (msg) => {
  throw TypeError(msg);
};
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp(target, key, result);
  return result;
};
var __accessCheck = (obj, member, msg) => member.has(obj) || __typeError("Cannot " + msg);
var __privateGet = (obj, member, getter) => (__accessCheck(obj, member, "read from private field"), getter ? getter.call(obj) : member.get(obj));
var __privateAdd = (obj, member, value) => member.has(obj) ? __typeError("Cannot add the same private member more than once") : member instanceof WeakSet ? member.add(obj) : member.set(obj, value);
var __privateSet = (obj, member, value, setter) => (__accessCheck(obj, member, "write to private field"), setter ? setter.call(obj, value) : member.set(obj, value), value);
var __privateMethod = (obj, member, method) => (__accessCheck(obj, member, "access private method"), method);
var _comboboxList, _phoneMediaQuery, _onPhoneChange, _UUIComboboxElement_instances, updateValue_fn, _onMouseDown, _onBlur, _onInput, _onSlotChange, _onChange, _onToggle, _onOpen, _onClose, _onKeyDown, _onClear, _renderInput, _renderClearButton, _renderDropdown;
let UUIComboboxElement = class extends UUIFormControlMixin(LitElement, "") {
  constructor() {
    super(...arguments);
    __privateAdd(this, _UUIComboboxElement_instances);
    this.closeLabel = "Close";
    this.disabled = false;
    this.readonly = false;
    __privateAdd(this, _comboboxList);
    __privateAdd(this, _phoneMediaQuery);
    this._displayValue = "";
    this._search = "";
    this._isPhone = false;
    this._isOpen = false;
    __privateAdd(this, _onPhoneChange, () => {
      this._isPhone = __privateGet(this, _phoneMediaQuery).matches;
    });
    __privateAdd(this, _onMouseDown, () => requestAnimationFrame(() => this._input.focus()));
    __privateAdd(this, _onBlur, () => requestAnimationFrame(() => {
      if (!this.shadowRoot?.activeElement) {
        __privateGet(this, _onClose).call(this);
      }
    }));
    __privateAdd(this, _onInput, (e) => {
      e.preventDefault();
      e.stopImmediatePropagation();
      this.search = e.target.value;
      this.dispatchEvent(new UUIComboboxEvent(UUIComboboxEvent.SEARCH));
      __privateGet(this, _onOpen).call(this);
    });
    __privateAdd(this, _onSlotChange, () => {
      if (this.value && this.value !== __privateGet(this, _comboboxList)?.value) {
        __privateMethod(this, _UUIComboboxElement_instances, updateValue_fn).call(this, this.value);
      }
    });
    __privateAdd(this, _onChange, () => {
      this.value = __privateGet(this, _comboboxList)?.value || "";
      this.search = this.value ? this.search : "";
      __privateGet(this, _onClose).call(this);
      this.dispatchEvent(new UUIComboboxEvent(UUIComboboxEvent.CHANGE));
    });
    __privateAdd(this, _onToggle, () => {
      if (this.readonly) return;
      this.open = !this.open;
    });
    __privateAdd(this, _onOpen, () => {
      if (this.open) return;
      if (this.readonly) return;
      this.open = true;
    });
    __privateAdd(this, _onClose, () => {
      if (!this.open) return;
      this.open = false;
      this.search = "";
      this._input.value = this._displayValue;
      this.dispatchEvent(new UUIComboboxEvent(UUIComboboxEvent.SEARCH));
    });
    __privateAdd(this, _onKeyDown, (e) => {
      if (this.open === false && e.key === "Enter") {
        e.preventDefault();
        e.stopImmediatePropagation();
      }
      if (e.key === "ArrowUp" || e.key === "ArrowDown") {
        __privateGet(this, _onOpen).call(this);
      }
      if (e.key === "Escape" || e.key === "Enter") {
        __privateGet(this, _onClose).call(this);
      }
    });
    __privateAdd(this, _onClear, (e) => {
      if (e.key && e.key !== "Enter") return;
      e.preventDefault();
      e.stopImmediatePropagation();
      this.value = "";
      this.search = "";
      this._input.value = this._displayValue;
      this._input.focus();
      this.dispatchEvent(new UUIComboboxEvent(UUIComboboxEvent.SEARCH));
      this.dispatchEvent(new UUIComboboxEvent(UUIComboboxEvent.CHANGE));
    });
    __privateAdd(this, _renderInput, () => {
      return html` <uui-input
      slot="trigger"
      id="combobox-input"
      label="combobox-input"
      type="text"
      .value=${this._displayValue}
      autocomplete="off"
      .disabled=${this.disabled}
      .readonly=${this.readonly}
      popovertarget="combobox-popover"
      @click=${__privateGet(this, _onToggle)}
      @input=${__privateGet(this, _onInput)}
      @keydown=${__privateGet(this, _onKeyDown)}>
      <slot name="input-prepend" slot="prepend"></slot>
      ${__privateGet(this, _renderClearButton).call(this)}
      <div id="expand-symbol-wrapper" slot="append">
        <uui-symbol-expand .open=${this._isOpen}></uui-symbol-expand>
      </div>
      <slot name="input-append" slot="append"></slot>
    </uui-input>`;
    });
    __privateAdd(this, _renderClearButton, () => {
      if (this.disabled) return nothing;
      if (this.readonly) return nothing;
      return this.value || this.search ? html`<uui-button
          id="clear-button"
          @click=${__privateGet(this, _onClear)}
          @keydown=${__privateGet(this, _onClear)}
          label="clear"
          slot="append"
          compact
          style="height: 100%;">
          <uui-icon name="remove" .fallback=${iconRemove.strings[0]}></uui-icon>
        </uui-button>` : "";
    });
    __privateAdd(this, _renderDropdown, () => {
      return html`<div id="dropdown">
      <uui-scroll-container tabindex="-1" id="scroll-container">
        <slot></slot>
      </uui-scroll-container>
    </div>`;
    });
  }
  get value() {
    return super.value;
  }
  set value(newValue) {
    if (typeof newValue === "string") {
      __privateMethod(this, _UUIComboboxElement_instances, updateValue_fn).call(this, newValue);
    }
    super.value = newValue;
  }
  get search() {
    return this._search;
  }
  set search(newValue) {
    if (this.search === newValue) return;
    const oldValue = this._search;
    this._search = newValue;
    this.requestUpdate("search", oldValue);
  }
  get open() {
    return this._isOpen;
  }
  set open(newValue) {
    const oldValue = newValue;
    this._isOpen = newValue;
    const popover = this._comboboxPopoverElement;
    if (popover) {
      if (newValue) {
        const width = this._input.offsetWidth;
        popover.style.setProperty("--popover-width", `${width}px`);
        popover.showPopover();
      } else {
        popover.hidePopover();
      }
    }
    this.requestUpdate("open", oldValue);
  }
  connectedCallback() {
    super.connectedCallback();
    this.addEventListener("blur", __privateGet(this, _onBlur));
    this.addEventListener("mousedown", __privateGet(this, _onMouseDown));
    __privateSet(this, _phoneMediaQuery, window.matchMedia("(max-width: 600px)"));
    __privateGet(this, _onPhoneChange).call(this);
    __privateGet(this, _phoneMediaQuery).addEventListener("change", __privateGet(this, _onPhoneChange));
    demandCustomElement(this, "uui-icon");
    demandCustomElement(this, "uui-input");
    demandCustomElement(this, "uui-button");
    demandCustomElement(this, "uui-combobox-list");
    demandCustomElement(this, "uui-scroll-container");
    demandCustomElement(this, "uui-popover-container");
    demandCustomElement(this, "uui-symbol-expand");
  }
  disconnectedCallback() {
    super.disconnectedCallback();
    this.removeEventListener("blur", __privateGet(this, _onBlur));
    this.removeEventListener("mousedown", __privateGet(this, _onMouseDown));
    __privateGet(this, _phoneMediaQuery).removeEventListener("change", __privateGet(this, _onPhoneChange));
  }
  async firstUpdated() {
    const list = this._comboboxListElements?.[0];
    if (list) {
      __privateSet(this, _comboboxList, list);
      __privateGet(this, _comboboxList).for = this;
      __privateGet(this, _comboboxList).addEventListener(
        UUIComboboxListEvent.CHANGE,
        __privateGet(this, _onChange)
      );
      __privateGet(this, _comboboxList).addEventListener(
        UUIComboboxListEvent.INNER_SLOT_CHANGE,
        __privateGet(this, _onSlotChange)
      );
      await this.updateComplete;
      __privateMethod(this, _UUIComboboxElement_instances, updateValue_fn).call(this, this.value);
    }
  }
  getFormElement() {
    return this._input;
  }
  async focus() {
    await this.updateComplete;
    this._input.focus();
  }
  async blur() {
    await this.updateComplete;
    this._input.blur();
  }
  async click() {
    await this.updateComplete;
    this._input.click();
  }
  render() {
    if (this._isPhone && this.open) {
      return html` <div id="phone-wrapper">
        <uui-button label="close" look="primary" @click=${__privateGet(this, _onClose)}>
          ${this.closeLabel}
        </uui-button>
        ${__privateGet(this, _renderInput).call(this)} ${__privateGet(this, _renderDropdown).call(this)}
      </div>`;
    } else {
      return html`
        ${__privateGet(this, _renderInput).call(this)}
        <uui-popover-container
          id="combobox-popover"
          popover="manual"
          placement="bottom-end">
          ${__privateGet(this, _renderDropdown).call(this)}
        </uui-popover-container>
      `;
    }
  }
};
_comboboxList = new WeakMap();
_phoneMediaQuery = new WeakMap();
_onPhoneChange = new WeakMap();
_UUIComboboxElement_instances = new WeakSet();
updateValue_fn = function(value) {
  if (__privateGet(this, _comboboxList)) {
    __privateGet(this, _comboboxList).value = value;
    requestAnimationFrame(
      () => this._displayValue = __privateGet(this, _comboboxList).displayValue || ""
    );
  }
};
_onMouseDown = new WeakMap();
_onBlur = new WeakMap();
_onInput = new WeakMap();
_onSlotChange = new WeakMap();
_onChange = new WeakMap();
_onToggle = new WeakMap();
_onOpen = new WeakMap();
_onClose = new WeakMap();
_onKeyDown = new WeakMap();
_onClear = new WeakMap();
_renderInput = new WeakMap();
_renderClearButton = new WeakMap();
_renderDropdown = new WeakMap();
UUIComboboxElement.styles = [
  css`
      :host {
        display: inline-block;
      }

      #combobox-input {
        width: 100%;
        border-radius: var(--uui-size-1,3px);
      }

      #combobox-popover {
        width: var(--uui-dropdown-width, var(--popover-width, inherit));
      }

      #scroll-container {
        overflow-y: auto;
        width: 100%;
        max-height: var(--uui-combobox-popover-max-height, 500px);
      }
      #expand-symbol-wrapper {
        height: 100%;
        padding-right: var(--uui-size-space-3,9px);
        display: flex;
        justify-content: center;
      }

      #dropdown {
        overflow: hidden;
        z-index: -1;
        background-color: var(
          --uui-combobox-popover-background-color,
          var(--uui-color-surface,#fff)
        );
        border: 1px solid var(--uui-color-border,#d8d7d9);
        border-radius: var(--uui-border-radius,3px);
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        box-shadow: var(--uui-shadow-depth-3,0 10px 20px rgba(0,0,0,0.19) , 0 6px 6px rgba(0,0,0,0.23));
      }

      :host([disabled]) #caret {
        fill: var(--uui-color-disabled-contrast,#c4c4c4);
      }

      #phone-wrapper {
        position: fixed;
        inset: 0;
        display: flex;
        flex-direction: column;
        z-index: 1;
        font-size: 1.1em;
      }

      #phone-wrapper #dropdown {
        display: flex;
      }

      #phone-wrapper #combobox-input {
        height: var(--uui-size-16,48px);
      }

      #phone-wrapper > uui-button {
        height: var(--uui-size-14,42px);
        width: 100%;
      }

      #phone-wrapper #scroll-container {
        max-height: unset;
      }
    `
];
__decorateClass([
  property({ attribute: "value", reflect: true })
], UUIComboboxElement.prototype, "value", 1);
__decorateClass([
  property({ type: String })
], UUIComboboxElement.prototype, "search", 1);
__decorateClass([
  property({ type: Boolean })
], UUIComboboxElement.prototype, "open", 1);
__decorateClass([
  property({ type: String })
], UUIComboboxElement.prototype, "closeLabel", 2);
__decorateClass([
  property({ type: Boolean, reflect: true })
], UUIComboboxElement.prototype, "disabled", 2);
__decorateClass([
  property({ type: Boolean, reflect: true })
], UUIComboboxElement.prototype, "readonly", 2);
__decorateClass([
  query("#combobox-input")
], UUIComboboxElement.prototype, "_input", 2);
__decorateClass([
  query("#combobox-popover")
], UUIComboboxElement.prototype, "_comboboxPopoverElement", 2);
__decorateClass([
  queryAssignedElements({
    flatten: true,
    selector: "uui-combobox-list"
  })
], UUIComboboxElement.prototype, "_comboboxListElements", 2);
__decorateClass([
  state()
], UUIComboboxElement.prototype, "_displayValue", 2);
__decorateClass([
  state()
], UUIComboboxElement.prototype, "_search", 2);
__decorateClass([
  state()
], UUIComboboxElement.prototype, "_isPhone", 2);
__decorateClass([
  state()
], UUIComboboxElement.prototype, "_isOpen", 2);
UUIComboboxElement = __decorateClass([
  defineElement("uui-combobox")
], UUIComboboxElement);

export { UUIComboboxElement, UUIComboboxEvent };
