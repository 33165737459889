import { css, LitElement, svg } from 'lit';
import { defineElement } from '@umbraco-ui/uui-base/lib/registration';

var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp(target, key, result);
  return result;
};
let UUISymbolMoreElement = class extends LitElement {
  render() {
    return svg`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <circle cx="17" cy="50" r="9"></circle>
      <circle cx="50" cy="50" r="9"></circle>
      <circle cx="83" cy="50" r="9"></circle>
    </svg>`;
  }
};
UUISymbolMoreElement.styles = [
  css`
      :host {
        display: inline-block;
        vertical-align: bottom;
        width: 1.15em;
        height: 1.15em;
      }
    `
];
UUISymbolMoreElement = __decorateClass([
  defineElement("uui-symbol-more")
], UUISymbolMoreElement);

export { UUISymbolMoreElement };
