import { css, LitElement, html } from 'lit';
import { defineElement } from '@umbraco-ui/uui-base/lib/registration';

var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp(target, key, result);
  return result;
};
let UUISymbolFolderElement = class extends LitElement {
  render() {
    return html`<svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      width="100%"
      id="icon">
      <path
        d="M450.962 169.833v-19.248c0-23.398-19.035-42.436-42.434-42.436H257.306c-5.885-16.926-21.99-29.1-40.885-29.1h-89.758c-20.545 0-37.795 14.391-42.191 33.623-13.891 7.016-23.486 21.45-23.486 37.912V169.897c-7.148 7.774-11.443 18.122-11.443 29.287v.956l.062.953c.045.704 4.639 70.713 5.469 95.492l3.137 93.62c.033 6.024.682 14.788 5.271 23.301 6.564 12.175 19.002 19.442 33.273 19.442h318.49c14.273 0 26.713-7.268 33.275-19.445 4.588-8.512 5.236-17.275 5.271-23.298l3.135-93.62c.832-24.782 5.424-94.788 5.471-95.492l.061-.953v-.956c0-11.203-4.314-21.574-11.496-29.351zM90.093 150.585c0-7.281 5.84-13.189 13.09-13.318h-.125c5.324 0 9.441-9.694 9.441-13.382l-.006-1.562c0-7.824 6.344-14.168 14.17-14.168h89.758c7.826 0 14.166 6.344 14.166 14.168v1.562c0 3.663 4.062 13.239 9.332 13.37h168.609c7.363 0 13.328 5.969 13.328 13.33v9.201H90.093v-9.201zm337.744 145.028c-.854 25.538-3.15 94.062-3.15 94.062 0 7.825-1.617 14.171-9.441 14.171H96.755c-7.822 0-9.439-6.346-9.439-14.171l-3.152-94.062c-.855-25.538-5.516-96.428-5.516-96.428 0-6.893 4.926-12.631 11.445-13.9l331.764-.009c6.547 1.251 11.494 6.998 11.494 13.909 0 .001-4.657 70.89-5.514 96.428z" />
    </svg> `;
  }
};
UUISymbolFolderElement.styles = [
  css`
      :host {
        display: block;
        box-sizing: border-box;
        position: relative;
        max-width: 100px;
      }

      #icon {
        fill: var(--uui-color-border,#d8d7d9);
      }
    `
];
UUISymbolFolderElement = __decorateClass([
  defineElement("uui-symbol-folder")
], UUISymbolFolderElement);

export { UUISymbolFolderElement };
