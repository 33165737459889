import { defineElement } from '@umbraco-ui/uui-base/lib/registration';
import { css, LitElement, html } from 'lit';
import { query, property } from 'lit/decorators.js';
import { UUIEvent } from '@umbraco-ui/uui-base/lib/events';

class UUIPopoverEvent extends UUIEvent {
}
UUIPopoverEvent.CLOSE = "close";

var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp(target, key, result);
  return result;
};
function mathClamp(value, min, max) {
  if (value < min) {
    return min;
  } else if (value > max) {
    return max;
  }
  return value;
}
let UUIPopoverElement = class extends LitElement {
  constructor() {
    super(...arguments);
    this.scrollEventHandler = this._updatePlacement.bind(this);
    this._open = false;
    this._placement = "bottom-start";
    this._currentPlacement = null;
    this._scrollParents = [];
    this.margin = 0;
    this._onTriggerSlotChanged = (event) => {
      this._trigger = event.target.assignedElements({
        flatten: true
      })[0];
    };
    this._intersectionCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting === false) {
          this._startScrollListener();
          this._updatePlacement();
        }
      });
    };
    this._onKeyUp = (event) => {
      if (event.key === "Escape") {
        this._triggerPopoverClose();
        return;
      }
    };
    this._onDocumentClick = (event) => {
      if (!event.composedPath().includes(this)) {
        this._triggerPopoverClose();
      }
    };
  }
  get placement() {
    return this._placement;
  }
  set placement(newValue) {
    const oldValue = this._placement;
    this._placement = newValue || "bottom-start";
    this._currentPlacement = null;
    this._updatePlacement();
    this.requestUpdate("placement", oldValue);
  }
  get open() {
    return this._open;
  }
  set open(newValue) {
    const oldValue = this._open;
    this._open = newValue;
    newValue ? this._openPopover() : this._closePopover();
    this.requestUpdate("open", oldValue);
  }
  connectedCallback() {
    super.connectedCallback();
    this._getScrollParents();
  }
  disconnectedCallback() {
    super.disconnectedCallback();
    document.removeEventListener("mousedown", this._onDocumentClick);
    document.removeEventListener("keyup", this._onKeyUp);
    document.removeEventListener("scroll", this.scrollEventHandler);
    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect();
      delete this.intersectionObserver;
    }
    this._scrollParents = [];
  }
  _openPopover() {
    if (this.containerElement) {
      this.containerElement.style.opacity = "0";
      document.addEventListener("mousedown", this._onDocumentClick);
      document.addEventListener("keyup", this._onKeyUp);
      this._currentPlacement = null;
      requestAnimationFrame(() => {
        this._updatePlacement();
        this._createIntersectionObserver();
        this.containerElement.style.opacity = "1";
      });
    }
  }
  _closePopover() {
    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect();
      delete this.intersectionObserver;
    }
    document.removeEventListener("mousedown", this._onDocumentClick);
    document.removeEventListener("keyup", this._onKeyUp);
    this._currentPlacement = null;
  }
  // Use this when changing the open state from within this component.
  _triggerPopoverClose() {
    const event = new UUIPopoverEvent(UUIPopoverEvent.CLOSE, {
      cancelable: true
    });
    this.dispatchEvent(event);
    if (event.defaultPrevented) return;
    this.open = false;
  }
  _getScrollParents() {
    const hostElement = this.shadowRoot.host;
    let style = getComputedStyle(hostElement);
    if (style.position === "fixed") {
      return;
    }
    const excludeStaticParent = style.position === "absolute";
    const overflowRegex = /(auto|scroll)/;
    let el = hostElement;
    while (el = el.parentElement) {
      style = getComputedStyle(el);
      if (excludeStaticParent && style.position === "static") {
        continue;
      }
      if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) {
        this._scrollParents.push(el);
      }
      if (style.position === "fixed") {
        return;
      }
    }
    this._scrollParents.push(document.body);
  }
  _createIntersectionObserver() {
    if (this.intersectionObserver) {
      return;
    }
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 1
    };
    this.intersectionObserver = new IntersectionObserver(
      this._intersectionCallback,
      options
    );
    this.intersectionObserver.observe(this.containerElement);
  }
  _startScrollListener() {
    this._scrollParents.forEach((el) => {
      el.addEventListener("scroll", this.scrollEventHandler);
    });
    document.addEventListener("scroll", this.scrollEventHandler);
  }
  _stopScrollListener() {
    this._scrollParents.forEach((el) => {
      el.removeEventListener("scroll", this.scrollEventHandler);
    });
    document.removeEventListener("scroll", this.scrollEventHandler);
  }
  _updatePlacement() {
    if (!this.shadowRoot) {
      return;
    }
    const containerElement = this.containerElement;
    if (!containerElement) {
      return;
    }
    const popoverRect = this.containerElement?.getBoundingClientRect();
    const triggerRect = this._trigger?.getBoundingClientRect();
    if (triggerRect != null && popoverRect != null) {
      const scrollParentRects = this._scrollParents.map(
        (el) => el.getBoundingClientRect()
      );
      this._currentPlacement = this._currentPlacement || this._placement;
      if (this._placement !== "auto") {
        this._currentPlacement = this._managePlacementFlip(
          this._currentPlacement,
          popoverRect,
          scrollParentRects
        );
      }
      let isTopPlacement = this._currentPlacement.indexOf("top") !== -1;
      let isBottomPlacement = this._currentPlacement.indexOf("bottom") !== -1;
      let isLeftPlacement = this._currentPlacement.indexOf("left") !== -1;
      let isRightPlacement = this._currentPlacement.indexOf("right") !== -1;
      const isStart = this._currentPlacement.indexOf("-start") !== -1;
      const isEnd = this._currentPlacement.indexOf("-end") !== -1;
      let originX = 0.5;
      let originY = 0.5;
      let alignX = 0.5;
      let alignY = 0.5;
      let marginX = 0;
      let marginY = 0;
      if (this.placement === "auto") {
        const firstScrollParent = this._scrollParents[0];
        const scrollParentWidth = firstScrollParent.clientWidth;
        const scrollParentHeight = firstScrollParent.clientHeight;
        const spaceLeft = triggerRect.x - popoverRect.width;
        const spaceRight = scrollParentWidth - (triggerRect.x + triggerRect.width) - popoverRect.width;
        const spaceTop = triggerRect.y - popoverRect.height;
        const spaceBottom = scrollParentHeight - (triggerRect.y + triggerRect.height) - popoverRect.height;
        let dirX = 0.5;
        let dirY = 0.5;
        const hMaxSpace = Math.max(spaceLeft, spaceRight);
        let vMaxSpace = Math.max(spaceTop, spaceBottom);
        if (spaceBottom > spaceTop && spaceBottom > this.margin) {
          vMaxSpace += 9999;
        }
        if (hMaxSpace > vMaxSpace) {
          if (spaceLeft > spaceRight) {
            dirX = 0;
            isLeftPlacement = true;
          } else {
            dirX = 1;
            isRightPlacement = true;
          }
          marginX = this.margin;
        } else {
          if (spaceTop > spaceBottom) {
            dirY = 0;
            isTopPlacement = true;
          } else {
            dirY = 1;
            isBottomPlacement = true;
          }
          marginY = this.margin;
        }
        originX = dirX;
        originY = dirY;
        alignX = 1 - dirX;
        alignY = 1 - dirY;
      } else {
        if (isTopPlacement) {
          alignY = 1;
          originY = 0;
        }
        if (isBottomPlacement) {
          alignY = 0;
          originY = 1;
        }
        if (isTopPlacement || isBottomPlacement) {
          marginY = this.margin;
          if (isStart) {
            alignX = 0;
            originX = 0;
          }
          if (isEnd) {
            alignX = 1;
            originX = 1;
          }
        }
        if (isLeftPlacement) {
          alignX = 1;
          originX = 0;
        }
        if (isRightPlacement) {
          alignX = 0;
          originX = 1;
        }
        if (isLeftPlacement || isRightPlacement) {
          marginX = this.margin;
          if (isStart) {
            alignY = 0;
            originY = 0;
          }
          if (isEnd) {
            alignY = 1;
            originY = 1;
          }
        }
      }
      const calcX = -popoverRect.width * alignX + triggerRect.width * originX - marginX * (alignX * 2 - 1);
      const calcY = -popoverRect.height * alignY + triggerRect.height * originY - marginY * (alignY * 2 - 1);
      let posX = calcX;
      let posY = calcY;
      if (isTopPlacement || isBottomPlacement) {
        this._scrollParents.forEach((el, index) => {
          const rectX = el === document.body ? 0 : scrollParentRects[index].x;
          const leftClamp = -triggerRect.x + rectX;
          const rightClamp = el.clientWidth - triggerRect.x - triggerRect.width + calcX + rectX - (popoverRect.width - triggerRect.width) * (1 - originX);
          posX = mathClamp(posX, leftClamp, rightClamp);
        });
        posX = mathClamp(posX, -popoverRect.width, triggerRect.width);
      } else if (isLeftPlacement || isRightPlacement) {
        this._scrollParents.forEach((el, index) => {
          const rectY = el === document.body ? 0 : scrollParentRects[index].y;
          const topClamp = -triggerRect.y + rectY;
          const bottomClamp = el.clientHeight - triggerRect.y - triggerRect.height + calcY + rectY - (popoverRect.height - triggerRect.height) * (1 - originY);
          posY = mathClamp(posY, topClamp, bottomClamp);
        });
        posY = mathClamp(posY, -popoverRect.height, triggerRect.height);
      }
      if (this._positionX !== posX || this._positionY !== posY) {
        this._positionX = posX;
        this._positionY = posY;
        if (calcX === posX && calcY === posY) {
          this._stopScrollListener();
        }
        containerElement.style.left = `${this._positionX}px`;
        containerElement.style.top = `${this._positionY}px`;
      }
    }
  }
  _managePlacementFlip(currentPlacement, popoverRect, scrollParentRects) {
    const buffer = 2;
    const placementSplit = currentPlacement.split("-");
    const currentSide = placementSplit[0];
    const placementAlign = placementSplit[1] || null;
    let sideFlip;
    this._scrollParents.some((el, index) => {
      const rectX = el === document.body ? 0 : scrollParentRects[index].x;
      const rectY = el === document.body ? 0 : scrollParentRects[index].y;
      if (currentSide === "top" && popoverRect.y - buffer <= rectY) {
        sideFlip = "bottom";
        return true;
      }
      if (currentSide === "bottom" && popoverRect.y + popoverRect.height + buffer >= el.clientHeight + rectY) {
        sideFlip = "top";
        return true;
      }
      if (currentSide === "left" && popoverRect.x - buffer <= rectX) {
        sideFlip = "right";
        return true;
      }
      if (currentSide === "right" && popoverRect.x + popoverRect.width + buffer >= el.clientWidth + rectX) {
        sideFlip = "left";
        return true;
      }
      return false;
    });
    if (sideFlip) {
      this._startScrollListener();
      return sideFlip + (placementAlign ? `-${placementAlign}` : "");
    }
    return currentPlacement;
  }
  render() {
    return html`
      <slot
        id="trigger"
        name="trigger"
        @slotchange=${this._onTriggerSlotChanged}></slot>
      <div id="container">
        ${this._open ? html`<slot name="popover"></slot>` : ""}
      </div>
    `;
  }
};
UUIPopoverElement.styles = [
  css`
      :host {
        position: relative;
        display: inline-block;
        width: 100%;
      }
      #container {
        position: absolute;
        width: 100%;
        z-index: var(--uui-popover-z-index, 1);
      }
      slot[name='popover'] {
        display: block;
      }
      #trigger {
        position: relative;
        width: 100%;
      }

      slot[name='trigger']::slotted(uui-button) {
        --uui-button-border-radius: var(
          --uui-popover-toggle-slot-button-border-radius
        );
        --uui-button-merge-border-left: var(
          --uui-popover-toggle-slot-button-merge-border-left
        );
      }
    `
];
__decorateClass([
  query("#container")
], UUIPopoverElement.prototype, "containerElement", 2);
__decorateClass([
  property({ type: Number })
], UUIPopoverElement.prototype, "margin", 2);
__decorateClass([
  property({ type: String, reflect: true })
], UUIPopoverElement.prototype, "placement", 1);
__decorateClass([
  property({ type: Boolean, reflect: true })
], UUIPopoverElement.prototype, "open", 1);
UUIPopoverElement = __decorateClass([
  defineElement("uui-popover")
], UUIPopoverElement);

export { UUIPopoverElement };
