import { UUIHorizontalShakeKeyframes, UUIHorizontalShakeAnimationValue } from '@umbraco-ui/uui-base/lib/animations';
import { defineElement } from '@umbraco-ui/uui-base/lib/registration';
import { UUIBooleanInputElement } from '@umbraco-ui/uui-boolean-input/lib';
import { iconCheck, iconRemove } from '@umbraco-ui/uui-icon-registry-essential/lib/svgs';
import { css, html } from 'lit';

var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp(target, key, result);
  return result;
};
let UUIToggleElement = class extends UUIBooleanInputElement {
  constructor() {
    super("switch");
  }
  renderCheckbox() {
    return html`
      <div id="slider">
        <div id="icon-checked">${iconCheck}</div>
        <div id="icon-unchecked">${iconRemove}</div>
      </div>
    `;
  }
};
/**
 * This is a static class field indicating that the element is can be used inside a native form and participate in its events. It may require a polyfill, check support here https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/attachInternals.  Read more about form controls here https://web.dev/more-capable-form-controls/
 * @type {boolean}
 */
UUIToggleElement.formAssociated = true;
UUIToggleElement.styles = [
  ...UUIBooleanInputElement.styles,
  UUIHorizontalShakeKeyframes,
  css`
      :host {
        --uui-toggle-size: 18px;
        --uui-toggle-switch-width: calc(2 * var(--uui-toggle-size));
      }

      #slider {
        position: relative;
        grid-area: 'input';
        display: flex;
        align-items: center;

        flex-shrink: 0;

        width: var(--uui-toggle-switch-width);
        height: var(--uui-toggle-size);
        border-radius: 100px;

        background-color: var(
          --uui-toggle-background-color,
          var(--uui-color-border,#d8d7d9)
        );
        border: 1px solid
          var(--uui-toggle-border-color, var(--uui-color-border-standalone,#c2c2c2));
        font-size: calc(var(--uui-toggle-size) * 0.6);
      }

      label:hover input:not([disabled]) ~ #slider {
        border-color: var(
          --uui-toggle-border-color-hover,
          var(--uui-color-border-emphasis,#a1a1a1)
        );
        background-color: var(
          --uui-toggle-background-color-hover,
          var(--uui-color-border,#d8d7d9)
        );
      }

      label:focus #slider {
        border-color: var(
          --uui-toggle-border-color-focus,
          var(--uui-color-focus,#3879ff)
        );
        background-color: var(
          --uui-toggle-background-color-focus,
          var(--uui-color-surface-emphasis,rgb(250, 250, 250))
        );
      }

      input:checked ~ #slider {
        background-color: var(--uui-color-selected,#3544b1);
      }

      label:hover input:checked:not([disabled]) ~ #slider {
        background-color: var(--uui-color-selected-emphasis,rgb(70, 86, 200));
      }

      label:focus input:checked ~ #slider {
        background-color: var(--uui-color-selected-emphasis,rgb(70, 86, 200));
      }

      #icon-checked,
      #icon-unchecked {
        position: absolute;
        vertical-align: middle;
        width: 1em;
        height: 1em;
        line-height: 0;
        transition: color 120ms;
      }

      #icon-checked {
        margin-left: -0.5em;
        left: calc(var(--uui-toggle-size) * 0.5);
        color: var(--uui-color-interactive,#1b264f);
      }

      #icon-unchecked {
        margin-right: -0.5em;
        right: calc(var(--uui-toggle-size) * 0.5);
        color: var(--uui-color-interactive,#1b264f);
      }

      input:checked ~ #slider #icon-checked {
        color: var(--uui-color-selected-contrast,#fff);
      }

      #slider::after {
        content: '';
        position: absolute;
        top: 2px;
        left: 2px;
        width: calc(var(--uui-toggle-size) - 4px);
        height: calc(var(--uui-toggle-size) - 4px);
        border-radius: 100px;
        background-color: var(--uui-color-selected-contrast,#fff);
        transition:
          width 120ms ease,
          left 120ms ease,
          transform 120ms ease,
          background-color 120ms;
      }

      input:checked ~ #slider::after {
        left: calc(100% - 2px);
        transform: translateX(-100%);
      }

      input:focus ~ #slider {
        outline: calc(2px * var(--uui-show-focus-outline, 1)) solid
          var(--uui-color-focus,#3879ff);
      }

      :host(:not([disabled], [readonly])) label:active #slider::after {
        /** Stretch when mouse down */
        width: calc(1.06 * var(--uui-toggle-size));
      }

      :host([disabled]) #slider {
        background-color: var(--uui-color-disabled-standalone,rgb(226, 226, 226));
      }
      :host([disabled]) input:checked ~ #slider {
        background-color: var(--uui-color-disabled-contrast,#c4c4c4);
      }
      :host([disabled]) #slider::after {
        background-color: var(--uui-color-disabled,#f3f3f5);
      }
      :host([disabled]) #slider #icon-unchecked {
        color: var(--uui-color-disabled-contrast,#c4c4c4);
      }
      :host([disabled]) label:active #slider {
        animation: ${UUIHorizontalShakeAnimationValue};
      }
      :host([disabled]) input:checked #slider #icon-checked {
        color: var(--uui-color-disabled-contrast,#c4c4c4);
      }

      :host(:not([pristine]):invalid) #slider,
      :host(:not([pristine]):invalid) label:hover #slider,
      /* polyfill support */
      :host(:not([pristine])[internals-invalid]) #slider,
      :host(:not([pristine])[internals-invalid]) label:hover #slider {
        border: 1px solid var(--uui-color-danger-standalone,rgb(191, 33, 78));
      }
    `
];
UUIToggleElement = __decorateClass([
  defineElement("uui-toggle")
], UUIToggleElement);

export { UUIToggleElement };
