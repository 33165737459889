import { css, LitElement, html } from 'lit';
import { defineElement } from '@umbraco-ui/uui-base/lib/registration';

var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp(target, key, result);
  return result;
};
let UUIRefListElement = class extends LitElement {
  render() {
    return html`<slot></slot>`;
  }
};
UUIRefListElement.styles = [
  css`
      :host {
        display: block;
      }

      ::slotted(*:not(:first-child)) {
        margin-top: 1px;
      }
      ::slotted(*:not(:first-child))::before {
        content: '';
        position: absolute;
        top: -1px;
        left: 0;
        right: 0;
        border-top: 1px solid var(--uui-color-border,#d8d7d9);
      }
    `
];
UUIRefListElement = __decorateClass([
  defineElement("uui-ref-list")
], UUIRefListElement);

export { UUIRefListElement };
