import { defineElement } from '@umbraco-ui/uui-base/lib/registration';
import { Timer, demandCustomElement } from '@umbraco-ui/uui-base/lib/utils';
import { UUITextStyles } from '@umbraco-ui/uui-css/lib';
import { iconRemove } from '@umbraco-ui/uui-icon-registry-essential/lib/svgs';
import { css, LitElement, html } from 'lit';
import { property, query, state } from 'lit/decorators.js';
import { UUIEvent } from '@umbraco-ui/uui-base/lib/events';

class UUIToastNotificationEvent extends UUIEvent {
}
UUIToastNotificationEvent.OPENING = "opening";
UUIToastNotificationEvent.OPENED = "opened";
UUIToastNotificationEvent.CLOSING = "closing";
UUIToastNotificationEvent.CLOSED = "closed";

var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp(target, key, result);
  return result;
};
let UUIToastNotificationElement = class extends LitElement {
  constructor() {
    super();
    this.color = "";
    this._autoClose = null;
    this._onOpenTimerComplete = () => {
      if (this._open) {
        this.open = false;
      }
    };
    this._timer = null;
    this._pauseTimer = false;
    this.isOpen = false;
    this._open = false;
    this._animate = false;
    this._requestAnimationUpdate = 0;
    this.addEventListener("keyup", (event) => {
      if (event.key === "Escape") {
        this.open = false;
      }
    });
  }
  get autoClose() {
    return this._autoClose;
  }
  set autoClose(value) {
    this._autoClose = value;
    if (value !== null) {
      if (this._timer === null) {
        this._timer = new Timer(this._onOpenTimerComplete, value);
      } else {
        this._timer.setDuration(value);
      }
      if (this._pauseTimer === false && this.isOpen === true && this._animate === false) {
        this._timer.start();
      }
    } else {
      this._timer?.destroy();
      this._timer = null;
    }
  }
  /**
   * Pause the auto close timer.
   */
  pauseAutoClose() {
    this._pauseTimer = true;
    if (this._timer !== null) {
      this._timer.pause();
    }
  }
  /**
   * Resume the auto close timer.
   */
  resumeAutoClose() {
    this._pauseTimer = false;
    if (this._timer !== null && this.isOpen === true && this._animate === false) {
      this._timer.restart();
    }
  }
  get open() {
    return this._open;
  }
  set open(value) {
    if (value === true) {
      this._makeOpen();
    } else {
      this._makeClose();
    }
  }
  connectedCallback() {
    super.connectedCallback();
    demandCustomElement(this, "uui-button");
    demandCustomElement(this, "uui-icon");
  }
  _getAnimationDuration() {
    return parseInt(
      getComputedStyle(this).getPropertyValue(
        "--uui-toast-notification-animation-duration"
      ),
      10
    ) || 480;
  }
  _makeOpen() {
    if (this._open === true) {
      return;
    }
    this._open = true;
    this.updateComplete.then(() => {
      if (this._open !== true) {
        return;
      }
      cancelAnimationFrame(this._requestAnimationUpdate);
      this._requestAnimationUpdate = requestAnimationFrame(() => {
        clearTimeout(this._animationTimeout);
        this.isOpen = true;
        this.setAttribute("is-open", "");
        this.style.height = this._toastEl.getBoundingClientRect().height + "px";
        this._animate = true;
        this.dispatchEvent(
          new UUIToastNotificationEvent(UUIToastNotificationEvent.OPENING)
        );
        this._animationTimeout = window.setTimeout(() => {
          if (this.isOpen === true) {
            this.style.height = "auto";
            this._animate = false;
            if (this._pauseTimer === false) {
              this._timer?.start();
            }
            this.dispatchEvent(
              new UUIToastNotificationEvent(UUIToastNotificationEvent.OPENED)
            );
          }
        }, this._getAnimationDuration());
      });
    });
  }
  _makeClose() {
    if (this._open === false) {
      return;
    }
    const event = new UUIToastNotificationEvent(
      UUIToastNotificationEvent.CLOSING,
      { cancelable: true }
    );
    this.dispatchEvent(event);
    if (event.defaultPrevented === true) {
      return;
    }
    this._open = false;
    this._timer?.pause();
    cancelAnimationFrame(this._requestAnimationUpdate);
    if (this.isOpen === true) {
      this._requestAnimationUpdate = requestAnimationFrame(() => {
        clearTimeout(this._animationTimeout);
        this.isOpen = false;
        this.removeAttribute("is-open");
        this.style.height = this._toastEl.getBoundingClientRect().height + "px";
        this._animate = true;
        requestAnimationFrame(() => {
          this.style.height = "0";
        });
        this._animationTimeout = window.setTimeout(() => {
          if (this.isOpen === false) {
            this._animate = false;
            this.dispatchEvent(
              new UUIToastNotificationEvent(UUIToastNotificationEvent.CLOSED)
            );
            if (this.parentNode) {
              this.parentNode.removeChild(this);
            }
          }
        }, this._getAnimationDuration());
      });
    }
  }
  render() {
    return html`
      <div id="toast" class=${this._animate ? "animate" : ""}>
        <div>
          <div id="close">
            <uui-button
              .label=${"close"}
              .color=${this.color}
              .look=${this.color ? "primary" : "default"}
              @click=${() => this.open = false}>
              <uui-icon
                name="remove"
                .fallback=${iconRemove.strings[0]}></uui-icon>
            </uui-button>
          </div>
          <slot></slot>
        </div>
      </div>
    `;
  }
};
UUIToastNotificationElement.styles = [
  UUITextStyles,
  css`
      :host {
        --uui-toast-notification-margin: var(--uui-size-space-2,6px);

        position: relative;
        display: block;
        width: 100%;
        max-width: 400px;
        margin: 0 var(--uui-toast-notification-margin);
        box-sizing: border-box;

        height: 0;
        pointer-events: none;

        transition: height
          var(--uui-toast-notification-animation-duration, 480ms) ease-in-out;
      }
      :host([is-open]) {
        pointer-events: all;
        transition-timing-function: cubic-bezier(
          0.19,
          1,
          0.22,
          1
        ); /* easeOutExpo */
      }

      #toast {
        position: relative;
        display: block;
        padding: calc(var(--uui-toast-notification-margin) * 0.5) 0;
        width: 100%;
        max-width: 400px;
      }
      #toast.animate {
        position: absolute;
      }

      #toast > div {
        position: relative;
        display: block;

        box-sizing: border-box;
        box-shadow: var(--uui-shadow-depth-1,0 1px 3px rgba(0,0,0,0.12) , 0 1px 2px rgba(0,0,0,0.24));
        background-color: var(--uui-color-surface,#fff);
        padding: var(--uui-size-layout-1,24px);
        padding-right: var(--uui-size-layout-1,24px);
        padding-left: var(--uui-size-layout-3,42px);
        border-radius: calc(var(--uui-border-radius,3px) * 2);

        opacity: 0;
        transition: opacity
          var(--uui-toast-notification-animation-duration, 480ms);
      }
      :host([is-open]) #toast > div {
        opacity: 1;
      }

      #close {
        float: right;
        margin-top: -6px;
        margin-left: var(--uui-size-space-1,3px);
        margin-bottom: -4px;
      }

      #close > uui-button {
        --uui-button-border-radius: 50px 50px 50px 50px;
        --uui-button-padding-left-factor: 1.5;
        --uui-button-padding-right-factor: 1.5;
      }

      :host #toast > div {
        background-color: var(--uui-color-surface,#fff);
        color: var(--uui-color-text,#060606);
        border-color: var(--uui-color-surface,#fff);
      }
      :host([color='default']) #toast > div {
        background-color: var(--uui-color-default,#1b264f);
        color: var(--uui-color-default-contrast,#fff);
        border-color: var(--uui-color-default-standalone,rgb(28, 35, 59));
      }
      :host([color='positive']) #toast > div {
        background-color: var(--uui-color-positive,#0b8152);
        color: var(--uui-color-positive-contrast,#fff);
        border-color: var(--uui-color-positive-standalone,rgb(10, 115, 73));
      }
      :host([color='warning']) #toast > div {
        background-color: var(--uui-color-warning,#fbd142);
        color: var(--uui-color-warning-contrast,#000);
        border-color: var(--uui-color-warning-standalone,#a17700);
      }
      :host([color='danger']) #toast > div {
        background-color: var(--uui-color-danger,#d42054);
        color: var(--uui-color-danger-contrast,white);
        border-color: var(--uui-color-danger-standalone,rgb(191, 33, 78));
      }
    `
];
__decorateClass([
  property({ reflect: true })
], UUIToastNotificationElement.prototype, "color", 2);
__decorateClass([
  property({ type: Number })
], UUIToastNotificationElement.prototype, "autoClose", 1);
__decorateClass([
  query("#toast")
], UUIToastNotificationElement.prototype, "_toastEl", 2);
__decorateClass([
  state()
], UUIToastNotificationElement.prototype, "_animate", 2);
__decorateClass([
  property({ type: Boolean, reflect: true })
], UUIToastNotificationElement.prototype, "open", 1);
UUIToastNotificationElement = __decorateClass([
  defineElement("uui-toast-notification")
], UUIToastNotificationElement);

export { UUIToastNotificationElement, UUIToastNotificationEvent };
