import { LitElement, html } from 'lit';
import { defineElement } from '@umbraco-ui/uui-base/lib/registration';
import { property } from 'lit/decorators.js';
import { UUIIconRequestEvent } from '@umbraco-ui/uui-icon/lib';

class UUIIconHost {
  constructor(svg) {
    this.promise = new Promise((resolveMethod, rejectMethod) => {
      this.resolve = resolveMethod;
      this.reject = rejectMethod;
    });
    if (svg) {
      this.resolve(svg);
    }
  }
  set svg(svg) {
    this.resolve(svg);
  }
}

class UUIIconRegistry {
  constructor() {
    this.icons = {};
    this._onIconRequest = (event) => {
      const icon = this.getIcon(event.detail.iconName);
      if (icon !== null) {
        event.acceptRequest(icon);
      }
    };
  }
  /**
   * Attach an element to provide this registry. Use detach when disconnected.
   * @param {EventTarget} element the element of which to provide this icon-set.
   */
  attach(element) {
    element.addEventListener(
      UUIIconRequestEvent.ICON_REQUEST,
      this._onIconRequest
    );
  }
  /**
   * Detach an element from providing this registry.
   * @param {EventTarget} element the element of which to stop providing this icon-set.
   */
  detach(element) {
    element.removeEventListener(
      UUIIconRequestEvent.ICON_REQUEST,
      this._onIconRequest
    );
  }
  /**
   * Define a icon to be served by this registry.
   * @param {string} iconName the name to use for this icon.
   * @param {string} svgString the svg source for this icon.
   */
  defineIcon(iconName, svgString) {
    if (this.icons[iconName]) {
      this.icons[iconName].svg = svgString;
      return;
    }
    this.icons[iconName] = new UUIIconHost(svgString);
  }
  /**
   * Retrieve the SVG source of an icon, Returns ´null´ if the name does not exist.
   * @param {string} iconName the name of the icon to retrieve.
   */
  getIcon(iconName) {
    if (!!this.icons[iconName] || this.acceptIcon(iconName)) {
      return this.icons[iconName].promise;
    }
    return null;
  }
  /**
   * Declare that this registry will be providing a icon for this name
   * @param {string} iconName the name of the icon to be provided.
   */
  provideIcon(iconName) {
    return this.icons[iconName] = new UUIIconHost();
  }
  /**
   * extend this method to provide your own logic.
   * @param iconName
   * @returns
   */
  //@ts-ignore-start
  // eslint-disable-next-line
  acceptIcon(iconName) {
    return false;
  }
  //@ts-ignore-end
  /**
     * Dynamic concept by extending this class:
     * extend getIcon in this way:
  
      protected acceptIcon(iconName: string): boolean {
  
        // Check if this is something we want to accept and provide.
        if(iconName === "myCustomIcon") {
  
          // Inform that we will be providing this.
          const icon = this.provideIcon(iconName);
  
          // When data is available set it on this icon object, this can be done at any point in time:
          icon.svg = "...";
  
          return true;
        }
  
        return false;
      }
    */
  getIconNames() {
    return Object.keys(this.icons);
  }
}

var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp(target, key, result);
  return result;
};
let UUIIconRegistryElement = class extends LitElement {
  constructor() {
    super();
    this._icons = {};
    this._registry = new UUIIconRegistry();
    this._registry.attach(this);
  }
  get icons() {
    return this._icons;
  }
  set icons(icons) {
    this._icons = icons;
    if (this._registry) {
      Object.entries(this._icons).forEach(
        ([key, value]) => this._registry.defineIcon(key, value)
      );
    }
  }
  get registry() {
    return this._registry;
  }
  set registry(newRegistry) {
    if (this.registry) {
      this.registry.detach(this);
    }
    newRegistry.attach(this);
    this._registry = newRegistry;
  }
  render() {
    return html`<slot></slot>`;
  }
};
__decorateClass([
  property({ attribute: false })
], UUIIconRegistryElement.prototype, "_icons", 2);
UUIIconRegistryElement = __decorateClass([
  defineElement("uui-icon-registry")
], UUIIconRegistryElement);

export { UUIIconHost, UUIIconRegistry, UUIIconRegistryElement };
