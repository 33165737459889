import { defineElement } from '@umbraco-ui/uui-base/lib/registration';
import { css, LitElement, html } from 'lit';
import { queryAssignedElements, state, property } from 'lit/decorators.js';

var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp(target, key, result);
  return result;
};
let UUIAvatarGroupElement = class extends LitElement {
  constructor() {
    super(...arguments);
    this._avatarArray = [];
    this.limit = 0;
  }
  firstUpdated() {
    this._setAvatarArray();
  }
  _onSlotChange() {
    this._setAvatarArray();
    this._updateAvatarVisibility();
  }
  _setAvatarArray() {
    this._avatarArray = this._avatarNodes ? this._avatarNodes : [];
  }
  updated(changedProperties) {
    if (changedProperties.has("limit")) {
      this._updateAvatarVisibility();
    }
  }
  _updateAvatarVisibility() {
    this._avatarArray.forEach((avatar, index) => {
      avatar.style.display = index < this.limit || this.limit === 0 ? "" : "none";
    });
  }
  _isLimitExceeded() {
    return this.limit !== 0 && this._avatarArray.length > this.limit;
  }
  render() {
    return html`
      <slot @slotchange=${this._onSlotChange}></slot>
      ${this._isLimitExceeded() ? (
      //prettier-ignore
      html`<small id="overflow-indication">+${this._avatarArray.length - this.limit}</small>`
    ) : ""}
    `;
  }
};
UUIAvatarGroupElement.styles = [
  css`
      :host {
        display: inline-flex;
        align-items: center;
        padding-left: 3px;
        padding-right: 3px;
      }

      ::slotted(uui-avatar) {
        margin-left: -0.2em;
        margin-right: -0.2em;
        border: 0.1em solid var(--uui-avatar-border-color);
      }

      #overflow-indication {
        margin-left: 6px;
      }
    `
];
__decorateClass([
  queryAssignedElements({
    selector: "uui-avatar, [uui-avatar]",
    flatten: true
  })
], UUIAvatarGroupElement.prototype, "_avatarNodes", 2);
__decorateClass([
  state()
], UUIAvatarGroupElement.prototype, "_avatarArray", 2);
__decorateClass([
  property({ type: Number, attribute: true })
], UUIAvatarGroupElement.prototype, "limit", 2);
UUIAvatarGroupElement = __decorateClass([
  defineElement("uui-avatar-group")
], UUIAvatarGroupElement);

export { UUIAvatarGroupElement };
