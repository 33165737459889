import { defineElement } from '@umbraco-ui/uui-base/lib/registration';
import { demandCustomElement } from '@umbraco-ui/uui-base/lib/utils';
import { ifDefined } from 'lit/directives/if-defined.js';
import { UUIRefElement } from '@umbraco-ui/uui-ref/lib';
import { css, html, nothing } from 'lit';
import { property, state } from 'lit/decorators.js';

var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __typeError = (msg) => {
  throw TypeError(msg);
};
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp(target, key, result);
  return result;
};
var __accessCheck = (obj, member, msg) => member.has(obj) || __typeError("Cannot " + msg);
var __privateAdd = (obj, member, value) => member.has(obj) ? __typeError("Cannot add the same private member more than once") : member instanceof WeakSet ? member.add(obj) : member.set(obj, value);
var __privateMethod = (obj, member, method) => (__accessCheck(obj, member, "access private method"), method);
var _UUIRefNodeElement_instances, onSlotIconChange_fn, renderFallbackIcon_fn, renderContent_fn, renderLink_fn, renderButton_fn, renderSomething_fn;
let UUIRefNodeElement = class extends UUIRefElement {
  constructor() {
    super(...arguments);
    __privateAdd(this, _UUIRefNodeElement_instances);
    this.name = "";
    this.detail = "";
    this._iconSlotHasContent = false;
    this.fallbackIcon = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M396.441 138.878l-83.997-83.993-7.331-7.333H105.702v416.701h298.071V146.214l-7.332-7.336zM130.74 439.217V72.591h141.613c37.201 0 19.274 88.18 19.274 88.18s86-20.901 87.104 18.534v259.912H130.74z"></path></svg>';
  }
  connectedCallback() {
    super.connectedCallback();
    demandCustomElement(this, "uui-icon");
  }
  renderDetail() {
    return html`<small id="detail"
      >${this.detail}<slot name="detail"></slot
    ></small>`;
  }
  render() {
    return html`
      ${__privateMethod(this, _UUIRefNodeElement_instances, renderSomething_fn).call(this)}
      <!-- Select border must be right after #open-part -->
      <div id="select-border"></div>

      <slot></slot>
      <slot name="tag"></slot>
      <slot name="actions" id="actions-container"></slot>
    `;
  }
};
_UUIRefNodeElement_instances = new WeakSet();
onSlotIconChange_fn = function(event) {
  this._iconSlotHasContent = event.target.assignedNodes({ flatten: true }).length > 0;
};
renderFallbackIcon_fn = function() {
  return html`<uui-icon .svg="${this.fallbackIcon}"></uui-icon>`;
};
renderContent_fn = function() {
  return html`
      <span id="content">
        <span id="icon">
          <slot name="icon" @slotchange=${__privateMethod(this, _UUIRefNodeElement_instances, onSlotIconChange_fn)}></slot>
          ${this._iconSlotHasContent === false ? __privateMethod(this, _UUIRefNodeElement_instances, renderFallbackIcon_fn).call(this) : ""}
        </span>
        <div id="info">
          <div id="name">${this.name}<slot name="name"></slot></div>
          ${this.renderDetail()}
        </div>
      </span>
    `;
};
renderLink_fn = function() {
  return html`<a
      id="open-part"
      tabindex=${this.disabled ? nothing : "0"}
      href=${ifDefined(!this.disabled ? this.href : void 0)}
      target=${ifDefined(this.target || void 0)}
      rel=${ifDefined(
    this.rel || ifDefined(
      this.target === "_blank" ? "noopener noreferrer" : void 0
    )
  )}>
      ${__privateMethod(this, _UUIRefNodeElement_instances, renderContent_fn).call(this)}
    </a>`;
};
renderButton_fn = function() {
  return html`
      <button
        type="button"
        id="open-part"
        tabindex="0"
        @click=${this.handleOpenClick}
        @keydown=${this.handleOpenKeydown}
        ?disabled=${this.disabled}>
        ${__privateMethod(this, _UUIRefNodeElement_instances, renderContent_fn).call(this)}
      </button>
    `;
};
renderSomething_fn = function() {
  if (this.readonly) {
    return html`${__privateMethod(this, _UUIRefNodeElement_instances, renderContent_fn).call(this)}`;
  } else {
    return this.href ? __privateMethod(this, _UUIRefNodeElement_instances, renderLink_fn).call(this) : __privateMethod(this, _UUIRefNodeElement_instances, renderButton_fn).call(this);
  }
};
UUIRefNodeElement.styles = [
  ...UUIRefElement.styles,
  css`
      :host {
        min-width: 250px;
        padding: calc(var(--uui-size-2,6px) + 1px);
      }

      #content {
        align-self: stretch;
        line-height: normal;
        display: flex;
        position: relative;
        align-items: center;
      }

      #open-part {
        color: inherit;
        text-decoration: none;
        cursor: pointer;
      }

      #icon {
        font-size: 1.2em;
        margin-left: var(--uui-size-2,6px);
        margin-right: var(--uui-size-1,3px);
      }

      #info {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        height: 100%;
        padding-left: var(--uui-size-2,6px);
      }

      #name {
        font-weight: 700;
      }

      #detail {
        font-size: var(--uui-type-small-size,12px);
      }

      :host(:not([disabled])) #open-part:hover #icon {
        color: var(--uui-color-interactive-emphasis,#3544b1);
      }
      :host(:not([disabled])) #open-part:hover #name {
        font-weight: 700;
        text-decoration: underline;
        color: var(--uui-color-interactive-emphasis,#3544b1);
      }
      :host(:not([disabled])) #open-part:hover #detail {
        color: var(--uui-color-interactive-emphasis,#3544b1);
      }

      :host([disabled]) #icon {
        color: var(--uui-color-disabled-contrast,#c4c4c4);
      }
      :host([disabled]) #name {
        color: var(--uui-color-disabled-contrast,#c4c4c4);
      }
      :host([disabled]) #detail {
        color: var(--uui-color-disabled-contrast,#c4c4c4);
      }
    `
];
__decorateClass([
  property({ type: String })
], UUIRefNodeElement.prototype, "name", 2);
__decorateClass([
  property({ type: String })
], UUIRefNodeElement.prototype, "detail", 2);
__decorateClass([
  property({ type: String })
], UUIRefNodeElement.prototype, "href", 2);
__decorateClass([
  property({ type: String })
], UUIRefNodeElement.prototype, "target", 2);
__decorateClass([
  property({ type: String })
], UUIRefNodeElement.prototype, "rel", 2);
__decorateClass([
  state()
], UUIRefNodeElement.prototype, "_iconSlotHasContent", 2);
UUIRefNodeElement = __decorateClass([
  defineElement("uui-ref-node")
], UUIRefNodeElement);

export { UUIRefNodeElement };
