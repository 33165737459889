import { defineElement } from '@umbraco-ui/uui-base/lib/registration';
import { css, LitElement, html } from 'lit';
import { property } from 'lit/decorators.js';

var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp(target, key, result);
  return result;
};
let UUILabelElement = class extends LitElement {
  constructor() {
    super();
    this.disabled = false;
    this.for = null;
    this.required = false;
    this.addEventListener("click", this._onClick);
  }
  _onClick() {
    if (this.disabled) return;
    const el = this.getForElement();
    if (el) {
      el.focus();
      el.click();
    }
  }
  getForElement() {
    if (typeof this.for === "string") {
      const scope = this.getRootNode();
      return scope?.getElementById(this.for) || null;
    }
    return this.for || null;
  }
  render() {
    return html`
      <slot></slot>
      ${this.required ? html`<div id="required">*</div>` : ""}
    `;
  }
};
UUILabelElement.styles = [
  css`
      :host {
        font-weight: 700;
      }
      :host([for]) {
        cursor: pointer;
      }
      :host([disabled]) {
        cursor: default;
      }
      #required {
        display: inline;
        color: var(--uui-color-danger,#d42054);
        font-weight: 900;
      }
    `
];
__decorateClass([
  property({ type: Boolean, reflect: true })
], UUILabelElement.prototype, "disabled", 2);
__decorateClass([
  property({ reflect: true, attribute: true })
], UUILabelElement.prototype, "for", 2);
__decorateClass([
  property({ type: Boolean, reflect: true })
], UUILabelElement.prototype, "required", 2);
UUILabelElement = __decorateClass([
  defineElement("uui-label")
], UUILabelElement);

export { UUILabelElement };
