import { defineElement } from '@umbraco-ui/uui-base/lib/registration';
import { state, property } from 'lit/decorators.js';
import { css, LitElement, html } from 'lit';
import { demandCustomElement } from '@umbraco-ui/uui-base/lib/utils';

class UUIFileSize {
  static humanFileSize(bytes, si = false, decimalPlaces = 1) {
    const thresh = si ? 1e3 : 1024;
    if (Math.abs(bytes) < thresh) {
      return bytes + " B";
    }
    const units = si ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"] : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    let u = -1;
    const r = 10 ** decimalPlaces;
    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
    return bytes.toFixed(decimalPlaces) + " " + units[u];
  }
}

var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp(target, key, result);
  return result;
};
let UUIFilePreviewElement = class extends LitElement {
  constructor() {
    super(...arguments);
    this._name = "";
    this._url = "";
    this._extension = "";
    this._src = "";
    this._size = 0;
    this._isDirectory = false;
  }
  get file() {
    return this._file;
  }
  set file(newValue) {
    const oldValue = this._file;
    if (newValue instanceof File) {
      this._name = newValue.name.split(".")[0];
      this._extension = newValue.name.split(".")[1];
      this._isDirectory = false;
      this._size = newValue.size;
      if (this._isFileAnImage(newValue)) {
        this._isImage = true;
        this._getThumbnail(newValue).then((result) => {
          this._src = result;
        });
      }
      this.requestUpdate("file", oldValue);
    }
  }
  connectedCallback() {
    super.connectedCallback();
    demandCustomElement(this, "uui-symbol-file-thumbnail");
    demandCustomElement(this, "uui-symbol-folder");
    demandCustomElement(this, "uui-symbol-file");
  }
  _openSource() {
    window.open(this._url, "_blank");
  }
  _fileTypeTemplate() {
    if (this._isDirectory) {
      return html`<uui-symbol-folder id="file-symbol"></uui-symbol-folder>`;
    }
    if (this._isImage) {
      return html`<uui-symbol-file-thumbnail
        .src=${this._src}
        .alt=${this._name}
        id="file-symbol"></uui-symbol-file-thumbnail>`;
    }
    return html`<uui-symbol-file
      id="file-symbol"
      .type=${this._extension}></uui-symbol-file>`;
  }
  _renderLongName() {
    const endCharCount = 6;
    const nameStart = this._name.substring(0, this._name.length - endCharCount);
    const nameEnd = this._name.substring(
      this._name.length - endCharCount,
      this._name.length
    );
    return html`
      <span
        id="file-name"
        class=${this._url ? "has-source" : ""}
        @click=${() => this._url ? this._openSource() : ""}
        @keydown=${() => ""}>
        <span id="file-name-start">${nameStart}</span>
        <span id="file-name-end">${nameEnd}</span>
      </span>
    `;
  }
  _isFileAnImage(file) {
    return file ? file["type"].split("/")[0] === "image" : false;
  }
  async _getThumbnail(file) {
    return await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
    });
  }
  render() {
    return html`
      <slot id="actions" name="actions"></slot>
      ${this._fileTypeTemplate()}
      <div id="file-info">
        ${this._renderLongName()}
        <span id="file-size">
          ${this._size && !this._isDirectory ? html`${UUIFileSize.humanFileSize(this._size, true)}` : ""}
        </span>
      </div>
    `;
  }
};
UUIFilePreviewElement.styles = [
  css`
      :host {
        position: relative;
        display: grid;
        /* These have to be changed together */
        grid-template-rows: 100px 50px;
        width: 150px;
        height: 150px;
        /* --------------------------------- */
        box-sizing: border-box;
        aspect-ratio: 1;
        color: var(--uui-color-text,#060606);
      }

      :host(:hover) slot[name='actions']::slotted(*) {
        opacity: 1;
      }

      slot[name='actions']::slotted(*) {
        position: absolute;
        top: 8px;
        right: 8px;
        max-width: 100%;
        height: 32px;
        font-size: 13px;
        opacity: 0;
        z-index: 1;
        transition: opacity 150ms;
      }

      #file-symbol {
        aspect-ratio: 1 / 1;
        margin: auto;
        max-width: 100%;
        max-height: 100%;
      }

      #file-info {
        min-width: 0;
        display: flex;
        text-align: center;
        flex-direction: column;
        font-size: 1rem;
      }

      #file-name {
        display: flex;
        justify-content: center;
      }

      #file-name-start {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      #file-name-end {
        white-space: nowrap;
      }

      #file-size {
        opacity: 0.6;
      }

      .has-source:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    `
];
__decorateClass([
  state()
], UUIFilePreviewElement.prototype, "_name", 2);
__decorateClass([
  state()
], UUIFilePreviewElement.prototype, "_url", 2);
__decorateClass([
  state()
], UUIFilePreviewElement.prototype, "_extension", 2);
__decorateClass([
  state()
], UUIFilePreviewElement.prototype, "_src", 2);
__decorateClass([
  state()
], UUIFilePreviewElement.prototype, "_size", 2);
__decorateClass([
  state()
], UUIFilePreviewElement.prototype, "_isDirectory", 2);
__decorateClass([
  state()
], UUIFilePreviewElement.prototype, "_file", 2);
__decorateClass([
  state()
], UUIFilePreviewElement.prototype, "_isImage", 2);
__decorateClass([
  property({ attribute: false })
], UUIFilePreviewElement.prototype, "file", 1);
UUIFilePreviewElement = __decorateClass([
  defineElement("uui-file-preview")
], UUIFilePreviewElement);

export { UUIFilePreviewElement };
