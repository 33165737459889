import { defineElement } from '@umbraco-ui/uui-base/lib/registration';
import { css, LitElement, html } from 'lit';
import { property, queryAssignedElements, state } from 'lit/decorators.js';
import { UUIEvent, UUISelectableEvent } from '@umbraco-ui/uui-base/lib/events';
import { SelectableMixin, ActiveMixin } from '@umbraco-ui/uui-base/lib/mixins';

class UUIComboboxListEvent extends UUIEvent {
  constructor(evName, eventInit = {}) {
    super(evName, {
      ...{ bubbles: true },
      ...eventInit
    });
  }
}
UUIComboboxListEvent.CHANGE = "change";
UUIComboboxListEvent.INNER_SLOT_CHANGE = "inner-slot-change";

var __defProp$1 = Object.defineProperty;
var __getOwnPropDesc$1 = Object.getOwnPropertyDescriptor;
var __typeError = (msg) => {
  throw TypeError(msg);
};
var __decorateClass$1 = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc$1(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp$1(target, key, result);
  return result;
};
var __accessCheck = (obj, member, msg) => member.has(obj) || __typeError("Cannot " + msg);
var __privateAdd = (obj, member, value) => member.has(obj) ? __typeError("Cannot add the same private member more than once") : member instanceof WeakSet ? member.add(obj) : member.set(obj, value);
var __privateMethod = (obj, member, method) => (__accessCheck(obj, member, "access private method"), method);
var _UUIComboboxListElement_instances, updateActiveElement_fn;
let UUIComboboxListElement = class extends LitElement {
  constructor() {
    super(...arguments);
    __privateAdd(this, _UUIComboboxListElement_instances);
    this.displayValue = "";
    this._value = "";
    this._activeElementValue = null;
    this._onSlotChange = () => {
      __privateMethod(this, _UUIComboboxListElement_instances, updateActiveElement_fn).call(this);
      this._updateSelection();
      this.dispatchEvent(
        new UUIComboboxListEvent(UUIComboboxListEvent.INNER_SLOT_CHANGE)
      );
    };
    this._onSelected = (e) => {
      if (this._selectedElement) {
        this._selectedElement.selected = false;
        this._selectedElement.active = false;
        this._selectedElement = void 0;
      }
      this._selectedElement = e.composedPath()[0];
      this.value = this._selectedElement.value || "";
      this.displayValue = this._selectedElement.displayValue || "";
      this.dispatchEvent(new UUIComboboxListEvent(UUIComboboxListEvent.CHANGE));
    };
    this._onDeselected = (e) => {
      const el = e.composedPath()[0];
      if (this._selectedElement === el) {
        this.value = "";
        this.displayValue = "";
        this.dispatchEvent(new UUIComboboxListEvent(UUIComboboxListEvent.CHANGE));
      }
    };
    this._moveIndex = (distance) => {
      const newIndex = Math.min(
        Math.max(this._getActiveIndex + distance, 0),
        this._options.length - 1
      );
      this._goToIndex(newIndex);
    };
    this._onKeyDown = (e) => {
      if (this._options.length <= 0) return;
      switch (e.key) {
        case "ArrowUp":
          e.preventDefault();
          e.ctrlKey ? this._moveIndex(-10) : this._moveIndex(-1);
          break;
        case "ArrowDown":
          e.preventDefault();
          e.ctrlKey ? this._moveIndex(10) : this._moveIndex(1);
          break;
        case "Home": {
          e.preventDefault();
          this._goToIndex(0);
          break;
        }
        case "Enter": {
          e.preventDefault();
          this._getActiveElement?.click();
          break;
        }
        case "End": {
          e.preventDefault();
          this._goToIndex(this._options.length - 1);
          break;
        }
      }
    };
  }
  get value() {
    return this._value;
  }
  set value(newValue) {
    if (this._value === newValue) return;
    const oldValue = this._value;
    this._value = newValue;
    this._updateSelection();
    this.requestUpdate("value", oldValue);
  }
  get for() {
    return this._for;
  }
  set for(newValue) {
    if (this._for) {
      this._for.removeEventListener("keydown", this._onKeyDown);
    }
    this._for = newValue;
    if (this._for) {
      this._for.addEventListener("keydown", this._onKeyDown);
    }
  }
  connectedCallback() {
    super.connectedCallback();
    if (!this._for) {
      this._for = this;
    }
    this.addEventListener(UUISelectableEvent.SELECTED, this._onSelected);
    this.addEventListener(UUISelectableEvent.DESELECTED, this._onDeselected);
  }
  disconnectedCallback() {
    super.disconnectedCallback();
    document.removeEventListener("keydown", this._onKeyDown);
    this.removeEventListener(UUISelectableEvent.SELECTED, this._onSelected);
    this.removeEventListener(UUISelectableEvent.DESELECTED, this._onDeselected);
  }
  _updateSelection() {
    this.displayValue = "";
    for (const option of this._options) {
      if (option.value === this._value) {
        this.displayValue = option.displayValue || "";
        option.selected = true;
      } else {
        option.selected = false;
      }
    }
  }
  get _getActiveIndex() {
    if (this._activeElementValue === null) return -1;
    return this._options.findIndex(
      (element) => element.value === this._activeElementValue
    );
  }
  get _getActiveElement() {
    if (this._activeElementValue === null) return null;
    return this._options.find(
      (element) => element.value === this._activeElementValue
    );
  }
  _goToIndex(index) {
    if (this._options.length === 0) return;
    index = Math.min(Math.max(index, 0), this._options.length - 1);
    const activeElement = this._options[index];
    this._activeElementValue = activeElement.value;
    __privateMethod(this, _UUIComboboxListElement_instances, updateActiveElement_fn).call(this);
    if (activeElement) {
      activeElement.scrollIntoView({
        behavior: "auto",
        block: "nearest",
        inline: "nearest"
      });
    }
  }
  render() {
    return html` <slot @slotchange=${this._onSlotChange}></slot> `;
  }
};
_UUIComboboxListElement_instances = new WeakSet();
updateActiveElement_fn = function() {
  for (let i = 0; i < this._activeOptions.length; i++) {
    this._activeOptions[i].active = false;
  }
  const activeElement = this._getActiveElement;
  if (activeElement) {
    activeElement.active = true;
  } else {
    this._goToIndex(0);
  }
};
UUIComboboxListElement.styles = [
  css`
      :host {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
      }
    `
];
__decorateClass$1([
  property()
], UUIComboboxListElement.prototype, "value", 1);
__decorateClass$1([
  property({ type: String })
], UUIComboboxListElement.prototype, "displayValue", 2);
__decorateClass$1([
  property({ attribute: false })
], UUIComboboxListElement.prototype, "for", 1);
__decorateClass$1([
  queryAssignedElements({
    flatten: true,
    selector: "uui-combobox-list-option:not([disabled])"
  })
], UUIComboboxListElement.prototype, "_options", 2);
__decorateClass$1([
  queryAssignedElements({
    flatten: true,
    selector: "uui-combobox-list-option[active]"
  })
], UUIComboboxListElement.prototype, "_activeOptions", 2);
__decorateClass$1([
  state()
], UUIComboboxListElement.prototype, "_value", 2);
__decorateClass$1([
  state()
], UUIComboboxListElement.prototype, "_activeElementValue", 2);
UUIComboboxListElement = __decorateClass$1([
  defineElement("uui-combobox-list")
], UUIComboboxListElement);

var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp(target, key, result);
  return result;
};
let UUIComboboxListOptionElement = class extends SelectableMixin(
  ActiveMixin(LitElement)
) {
  constructor() {
    super();
    this._disabled = false;
    this._displayValue = "";
    this.selectable = true;
    this.deselectable = false;
  }
  get value() {
    return this._value ? this._value : this.textContent?.trim() || "";
  }
  set value(newValue) {
    const oldValue = this._value;
    this._value = newValue;
    this.requestUpdate("value", oldValue);
  }
  get displayValue() {
    return this._displayValue ? this._displayValue : this.textContent?.trim() || "";
  }
  set displayValue(newValue) {
    const oldValue = this._displayValue;
    this._displayValue = newValue;
    this.requestUpdate("displayValue", oldValue);
  }
  get disabled() {
    return this._disabled;
  }
  set disabled(newValue) {
    const oldValue = this._disabled;
    this._disabled = newValue;
    this.selectable = !this._disabled;
    this.requestUpdate("disabled", oldValue);
  }
  render() {
    return html`<slot></slot>`;
  }
};
UUIComboboxListOptionElement.styles = [
  css`
      :host {
        position: relative;
        cursor: pointer;
        margin: 0 4px;
        border-radius: var(--uui-border-radius,3px);
        outline: 2px solid transparent;
        outline-offset: -2px;
        padding-left: 4px;
      }

      :host(:first-child) {
        margin-top: 6px;
      }
      :host(:last-child) {
        margin-bottom: 6px;
      }

      :host([selected]):host([active])::after {
        display: block;
        content: '';
        position: absolute;
        inset: 0px;
        outline: var(--uui-color-surface,#fff) solid 2px;
        outline-offset: -4px;
      }
      /*
      :host::before {
        display: block;
        content: '';
        opacity: 0;
        position: absolute;
        inset: 0;
        background-color: var(--uui-color-selected);
      }

      :host(:hover)::before {
        opacity: 0.15;
        border-radius: var(--uui-border-radius);
      } */

      :host(:hover) {
        background-color: var(--uui-color-surface-emphasis,rgb(250, 250, 250));
        color: var(--uui-color-interactive-emphasis,#3544b1);
      }

      :host([disabled]) {
        cursor: auto;
        color: var(--uui-color-disabled-contrast,#c4c4c4);
        background-color: var(--uui-color-disabled,#f3f3f5);
      }

      :host([disabled]:hover) {
        background-color: var(--uui-color-disabled,#f3f3f5);
      }

      :host([active]) {
        outline-color: var(--uui-color-focus,#3879ff);
      }

      :host([selected]) {
        color: var(--uui-color-selected-contrast,#fff);
        background-color: var(--uui-color-selected,#3544b1);
      }

      :host([selected]:hover) {
        color: var(--uui-color-selected-contrast,#fff);
        background-color: var(--uui-color-selected-emphasis,rgb(70, 86, 200));
      }
      :host([selected][disabled]) {
        color: var(--uui-color-disabled-contrast,#c4c4c4);
        background-color: var(--uui-color-disabled,#f3f3f5);
      }
    `
];
__decorateClass([
  state()
], UUIComboboxListOptionElement.prototype, "_disabled", 2);
__decorateClass([
  state()
], UUIComboboxListOptionElement.prototype, "_displayValue", 2);
__decorateClass([
  property({ type: String })
], UUIComboboxListOptionElement.prototype, "value", 1);
__decorateClass([
  property({ type: String, attribute: "display-value" })
], UUIComboboxListOptionElement.prototype, "displayValue", 1);
__decorateClass([
  property({ type: Boolean, reflect: true })
], UUIComboboxListOptionElement.prototype, "disabled", 1);
UUIComboboxListOptionElement = __decorateClass([
  defineElement("uui-combobox-list-option")
], UUIComboboxListOptionElement);

export { UUIComboboxListElement, UUIComboboxListEvent, UUIComboboxListOptionElement };
