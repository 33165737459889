class UUIEvent extends Event {
  constructor(evName, eventInit = {}) {
    super(evName, { ...eventInit });
    this.detail = eventInit.detail || {};
  }
}

class UUIFormControlEvent extends UUIEvent {
  constructor(evName, eventInit = {}) {
    super(evName, {
      ...{ bubbles: true },
      ...eventInit
    });
  }
}
UUIFormControlEvent.VALID = "valid";
UUIFormControlEvent.INVALID = "invalid";

class UUISelectableEvent extends UUIEvent {
  constructor(evName, eventInit = {}) {
    super(evName, {
      ...{ bubbles: true, cancelable: true },
      ...eventInit
    });
  }
}
UUISelectableEvent.SELECTED = "selected";
UUISelectableEvent.DESELECTED = "deselected";

export { UUIEvent, UUIFormControlEvent, UUISelectableEvent };
