import { UUIBlinkKeyframes, UUIBlinkAnimationValue } from '@umbraco-ui/uui-base/lib/animations';
import { defineElement } from '@umbraco-ui/uui-base/lib/registration';
import { css, LitElement, html } from 'lit';
import { state, property } from 'lit/decorators.js';
import { styleMap } from 'lit/directives/style-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { UUIEvent } from '@umbraco-ui/uui-base/lib/events';

class UUIButtonInlineCreateEvent extends UUIEvent {
  constructor(evName, eventInit = {}) {
    super(evName, {
      ...{ bubbles: true, composed: true },
      ...eventInit
    });
  }
}
UUIButtonInlineCreateEvent.CLICK = "click";

var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __typeError = (msg) => {
  throw TypeError(msg);
};
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp(target, key, result);
  return result;
};
var __accessCheck = (obj, member, msg) => member.has(obj) || __typeError("Cannot " + msg);
var __privateAdd = (obj, member, value) => member.has(obj) ? __typeError("Cannot add the same private member more than once") : member instanceof WeakSet ? member.add(obj) : member.set(obj, value);
var __privateMethod = (obj, member, method) => (__accessCheck(obj, member, "access private method"), method);
var _UUIButtonInlineCreateElement_instances, renderContent_fn, renderLink_fn, renderButton_fn;
let UUIButtonInlineCreateElement = class extends LitElement {
  constructor() {
    super(...arguments);
    __privateAdd(this, _UUIButtonInlineCreateElement_instances);
    this._position = 0;
    this.vertical = false;
  }
  _onMouseMove(e) {
    this._position = (this.vertical ? e.offsetY : e.offsetX) - 5;
  }
  _handleClick(e) {
    e.preventDefault();
    e.stopImmediatePropagation();
    e.target?.blur?.();
    this.dispatchEvent(
      new UUIButtonInlineCreateEvent(UUIButtonInlineCreateEvent.CLICK)
    );
  }
  render() {
    return this.href ? __privateMethod(this, _UUIButtonInlineCreateElement_instances, renderLink_fn).call(this) : __privateMethod(this, _UUIButtonInlineCreateElement_instances, renderButton_fn).call(this);
  }
};
_UUIButtonInlineCreateElement_instances = new WeakSet();
renderContent_fn = function() {
  return html`
      <div
        id="plus"
        style=${styleMap({
    left: this.vertical ? "" : this._position + "px",
    top: this.vertical ? this._position + "px" : ""
  })}>
        <svg
          id="plus-icon"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512">
          <path
            d="M420.592 214.291H296.104V89.804h-83.102v124.487H88.518v83.104h124.484v124.488h83.102V297.395h124.488z" />
        </svg>
      </div>
    `;
};
renderLink_fn = function() {
  return html`<a
      id="button-wrapper"
      @mousemove=${this._onMouseMove}
      href=${ifDefined(this.href)}
      target=${ifDefined(this.target || void 0)}
      rel=${ifDefined(
    this.rel || ifDefined(
      this.target === "_blank" ? "noopener noreferrer" : void 0
    )
  )}
      aria-label=${this.label ? this.label : "create new element"}>
      ${__privateMethod(this, _UUIButtonInlineCreateElement_instances, renderContent_fn).call(this)}
    </a>`;
};
renderButton_fn = function() {
  return html`
      <button
        id="button-wrapper"
        @mousemove=${this._onMouseMove}
        @click=${this._handleClick}
        aria-label=${this.label ? this.label : "create new element"}>
        ${__privateMethod(this, _UUIButtonInlineCreateElement_instances, renderContent_fn).call(this)}
      </button>
    `;
};
UUIButtonInlineCreateElement.styles = [
  UUIBlinkKeyframes,
  css`
      :host {
        display: flex;
        position: relative;
        z-index: 1;
      }

      :host(:not([vertical])) {
        height: 20px;
        width: 100%;
        margin: -10px 0;
      }

      :host([vertical]) {
        height: 100%;
        width: 20px;
        margin: 0 -10px;
      }

      #button-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        position: absolute;
        z-index: 1;
        outline: 0;
        transition: opacity 0.24s;
        display: inline-flex;
        width: 100%;
        border: none;
        height: 100%;
        padding: 0;

        text-decoration: none;
        background: transparent;
        color: currentColor;

        cursor: pointer;
        -webkit-appearance: none;
        -moz-appearance: none;

        opacity: 0;
      }

      :host(:focus) #button-wrapper,
      :host(:focus-within) #button-wrapper,
      :host(:hover) #button-wrapper {
        opacity: 1;
      }

      :host(:focus) #button-wrapper:before,
      :host(:focus-within) #button-wrapper:before,
      :host(:hover) #button-wrapper:before {
        animation: ${UUIBlinkAnimationValue};
        background-color: var(--uui-color-interactive-emphasis,#3544b1);
        border-color: var(--uui-color-surface,#fff) !important;
      }

      #button-wrapper:before {
        content: '';
        position: absolute;
        right: 0;
        left: 0;
        height: 2px;
        background-color: transparent;
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;
        border-radius: 2px;
        pointer-events: none;
        transition:
          background-color 720ms ease-out,
          border-color 240ms;
      }

      :host(:not([vertical])) #button-wrapper:before {
        top: 50%;
        transform: translateY(-50%);
      }

      :host([vertical]) #button-wrapper:before {
        height: 100%;
        width: 2px;
        left: 50%;
        transform: translateX(-50%);
        border-top: none;
        border-bottom: none;
        border-left: 1px solid transparent;
        border-right: 1px solid transparent;
      }

      :host(:not([vertical]):not(:hover)) #plus:not(:focus) {
        left: calc(50% - 2px) !important;
      }

      :host([vertical]:not(:hover)) #plus:not(:focus) {
        top: calc(50% - 2px) !important;
      }

      #plus {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: none;
        box-sizing: border-box;
        width: 28px;
        height: 28px;
        border-radius: 3em;
        font-size: 14px;
        border: 2px solid var(--uui-color-interactive-emphasis,#3544b1);
        color: var(--uui-color-interactive-emphasis,#3544b1);
        background-color: var(--uui-color-surface,#fff);

        opacity: 0;
        transform: scale(0);
        transition:
          transform 240ms ease-in,
          opacity 240ms,
          left 100ms linear 150ms,
          top 100ms linear 150ms;
      }
      :host(:focus) #plus,
      :host(:focus-within) #plus,
      :host(:hover) #plus {
        opacity: 1;
        transform: scale(1);
        transition:
          transform 240ms cubic-bezier(0.175, 0.885, 0.32, 1.275),
          opacity 80ms,
          box-shadow 240ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
        box-shadow: 0 0 0 2px var(--uui-color-surface,#fff);
      }

      :host(:not([vertical])) #plus {
        margin-left: -18px;
      }

      :host([vertical]) #plus {
        left: -4px;
        margin-top: -18px;
      }

      #button-wrapper:focus #plus {
        /* TODO: implement focus outline system */
        border: 2px solid var(--uui-color-focus,#3879ff);
        color: var(--uui-color-focus,#3879ff);
      }

      #plus-icon {
        width: 50%;
        fill: currentColor;
      }

      #button-wrapper:active #plus {
        transform: scale(1.1);
      }
    `
];
__decorateClass([
  state()
], UUIButtonInlineCreateElement.prototype, "_position", 2);
__decorateClass([
  property({ type: String })
], UUIButtonInlineCreateElement.prototype, "label", 2);
__decorateClass([
  property({ type: Boolean, reflect: true })
], UUIButtonInlineCreateElement.prototype, "vertical", 2);
__decorateClass([
  property({ type: String })
], UUIButtonInlineCreateElement.prototype, "href", 2);
__decorateClass([
  property({ type: String })
], UUIButtonInlineCreateElement.prototype, "target", 2);
__decorateClass([
  property({ type: String })
], UUIButtonInlineCreateElement.prototype, "rel", 2);
UUIButtonInlineCreateElement = __decorateClass([
  defineElement("uui-button-inline-create")
], UUIButtonInlineCreateElement);

export { UUIButtonInlineCreateElement, UUIButtonInlineCreateEvent };
