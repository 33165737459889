import { SelectOnlyMixin, SelectableMixin } from '@umbraco-ui/uui-base/lib/mixins';
import { defineElement } from '@umbraco-ui/uui-base/lib/registration';
import { LitElement, html, css } from 'lit';
import { property } from 'lit/decorators.js';
import { UUIEvent } from '@umbraco-ui/uui-base/lib/events';

class UUICardEvent extends UUIEvent {
}
UUICardEvent.OPEN = "open";

var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp(target, key, result);
  return result;
};
let UUICardElement = class extends SelectOnlyMixin(
  SelectableMixin(LitElement)
) {
  constructor() {
    super(...arguments);
    this.disabled = false;
    this.error = false;
  }
  // This is deprecated - use href instead
  handleOpenClick(e) {
    if (this.disabled) return;
    e.stopPropagation();
    this.dispatchEvent(new UUICardEvent(UUICardEvent.OPEN));
  }
  // This is deprecated - use href instead
  handleOpenKeydown(e) {
    if (this.disabled) return;
    if (e.key !== "Enter") return;
    e.preventDefault();
    e.stopPropagation();
    this.dispatchEvent(new UUICardEvent(UUICardEvent.OPEN));
  }
  render() {
    return html`<slot></slot>`;
  }
};
UUICardElement.styles = [
  css`
      :host {
        position: relative;
        display: flex;
        width: 100%;
        justify-content: center;
        box-sizing: border-box;
        box-shadow: var(--uui-shadow-depth-1,0 1px 3px rgba(0,0,0,0.12) , 0 1px 2px rgba(0,0,0,0.24));
        border-radius: var(--uui-border-radius,3px);
        min-height: var(--uui-layout-medium);
        background-color: var(--uui-color-surface,#fff);
        --uui-card-border-width: 3px;
        transition: box-shadow 100ms ease-out;
      }

      :host(*) {
        /* TODO: implement globally shared outline style */
        outline-color: var(--uui-color-focus,#3879ff);
        outline-offset: 4px;
      }

      :host(*) * {
        /* TODO: implement globally shared outline style */
        outline-color: var(--uui-color-focus,#3879ff);
      }

      :host(:focus) {
        outline-color: var(--uui-color-focus,#3879ff);
        outline-width: var(--uui-card-border-width);
        outline-style: solid;
        outline-offset: var(--uui-card-border-width);
      }

      :host([error])::before {
        content: '';
        position: absolute;
        pointer-events: none;
        inset: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        box-sizing: border-box;
        border: var(--uui-card-border-width) solid var(--uui-color-danger,#d42054);
        border-radius: var(--uui-border-radius,3px);
      }

      :host([selectable]) {
        cursor: pointer;
      }

      :host([disabled]) {
        background: var(--uui-color-disabled,#f3f3f5);
        color: var(--uui-color-disabled-contrast,#c4c4c4);
      }

      :host([selectable])::after {
        content: '';
        position: absolute;
        pointer-events: none;
        inset: calc(var(--uui-card-border-width) * -1);
        width: calc(100% + calc(var(--uui-card-border-width) * 2));
        height: calc(100% + calc(var(--uui-card-border-width) * 2));
        box-sizing: border-box;
        border: var(--uui-card-border-width) solid var(--uui-color-selected,#3544b1);
        border-radius: calc(
          var(--uui-border-radius,3px) + var(--uui-card-border-width)
        );
        transition: opacity 100ms ease-out;
        opacity: 0;
      }
      :host([selectable]:hover)::after {
        opacity: 0.33;
      }
      :host([selectable][selected]:hover)::after {
        opacity: 0.66;
      }
      :host([selectable][selected])::after {
        opacity: 1;
      }

      :host([select-only]) *,
      :host([select-only]) ::slotted(*) {
        pointer-events: none;
      }

      a {
        text-decoration: none;
        color: inherit;
      }
    `
];
__decorateClass([
  property({ type: Boolean, reflect: true, attribute: "disabled" })
], UUICardElement.prototype, "disabled", 2);
__decorateClass([
  property({ type: Boolean, reflect: true })
], UUICardElement.prototype, "error", 2);
__decorateClass([
  property({ type: String })
], UUICardElement.prototype, "href", 2);
__decorateClass([
  property({ type: String })
], UUICardElement.prototype, "target", 2);
__decorateClass([
  property({ type: String })
], UUICardElement.prototype, "rel", 2);
UUICardElement = __decorateClass([
  defineElement("uui-card")
], UUICardElement);

export { UUICardElement, UUICardEvent };
