function defineElement(name, options) {
  return (constructor) => {
    const isValidElementName = name.indexOf("-") > 0;
    if (isValidElementName === false) {
      console.error(
        `${name} is not a valid custom element name. A custom element name should consist of at least two words separated by a hyphen.`
      );
      return;
    }
    const existingElement = window.customElements.get(name);
    if (!existingElement) {
      window.customElements.define(name, constructor, options);
    }
  };
}

export { defineElement };
