import { defineElement } from '@umbraco-ui/uui-base/lib/registration';
import { css, LitElement, html } from 'lit';
import { property, queryAssignedElements } from 'lit/decorators.js';
import { SelectOnlyMixin, SelectableMixin } from '@umbraco-ui/uui-base/lib/mixins';

var __defProp$5 = Object.defineProperty;
var __getOwnPropDesc$5 = Object.getOwnPropertyDescriptor;
var __decorateClass$5 = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc$5(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp$5(target, key, result);
  return result;
};
let UUITableElement = class extends LitElement {
  /* consider select-only attribute on this level? */
  connectedCallback() {
    super.connectedCallback();
    this.setAttribute("role", "table");
  }
  render() {
    return html`<slot></slot>`;
  }
};
UUITableElement.styles = [
  css`
      :host {
        display: table;
        width: 100%;
        border-radius: var(--uui-border-radius,3px);
        background-color: var(--uui-color-surface,#fff);
        cursor: default;
      }
    `
];
UUITableElement = __decorateClass$5([
  defineElement("uui-table")
], UUITableElement);

var __defProp$4 = Object.defineProperty;
var __getOwnPropDesc$4 = Object.getOwnPropertyDescriptor;
var __decorateClass$4 = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc$4(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp$4(target, key, result);
  return result;
};
let UUITableCellElement = class extends LitElement {
  constructor() {
    super(...arguments);
    this.disableChildInteraction = false;
    this.noPadding = false;
    this.clipText = false;
    this._observer = new ResizeObserver(() => {
      this._detectOverflow();
    });
  }
  _detectOverflow() {
    if (this.scrollWidth > this.clientWidth) {
      this.setAttribute("title", this.innerText);
    } else {
      this.removeAttribute("title");
    }
  }
  connectedCallback() {
    super.connectedCallback();
    this.setAttribute("role", "cell");
  }
  disconnectedCallback() {
    super.disconnectedCallback();
    this._observer.disconnect();
  }
  updated(changedProperties) {
    super.updated(changedProperties);
    if (changedProperties.has("clipText")) {
      if (this.clipText) {
        this._detectOverflow();
        this._observer.observe(this);
      } else {
        this._observer.disconnect();
      }
    }
  }
  render() {
    return html` <slot @slotchange=${this._detectOverflow}></slot>`;
  }
};
UUITableCellElement.styles = [
  css`
      :host {
        position: relative;
        display: table-cell;
        height: var(--uui-table-cell-height, var(--uui-size-12,36px));
        padding: var(
          --uui-table-cell-padding,
          var(--uui-size-4,12px) var(--uui-size-5,15px)
        );
        border-top: 1px solid var(--uui-color-border,#d8d7d9);
        vertical-align: middle;
      }

      :host([clip-text]) {
        max-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        box-sizing: border-box;
      }

      :host([disable-child-interaction]) ::slotted(*) {
        pointer-events: none;
      }

      :host([disable-child-interaction])::after {
        content: '';
        position: absolute;
        inset: 0;
      }

      :host([no-padding]) {
        padding: 0;
      }
    `
];
__decorateClass$4([
  property({
    type: Boolean,
    reflect: true,
    attribute: "disable-child-interaction"
  })
], UUITableCellElement.prototype, "disableChildInteraction", 2);
__decorateClass$4([
  property({ type: Boolean, reflect: true, attribute: "no-padding" })
], UUITableCellElement.prototype, "noPadding", 2);
__decorateClass$4([
  property({ type: Boolean, reflect: true, attribute: "clip-text" })
], UUITableCellElement.prototype, "clipText", 2);
UUITableCellElement = __decorateClass$4([
  defineElement("uui-table-cell")
], UUITableCellElement);

var __defProp$3 = Object.defineProperty;
var __getOwnPropDesc$3 = Object.getOwnPropertyDescriptor;
var __decorateClass$3 = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc$3(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp$3(target, key, result);
  return result;
};
let UUITableColumnElement = class extends LitElement {
};
UUITableColumnElement.styles = [
  css`
      :host {
        display: table-column;
      }
    `
];
UUITableColumnElement = __decorateClass$3([
  defineElement("uui-table-column")
], UUITableColumnElement);

var __defProp$2 = Object.defineProperty;
var __getOwnPropDesc$2 = Object.getOwnPropertyDescriptor;
var __decorateClass$2 = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc$2(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp$2(target, key, result);
  return result;
};
let UUITableHeadCellElement = class extends UUITableCellElement {
  connectedCallback() {
    super.connectedCallback();
    this.setAttribute("role", "columnheader");
  }
};
UUITableHeadCellElement.styles = [
  ...UUITableCellElement.styles,
  css`
      :host {
        border-top: none;
      }
    `
];
UUITableHeadCellElement = __decorateClass$2([
  defineElement("uui-table-head-cell")
], UUITableHeadCellElement);

var __defProp$1 = Object.defineProperty;
var __getOwnPropDesc$1 = Object.getOwnPropertyDescriptor;
var __decorateClass$1 = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc$1(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp$1(target, key, result);
  return result;
};
let UUITableHeadElement = class extends LitElement {
  connectedCallback() {
    super.connectedCallback();
    this.setAttribute("role", "row");
  }
  render() {
    return html`<slot></slot>`;
  }
};
UUITableHeadElement.styles = [
  css`
      :host {
        display: table-header-group;
        font-weight: bold;
      }
    `
];
UUITableHeadElement = __decorateClass$1([
  defineElement("uui-table-head")
], UUITableHeadElement);

var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp(target, key, result);
  return result;
};
let UUITableRowElement = class extends SelectOnlyMixin(
  SelectableMixin(LitElement)
) {
  constructor() {
    super();
    let hadMouseDown = false;
    this.addEventListener("blur", () => {
      if (hadMouseDown === false) {
        this.style.setProperty("--uui-show-focus-outline", "1");
      }
      hadMouseDown = false;
    });
    this.addEventListener("mousedown", () => {
      this.style.setProperty("--uui-show-focus-outline", "0");
      hadMouseDown = true;
    });
    this.addEventListener("mouseup", () => {
      hadMouseDown = false;
    });
  }
  connectedCallback() {
    super.connectedCallback();
    this.setAttribute("role", "row");
  }
  updated(changedProperties) {
    if (changedProperties.has("selectOnly")) this.updateChildSelectOnly();
  }
  updateChildSelectOnly() {
    if (this.slotCellNodes) {
      this.slotCellNodes.forEach((el) => {
        if (this.elementIsTableCell(el)) {
          el.disableChildInteraction = this.selectOnly;
        }
      });
    }
  }
  elementIsTableCell(element) {
    return element instanceof UUITableCellElement;
  }
  render() {
    return html` <slot @slotchanged=${this.updateChildSelectOnly}></slot> `;
  }
};
UUITableRowElement.styles = [
  css`
      :host {
        display: table-row;
        position: relative;
        outline-offset: -3px;
      }

      :host([selectable]) {
        cursor: pointer;
      }

      :host(:focus) {
        outline: calc(2px * var(--uui-show-focus-outline, 1)) solid
          var(--uui-color-focus,#3879ff);
      }
      :host([selected]) {
        outline: 2px solid
          var(--uui-table-row-color-selected, var(--uui-color-selected,#3544b1));
      }
      :host([selected]:focus) {
        outline-color: var(--uui-color-focus,#3879ff);
      }
    `
];
__decorateClass([
  queryAssignedElements({
    flatten: true,
    selector: "uui-table-cell, [uui-table-cell], [role=cell]"
  })
], UUITableRowElement.prototype, "slotCellNodes", 2);
UUITableRowElement = __decorateClass([
  defineElement("uui-table-row")
], UUITableRowElement);

export { UUITableCellElement, UUITableColumnElement, UUITableElement, UUITableHeadCellElement, UUITableHeadElement, UUITableRowElement };
