import { defineElement } from '@umbraco-ui/uui-base/lib/registration';
import { demandCustomElement } from '@umbraco-ui/uui-base/lib/utils';
import { iconSee, iconUnsee } from '@umbraco-ui/uui-icon-registry-essential/lib/svgs';
import { UUIInputElement } from '@umbraco-ui/uui-input/lib';
import { css, html } from 'lit';
import { state, property } from 'lit/decorators.js';

var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp(target, key, result);
  return result;
};
let UUIInputPasswordElement = class extends UUIInputElement {
  constructor() {
    super(...arguments);
    this.passwordType = "password";
  }
  get type() {
    return this.passwordType;
  }
  set type(newValue) {
    this.passwordType = newValue;
  }
  _onPasswordToggle() {
    if (this.passwordType === "password") {
      this.passwordType = "text";
    } else {
      this.passwordType = "password";
    }
  }
  connectedCallback() {
    super.connectedCallback();
    demandCustomElement(this, "uui-icon");
    demandCustomElement(this, "uui-button");
    if (!this.hasAttribute("spellcheck")) {
      this.spellcheck = false;
    }
  }
  renderIcon() {
    return this.passwordType === "password" ? html`<uui-icon name="see" .fallback=${iconSee.strings[0]}></uui-icon>` : html`<uui-icon
          name="unsee"
          .fallback=${iconUnsee.strings[0]}></uui-icon>`;
  }
  renderAppend() {
    return html`<uui-button
      .disabled=${this.disabled}
      @click=${this._onPasswordToggle}
      compact
      label="${this.passwordType === "password" ? "Show password" : "Hide password"}"
      id="eye">
      ${this.renderIcon()}
    </uui-button>`;
  }
};
UUIInputPasswordElement.styles = [
  ...UUIInputElement.styles,
  css`
      #eye {
        height: 100%;
        margin-left: -6px;
      }

      #clear:hover {
        color: black;
      }
    `
];
__decorateClass([
  state()
], UUIInputPasswordElement.prototype, "passwordType", 2);
__decorateClass([
  property()
], UUIInputPasswordElement.prototype, "type", 1);
UUIInputPasswordElement = __decorateClass([
  defineElement("uui-input-password")
], UUIInputPasswordElement);

export { UUIInputPasswordElement };
