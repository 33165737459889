import { defineElement } from '@umbraco-ui/uui-base/lib/registration';
import { LitElement, html } from 'lit';

var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp(target, key, result);
  return result;
};
let UUIFormElement = class extends LitElement {
  constructor() {
    super(...arguments);
    this._formElement = null;
  }
  getFormElement() {
    return this._formElement;
  }
  _onSlotChanged(event) {
    if (this._formElement) {
      this._formElement.removeEventListener("submit", this._onSubmit);
      this._formElement.removeEventListener("reset", this._onReset);
    }
    const formElements = event.target.assignedNodes({ flatten: true }).filter((x) => x instanceof HTMLFormElement);
    this._formElement = formElements.length > 0 ? formElements[0] : null;
    if (this._formElement) {
      this._formElement.setAttribute("novalidate", "");
      this._formElement.addEventListener("submit", this._onSubmit);
      this._formElement.addEventListener("reset", this._onReset);
    }
  }
  _onSubmit(event) {
    if (event.target === null) {
      return;
    }
    const formNode = event.target;
    const isValid = formNode.checkValidity();
    if (!isValid) {
      formNode.setAttribute("submit-invalid", "");
      return;
    }
    formNode.removeAttribute("submit-invalid");
  }
  _onReset(event) {
    if (event.target === null) {
      return;
    }
    event.target.removeAttribute("submit-invalid");
  }
  render() {
    return html`<slot @slotchange=${this._onSlotChanged}></slot>`;
  }
};
UUIFormElement = __decorateClass([
  defineElement("uui-form")
], UUIFormElement);

export { UUIFormElement };
