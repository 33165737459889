import { defineElement } from '@umbraco-ui/uui-base/lib/registration';
import { css, LitElement, html } from 'lit';
import { property } from 'lit/decorators.js';
import { styleMap } from 'lit/directives/style-map.js';

var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp(target, key, result);
  return result;
};
let UUILoaderCircleElement = class extends LitElement {
  constructor() {
    super(...arguments);
    this.progress = 0;
    this.showProgress = false;
    this._resizeObserver = new ResizeObserver(() => this.onResize());
    this._isLarge = false;
  }
  _circleStyle() {
    if (this.progress) {
      return { strokeDasharray: `${this.progress} 100` };
    } else {
      return { strokeDasharray: "100 100" };
    }
  }
  firstUpdated() {
    this._resizeObserver.observe(this);
  }
  disconnectedCallback() {
    this._resizeObserver.disconnect();
  }
  onResize() {
    const newIsLarge = this.clientHeight >= 30;
    if (this._isLarge != newIsLarge) {
      this._isLarge = newIsLarge;
      this.requestUpdate();
    }
  }
  renderProgress() {
    return this._isLarge && this.progress && this.showProgress ? html`<span id="progress-display">${this.progress}</span>` : "";
  }
  render() {
    return html`
      <svg
        id="spinner"
        class=${this.progress ? "" : "animate"}
        viewBox="0 0 40 40"
        xmlns="http://www.w3.org/2000/svg">
        <circle id="bg" cx="50%" cy="50%" r="16" />
        <g>
          <circle
            id="circle"
            cx="50%"
            cy="50%"
            r="16"
            style=${styleMap(this._circleStyle())} />
        </g>
      </svg>
      ${this.renderProgress()}
    `;
  }
};
UUILoaderCircleElement.styles = [
  css`
      :host {
        vertical-align: middle;
        line-height: 0;
        overflow: hidden;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 1em;
        height: 1em;
        color: var(--uui-color-default,#1b264f);
      }

      #spinner {
        width: 100%;
        height: 100%;
      }
      #spinner g {
        transform-origin: 50% 50%;
        animation: 18s linear infinite spinner-animation;
      }
      #spinner.animate g {
        animation: 800ms linear infinite spinner-animation;
      }

      @keyframes spinner-animation {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }

      #spinner.animate #circle {
        animation: 1400ms ease-in infinite circle-animation;
        /** ease-in */
      }

      @keyframes circle-animation {
        0% {
          stroke-dashoffset: -55;
        }
        38% {
          stroke-dashoffset: -88;
        }
        100% {
          stroke-dashoffset: -55;
        }
      }

      svg circle {
        fill: transparent;
        stroke: currentColor;
        stroke-width: 6px;
      }

      #bg {
        opacity: 0.5;
      }

      #circle {
        stroke-linecap: round;
        stroke-dasharray: 0 0;

        transform-origin: 50% 50%;
        transform: rotate(-90deg);
        transition: stroke-dasharray 120ms ease;
      }

      #progress-display {
        position: absolute;
        left: 0;
        top: 50%;
        right: 0;
        stroke: currentColor;
        transform: translateY(-50%);
        font-size: 0.3em;
        font-weight: 700;
        text-align: center;

        /* Center the text */
        padding-top: 0.09em;
      }
    `
];
__decorateClass([
  property({ type: Number })
], UUILoaderCircleElement.prototype, "progress", 2);
__decorateClass([
  property({ type: Boolean, reflect: true, attribute: "show-progress" })
], UUILoaderCircleElement.prototype, "showProgress", 2);
UUILoaderCircleElement = __decorateClass([
  defineElement("uui-loader-circle")
], UUILoaderCircleElement);

export { UUILoaderCircleElement };
