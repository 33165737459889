import { UUIFormControlEvent } from '@umbraco-ui/uui-base/lib/events';
import { defineElement } from '@umbraco-ui/uui-base/lib/registration';
import { css, LitElement, html } from 'lit';
import { property } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';

var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp(target, key, result);
  return result;
};
let UUIFormValidationMessageElement = class extends LitElement {
  constructor() {
    super();
    this._for = null;
    this._messages = /* @__PURE__ */ new Map();
    this._onControlInvalid = (e) => {
      const ctrl = e.composedPath()[0];
      if (ctrl.pristine === false) {
        this._messages.set(ctrl, ctrl.validationMessage);
      } else {
        this._messages.delete(ctrl);
      }
      this.requestUpdate("_messages");
    };
    this._onControlValid = (e) => {
      const ctrl = e.composedPath()[0];
      this._messages.delete(ctrl);
      this.requestUpdate("_messages");
    };
    if (this.for === null) {
      this.for = this;
    }
  }
  get for() {
    return this._for;
  }
  set for(value) {
    let element = null;
    if (typeof value === "string") {
      const scope = this.getRootNode();
      element = scope?.getElementById(value);
    } else if (value instanceof HTMLElement) {
      element = value;
    }
    const newScope = element ?? this;
    const oldScope = this._for;
    if (oldScope === newScope) {
      return;
    }
    if (oldScope !== null) {
      oldScope.removeEventListener(
        UUIFormControlEvent.INVALID,
        this._onControlInvalid
      );
      oldScope.removeEventListener(
        UUIFormControlEvent.VALID,
        this._onControlValid
      );
    }
    this._for = newScope;
    this._for.addEventListener(
      UUIFormControlEvent.INVALID,
      this._onControlInvalid
    );
    this._for.addEventListener(
      UUIFormControlEvent.VALID,
      this._onControlValid
    );
  }
  render() {
    return html`
      <slot></slot>
      <div id="messages">
        ${repeat(
      this._messages,
      (item) => html`<div>${unsafeHTML(item[1])}</div>`
    )}
        <slot name="message"></slot>
      </div>
    `;
  }
};
UUIFormValidationMessageElement.styles = [
  css`
      #messages {
        color: var(--uui-color-danger-standalone,rgb(191, 33, 78));
      }
    `
];
__decorateClass([
  property({ reflect: false, attribute: true })
], UUIFormValidationMessageElement.prototype, "for", 1);
UUIFormValidationMessageElement = __decorateClass([
  defineElement("uui-form-validation-message")
], UUIFormValidationMessageElement);

export { UUIFormValidationMessageElement };
