import { css, LitElement, html } from 'lit';
import { defineElement } from '@umbraco-ui/uui-base/lib/registration';
import { state, property } from 'lit/decorators.js';
import { UUIEvent } from '@umbraco-ui/uui-base/lib/events';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';

class UUIIconRequestEvent extends UUIEvent {
  constructor(evName, eventInit = {}) {
    super(evName, {
      ...{ bubbles: true, composed: true },
      ...eventInit
    });
    this.icon = null;
  }
  acceptRequest(icon) {
    this.icon = icon;
    this.stopImmediatePropagation();
  }
}
UUIIconRequestEvent.ICON_REQUEST = "icon-request";

var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp(target, key, result);
  return result;
};
let UUIIconElement = class extends LitElement {
  constructor() {
    super(...arguments);
    this._name = null;
    this._retrievedNameIcon = false;
    this._nameSvg = null;
    this.label = "";
    this.svg = null;
    this.fallback = null;
    this._useFallback = false;
  }
  get name() {
    return this._name;
  }
  set name(newValue) {
    this._name = newValue;
    if (this.shadowRoot) {
      this.requestIcon();
    }
  }
  requestIcon() {
    if (this._name !== "" && this._name !== null) {
      const event = new UUIIconRequestEvent(UUIIconRequestEvent.ICON_REQUEST, {
        detail: { iconName: this._name }
      });
      this.dispatchEvent(event);
      if (event.icon !== null) {
        this._retrievedNameIcon = true;
        event.icon.then((iconSvg) => {
          this._useFallback = false;
          this._nameSvg = iconSvg;
        });
      } else {
        this._retrievedNameIcon = false;
        this._useFallback = true;
      }
    }
  }
  connectedCallback() {
    super.connectedCallback();
    if (this._retrievedNameIcon === false) {
      this.requestIcon();
    }
  }
  disconnectedCallback() {
    this._retrievedNameIcon = false;
  }
  firstUpdated() {
    if (this._retrievedNameIcon === false) {
      this.requestIcon();
    }
    const hasLabel = typeof this.label === "string" && this.label.length > 0;
    if (hasLabel) {
      this.setAttribute("role", "img");
      this.setAttribute("aria-label", this.label);
      this.removeAttribute("aria-hidden");
    } else {
      this.removeAttribute("role");
      this.removeAttribute("aria-label");
      this.setAttribute("aria-hidden", "true");
    }
  }
  render() {
    if (this._useFallback === true) {
      if (this.fallback === null) {
        return html`<slot name="fallback"></slot>`;
      } else {
        return unsafeHTML(this.fallback);
      }
    }
    if (this._nameSvg !== null) {
      return unsafeHTML(this._nameSvg);
    }
    if (this.svg !== null) {
      return unsafeHTML(this.svg);
    }
    return html`<slot></slot>`;
  }
};
UUIIconElement.styles = [
  css`
      :host {
        display: inline-block;
        vertical-align: bottom;
        width: 1.15em;
        height: 1.15em;
      }

      :host svg,
      ::slotted(svg) {
        color: var(--uui-icon-color, currentColor);
      }

      :host-context(div[slot='prepend']) {
        margin-left: var(--uui-size-2, 6px);
      }

      :host-context(div[slot='append']) {
        margin-right: var(--uui-size-2, 6px);
      }
    `
];
__decorateClass([
  state()
], UUIIconElement.prototype, "_nameSvg", 2);
__decorateClass([
  property()
], UUIIconElement.prototype, "label", 2);
__decorateClass([
  property()
], UUIIconElement.prototype, "name", 1);
__decorateClass([
  property({ attribute: false })
], UUIIconElement.prototype, "svg", 2);
__decorateClass([
  property({ attribute: false })
], UUIIconElement.prototype, "fallback", 2);
__decorateClass([
  state()
], UUIIconElement.prototype, "_useFallback", 2);
UUIIconElement = __decorateClass([
  defineElement("uui-icon")
], UUIIconElement);

export { UUIIconElement, UUIIconRequestEvent };
