import { defineElement } from '@umbraco-ui/uui-base/lib/registration';
import { demandCustomElement } from '@umbraco-ui/uui-base/lib/utils';
import { UUICardElement } from '@umbraco-ui/uui-card/lib';
import { css, html, nothing } from 'lit';
import { property, state } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';

var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __typeError = (msg) => {
  throw TypeError(msg);
};
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp(target, key, result);
  return result;
};
var __accessCheck = (obj, member, msg) => member.has(obj) || __typeError("Cannot " + msg);
var __privateAdd = (obj, member, value) => member.has(obj) ? __typeError("Cannot add the same private member more than once") : member instanceof WeakSet ? member.add(obj) : member.set(obj, value);
var __privateMethod = (obj, member, method) => (__accessCheck(obj, member, "access private method"), method);
var _UUICardMediaElement_instances, renderButton_fn, renderLink_fn;
let UUICardMediaElement = class extends UUICardElement {
  constructor() {
    super(...arguments);
    __privateAdd(this, _UUICardMediaElement_instances);
    this.name = "";
    this.fileExt = "";
    this.hasPreview = false;
  }
  connectedCallback() {
    super.connectedCallback();
    demandCustomElement(this, "uui-symbol-folder");
    demandCustomElement(this, "uui-symbol-file");
  }
  queryPreviews(e) {
    this.hasPreview = e.composedPath()[0].assignedElements({
      flatten: true
    }).length > 0;
  }
  renderMedia() {
    if (this.hasPreview === true) return "";
    if (this.fileExt === "") {
      return html`<uui-symbol-folder id="folder-symbol"></uui-symbol-folder>`;
    }
    return html`<uui-symbol-file
      id="file-symbol"
      type="${this.fileExt}"></uui-symbol-file>`;
  }
  render() {
    return html` ${this.renderMedia()}
      <slot @slotchange=${this.queryPreviews}></slot>
      ${this.href ? __privateMethod(this, _UUICardMediaElement_instances, renderLink_fn).call(this) : __privateMethod(this, _UUICardMediaElement_instances, renderButton_fn).call(this)}
      <!-- Select border must be right after .open-part -->
      <div id="select-border"></div>

      <slot name="tag"></slot>
      <slot name="actions"></slot>`;
  }
};
_UUICardMediaElement_instances = new WeakSet();
renderButton_fn = function() {
  return html`
      <button
        id="open-part"
        tabindex=${this.disabled ? nothing : "0"}
        @click=${this.handleOpenClick}
        @keydown=${this.handleOpenKeydown}>
        <!--
        TODO: Implement when pop-out is ready
        <uui-icon
          id="info-icon"
          name="info"
          style="color: currentColor">
        </uui-icon>
        -->
        <span>${this.name}</span>
      </button>
    `;
};
renderLink_fn = function() {
  return html`
      <a
        id="open-part"
        tabindex=${this.disabled ? nothing : "0"}
        href=${ifDefined(!this.disabled ? this.href : void 0)}
        target=${ifDefined(this.target || void 0)}
        rel=${ifDefined(
    this.rel || ifDefined(
      this.target === "_blank" ? "noopener noreferrer" : void 0
    )
  )}>
        <!--
        TODO: Implement when pop-out is ready
        <uui-icon
          id="info-icon"
          name="info"
          style="color: currentColor">
        </uui-icon>
        -->
        <span>${this.name}</span>
      </a>
    `;
};
UUICardMediaElement.styles = [
  ...UUICardElement.styles,
  css`
      #file-symbol,
      #folder-symbol {
        align-self: center;
        margin: var(--uui-size-14,42px);
        width: 80%;
      }

      slot[name='tag'] {
        position: absolute;
        top: var(--uui-size-4,12px);
        right: var(--uui-size-4,12px);
        display: flex;
        justify-content: right;
      }

      slot[name='actions'] {
        position: absolute;
        top: var(--uui-size-4,12px);
        right: var(--uui-size-4,12px);
        display: flex;
        justify-content: right;

        opacity: 0;
        transition: opacity 120ms;
      }
      :host(:focus) slot[name='actions'],
      :host(:focus-within) slot[name='actions'],
      :host(:hover) slot[name='actions'] {
        opacity: 1;
      }

      slot:not([name])::slotted(*) {
        align-self: center;
        border-radius: var(--uui-border-radius,3px);
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      #open-part {
        position: absolute;
        bottom: 0;
        width: 100%;
        background-color: var(--uui-color-surface,#fff);
        color: var(--uui-color-interactive,#1b264f);
        border: none;
        cursor: pointer;
        border-top: 1px solid var(--uui-color-divider,#f6f6f7);
        border-radius: 0 0 var(--uui-border-radius,3px) var(--uui-border-radius,3px);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-family: inherit;
        font-size: var(--uui-type-small-size,12px);
        box-sizing: border-box;
        padding: var(--uui-size-2,6px) var(--uui-size-4,12px);
        text-align: left;
        word-break: break-word;
      }

      :host([disabled]) #open-part {
        pointer-events: none;
        background: var(--uui-color-disabled,#f3f3f5);
        color: var(--uui-color-contrast-disabled);
      }

      #open-part:hover {
        text-decoration: underline;
        color: var(--uui-color-interactive-emphasis,#3544b1);
      }

      :host([image]:not([image=''])) #open-part {
        transition: opacity 0.5s 0.5s;
        opacity: 0;
      }

      :host(
          [image]:not([image='']):hover,
          [image]:not([image='']):focus,
          [image]:not([image='']):focus-within,
          [selected][image]:not([image='']),
          [error][image]:not([image=''])
        )
        #open-part {
        opacity: 1;
        transition-duration: 120ms;
        transition-delay: 0s;
      }

      /*
      #info-icon {
        margin-right: var(--uui-size-2);
        display: flex;
        height: var(--uui-size-8);
      }
      */
    `
];
__decorateClass([
  property({ type: String })
], UUICardMediaElement.prototype, "name", 2);
__decorateClass([
  property({ type: String, attribute: "file-ext" })
], UUICardMediaElement.prototype, "fileExt", 2);
__decorateClass([
  state()
], UUICardMediaElement.prototype, "hasPreview", 2);
UUICardMediaElement = __decorateClass([
  defineElement("uui-card-media")
], UUICardMediaElement);

export { UUICardMediaElement };
