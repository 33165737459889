import { defineElement } from '@umbraco-ui/uui-base/lib/registration';
import { UUIToastNotificationEvent } from '@umbraco-ui/uui-toast-notification/lib';
import { css, LitElement, html } from 'lit';
import { property } from 'lit/decorators.js';

var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp(target, key, result);
  return result;
};
let UUIToastNotificationContainerElement = class extends LitElement {
  constructor() {
    super(...arguments);
    this._autoClose = null;
    this._autoClosePause = false;
    /**
     * pause all auto close timer, including later coming.
     */
    this.pauseAutoClose = () => {
      this._autoClosePause = true;
      this._toasts?.forEach((el) => el.pauseAutoClose());
    };
    /**
     * resume the auto close timers.
     */
    this.resumeAutoClose = () => {
      if (this.matches(":focus-within:not(:focus)") === false) {
        this._autoClosePause = false;
        this._toasts?.forEach((el) => el.resumeAutoClose());
      }
    };
    this.onToastClosed = (e) => {
      this.removeToast(e.target);
    };
    this._toasts = [];
    this.onSlotChanged = (event) => {
      const existingModals = [...this._toasts];
      this._toasts = event.target.assignedElements({ flatten: true }).filter(
        (e) => e.nodeName === "UUI-TOAST-NOTIFICATION"
      );
      const oldToasts = existingModals.filter(
        (modal) => this._toasts.indexOf(modal) === -1
      );
      oldToasts.forEach((toast) => {
        toast.removeEventListener(
          UUIToastNotificationEvent.CLOSED,
          this.onToastClosed
        );
        toast.removeEventListener("mouseenter", this.pauseAutoClose);
        toast.removeEventListener("mouseleave", this.resumeAutoClose);
        toast.removeEventListener("focus", this.pauseAutoClose);
        toast.removeEventListener("blur", this.resumeAutoClose);
      });
      const newToasts = this._toasts.filter(
        (modal) => existingModals.indexOf(modal) === -1
      );
      newToasts.forEach((toast) => {
        toast.addEventListener(
          UUIToastNotificationEvent.CLOSED,
          this.onToastClosed
        );
        toast.addEventListener("mouseenter", this.pauseAutoClose);
        toast.addEventListener("mouseleave", this.resumeAutoClose);
        toast.addEventListener("focus", this.pauseAutoClose);
        toast.addEventListener("blur", this.resumeAutoClose);
        if (this._autoClose) {
          toast.autoClose = this._autoClose;
        }
        if (this._autoClosePause === true) {
          toast.pauseAutoClose();
        }
        toast.open = true;
      });
    };
  }
  get autoClose() {
    return this._autoClose;
  }
  set autoClose(value) {
    this._autoClose = value;
    this._toasts?.forEach((el) => el.autoClose = value);
  }
  /**
   * Instantly remove a toast element.
   * @param  {UUIToastNotificationElement} toast The toast element to remove
   */
  removeToast(toast) {
    if (!toast) {
      toast = this._toasts[this._toasts.length - 1];
    } else if (this._toasts.indexOf(toast) === -1) {
      console.warn(
        "Toast-notification",
        toast,
        "could not be removed as it is not a child of this toast-notification-container",
        this
      );
      return;
    }
    this.removeChild(toast);
  }
  /**
   * Close a toast element, this will animate out and then be removed.
   * @param  {UUIToastNotificationElement} toast The toast element to close and remove
   */
  closeToast(modal) {
    let _modal = modal;
    if (!_modal) {
      _modal = this._toasts[this._toasts.length - 1];
    }
    _modal.open = false;
  }
  render() {
    return html` <slot @slotchange=${this.onSlotChanged}></slot> `;
  }
};
UUIToastNotificationContainerElement.styles = [
  css`
      :host {
        position: absolute;
        overflow: hidden;
        max-width: 100%;
        height: 100%;

        pointer-events: none;
        box-sizing: border-box;
      }

      slot {
        display: flex;
        flex-direction: column;
        align-items: end;

        height: 100%;
        box-sizing: border-box;

        padding-top: var(--uui-size-space-1,3px);
        padding-bottom: var(--uui-size-space-1,3px);
      }
      :host([bottom-up]) slot {
        justify-content: end;
      }
      :host([left-align]) slot {
        align-items: start;
      }
    `
];
__decorateClass([
  property({ type: Number, reflect: true, attribute: "auto-close" })
], UUIToastNotificationContainerElement.prototype, "_autoClose", 2);
UUIToastNotificationContainerElement = __decorateClass([
  defineElement("uui-toast-notification-container")
], UUIToastNotificationContainerElement);

export { UUIToastNotificationContainerElement };
