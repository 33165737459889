import { UUIFormControlMixin, LabelMixin } from '@umbraco-ui/uui-base/lib/mixins';
import { LitElement, html, css } from 'lit';
import { property, query } from 'lit/decorators.js';
import { UUIEvent } from '@umbraco-ui/uui-base/lib/events';

class UUIBooleanInputEvent extends UUIEvent {
  constructor(evName, eventInit = {}) {
    super(evName, {
      ...{ bubbles: true },
      ...eventInit
    });
  }
}
UUIBooleanInputEvent.CHANGE = "change";

var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp(target, key, result);
  return result;
};
class UUIBooleanInputElement extends UUIFormControlMixin(
  LabelMixin("", LitElement),
  ""
) {
  constructor(inputRole = "checkbox") {
    super();
    this._value = "";
    this.labelPosition = "right";
    this._checked = false;
    this.indeterminate = false;
    this.disabled = false;
    this.readonly = false;
    if (this._value === "") {
      this._value = "on";
    }
    this.inputRole = inputRole;
    this.addEventListener("keypress", this._onKeypress);
  }
  /** intentional overwrite of FormControlMixins value getter and setter method. */
  get value() {
    return this._value;
  }
  set value(newVal) {
    const oldValue = super.value;
    this._value = newVal;
    if ("ElementInternals" in window && //@ts-ignore
    "setFormValue" in window.ElementInternals.prototype) {
      this._internals.setFormValue(
        this._checked && this.name !== "" ? this._value : null
      );
    }
    this.requestUpdate("value", oldValue);
  }
  // Do not 'reflect' as the attribute is used as fallback.
  get checked() {
    return this._checked;
  }
  set checked(newVal) {
    const oldValue = this._checked;
    this._checked = newVal;
    this._internals.setFormValue(
      this._checked && this.name !== "" ? this._value ? this._value : "on" : null
    );
    this.requestUpdate("checked", oldValue);
  }
  getFormElement() {
    return this._input;
  }
  _onKeypress(e) {
    if (e.key == "Enter") {
      this.submit();
    }
  }
  hasValue() {
    return this.checked;
  }
  formResetCallback() {
    super.formResetCallback();
    this.checked = this.hasAttribute("checked");
  }
  firstUpdated(_changedProperties) {
    super.firstUpdated(_changedProperties);
    const labelEl = this.shadowRoot?.querySelector("label");
    let hadMouseDown = false;
    this._input.addEventListener("blur", () => {
      if (hadMouseDown === false) {
        this.style.setProperty("--uui-show-focus-outline", "1");
      }
      hadMouseDown = false;
    });
    labelEl.addEventListener("mousedown", () => {
      this.style.setProperty("--uui-show-focus-outline", "0");
      hadMouseDown = true;
    });
    labelEl.addEventListener("mouseup", () => {
      hadMouseDown = false;
    });
  }
  /**
   * This method enables <label for="..."> to focus the input
   */
  async focus() {
    await this.updateComplete;
    this._input.focus();
  }
  async click() {
    await this.updateComplete;
    this._input.click();
  }
  _onInputChange(e) {
    e.stopPropagation();
    this.pristine = false;
    this.checked = this._input.checked;
    this.indeterminate = this._input.indeterminate;
    this.dispatchEvent(new UUIBooleanInputEvent(UUIBooleanInputEvent.CHANGE));
  }
  render() {
    return html`
      <label>
        <input
          id="input"
          type="checkbox"
          @change="${this._onInputChange}"
          .disabled=${this.disabled || this.readonly}
          .checked=${this.checked}
          .indeterminate=${this.indeterminate}
          aria-checked="${this.checked ? "true" : "false"}"
          aria-label=${this.label}
          role="${this.inputRole}" />
        ${this.renderCheckbox()} ${this.renderLabel()}
      </label>
    `;
  }
}
UUIBooleanInputElement.styles = [
  css`
      :host {
        display: inline-block;
      }

      label {
        position: relative;
        cursor: pointer;
        user-select: none;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-items: center;
        gap: var(--uui-size-3,9px);
      }

      :host([readonly]) label {
        cursor: default;
      }

      input {
        position: absolute;
        height: 0px;
        width: 0px;
        opacity: 0;
      }

      :host([label-position='left']) label {
        flex-direction: row-reverse;
      }

      :host([label-position='top']) label {
        gap: var(--uui-size-half-base-unit);
        flex-direction: column-reverse;
      }

      :host([label-position='bottom']) label {
        gap: var(--uui-size-half-base-unit);
        flex-direction: column;
      }

      :host([disabled]) label {
        cursor: not-allowed;
        opacity: 0.5;
      }

      .label {
        display: block;
      }
    `
];
__decorateClass([
  property({ type: String, attribute: "label-position", reflect: true })
], UUIBooleanInputElement.prototype, "labelPosition", 2);
__decorateClass([
  property({ type: Boolean })
], UUIBooleanInputElement.prototype, "checked", 1);
__decorateClass([
  property({ type: Boolean, reflect: true })
], UUIBooleanInputElement.prototype, "indeterminate", 2);
__decorateClass([
  property({ type: Boolean, reflect: true })
], UUIBooleanInputElement.prototype, "disabled", 2);
__decorateClass([
  property({ type: Boolean, reflect: true })
], UUIBooleanInputElement.prototype, "readonly", 2);
__decorateClass([
  query("#input")
], UUIBooleanInputElement.prototype, "_input", 2);

export { UUIBooleanInputElement, UUIBooleanInputEvent };
