import { UUIRefNodeElement } from '@umbraco-ui/uui-ref-node/lib';
import { defineElement } from '@umbraco-ui/uui-base/lib/registration';
import { html } from 'lit';
import { property } from 'lit/decorators.js';

var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp(target, key, result);
  return result;
};
let UUIRefNodeDocumentTypeElement = class extends UUIRefNodeElement {
  constructor() {
    super(...arguments);
    this.fallbackIcon = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M49.035 60.434h413.93v33.592H49.035zm0 82.005h86.578v86.577H49.035zm163.677 0h86.576v86.577h-86.576zm163.676 0h86.576v86.577h-86.576zM49.035 282.984h413.93v33.593H49.035zm0 82.006h86.578v86.576H49.035zm163.677 0h86.576v86.576h-86.576zm163.676 0h86.576v86.576h-86.576z"></path></svg>';
    this.alias = "";
  }
  renderDetail() {
    const details = [];
    if (this.alias !== "") {
      details.push(this.alias);
    }
    if (this.detail !== "") {
      details.push(this.detail);
    }
    return html`<small id="detail"
      >${details.join(" | ")}<slot name="detail"></slot
    ></small>`;
  }
};
UUIRefNodeDocumentTypeElement.styles = [...UUIRefNodeElement.styles];
__decorateClass([
  property({ type: String })
], UUIRefNodeDocumentTypeElement.prototype, "alias", 2);
UUIRefNodeDocumentTypeElement = __decorateClass([
  defineElement("uui-ref-node-document-type")
], UUIRefNodeDocumentTypeElement);

export { UUIRefNodeDocumentTypeElement };
