import { defineElement } from '@umbraco-ui/uui-base/lib/registration';
import { demandCustomElement } from '@umbraco-ui/uui-base/lib/utils';
import { css, LitElement, html } from 'lit';
import { property } from 'lit/decorators.js';
import { iconPicture } from '@umbraco-ui/uui-icon-registry-essential/lib/svgs';

var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp(target, key, result);
  return result;
};
let UUISymbolFileThumbnailElement = class extends LitElement {
  constructor() {
    super(...arguments);
    this.src = "";
    this.alt = "";
  }
  connectedCallback() {
    super.connectedCallback();
    demandCustomElement(this, "uui-icon");
  }
  render() {
    return this.src ? html`<img src=${this.src} alt=${this.alt} />` : html`<uui-icon
          name="picture"
          .fallback=${iconPicture.strings[0]}></uui-icon>`;
  }
};
UUISymbolFileThumbnailElement.styles = [
  css`
      :host {
        display: block;
        width: 100%;
        height: 100%;
      }

      img {
        object-fit: contain;
        height: 100%;
        width: 100%;
      }

      uui-icon {
        width: 100%;
        height: 100%;
        max-width: 100%;
        display: flex;
        max-height: 100%;
        justify-content: center;
        color: var(--uui-color-surface,#fff);
        background: var(--uui-color-surface-alt,#f3f3f5);
      }
    `
];
__decorateClass([
  property({ type: String })
], UUISymbolFileThumbnailElement.prototype, "src", 2);
__decorateClass([
  property({ type: String })
], UUISymbolFileThumbnailElement.prototype, "alt", 2);
UUISymbolFileThumbnailElement = __decorateClass([
  defineElement("uui-symbol-file-thumbnail")
], UUISymbolFileThumbnailElement);

export { UUISymbolFileThumbnailElement };
