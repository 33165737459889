import { css, LitElement, html } from 'lit';
import { defineElement } from '@umbraco-ui/uui-base/lib/registration';
import { property } from 'lit/decorators.js';
import { styleMap } from 'lit/directives/style-map.js';

var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp(target, key, result);
  return result;
};
const clamp = (num, min, max) => Math.min(Math.max(num, min), max);
let UUIProgressBarElement = class extends LitElement {
  constructor() {
    super(...arguments);
    this._progress = 0;
  }
  get progress() {
    return this._progress;
  }
  set progress(newVal) {
    const oldVal = this._progress;
    this._progress = clamp(newVal, 0, 100);
    this.requestUpdate("progress", oldVal);
  }
  _getProgressStyle() {
    return { width: `${this._progress}%` };
  }
  render() {
    return html`
      <div id="bar" style=${styleMap(this._getProgressStyle())}></div>
    `;
  }
};
UUIProgressBarElement.styles = [
  css`
      :host {
        width: 100%;
        height: 4px;
        position: relative;
        overflow: hidden;
        background: var(--uui-color-surface-alt,#f3f3f5);
        border-radius: 100px;
        display: inline-block;
      }

      #bar {
        transition: width 250ms ease;
        background: var(--uui-color-positive,#0b8152);
        height: 100%;
        width: 0%;
      }
    `
];
__decorateClass([
  property({ type: Number })
], UUIProgressBarElement.prototype, "progress", 1);
UUIProgressBarElement = __decorateClass([
  defineElement("uui-progress-bar")
], UUIProgressBarElement);

export { UUIProgressBarElement };
