import { defineElement } from '@umbraco-ui/uui-base/lib/registration';
import { css, LitElement, html } from 'lit';
import { property, state } from 'lit/decorators.js';

var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp(target, key, result);
  return result;
};
let UUIAvatarElement = class extends LitElement {
  constructor() {
    super(...arguments);
    this.overflow = true;
    this.imgSrc = "";
    this.imgSrcset = "";
    this._name = "";
    this.initials = "";
  }
  get name() {
    return this._name;
  }
  set name(newVal) {
    const oldValue = this._name;
    this._name = newVal;
    this.initials = this.createInitials(this._name);
    this.requestUpdate("title", oldValue);
  }
  connectedCallback() {
    super.connectedCallback();
    if (!this.name) {
      console.warn(this.tagName + " needs a `name`", this);
    }
  }
  createInitials(name) {
    let initials = "";
    if (!name) {
      return initials;
    }
    const words = name.match(/(\w+)/g);
    if (!words?.length) {
      return initials;
    }
    initials = words[0].substring(0, 1);
    if (words.length > 1) {
      initials += words[words.length - 1].substring(0, 1);
    }
    return initials.toUpperCase();
  }
  renderImage() {
    return html` <img
      src="${this.imgSrc}"
      srcset="${this.imgSrcset}"
      alt="${this.initials}"
      title="${this.name}" />`;
  }
  render() {
    return html`
      ${this.imgSrc ? this.renderImage() : ""}
      ${!this.imgSrc ? this.initials : ""}
      <slot></slot>
    `;
  }
};
UUIAvatarElement.styles = [
  css`
      :host {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
        overflow: hidden;
        border-radius: 50%;
        font-weight: 700;
        -webkit-font-smoothing: subpixel-antialiased;
        width: calc(2em + 4px);
        height: calc(2em + 4px);
        user-select: none;
        /* box-sizing: border-box; */
        aspect-ratio: 1;
        background-color: var(--uui-palette-spanish-pink,#f5c1bc);
        color: var(--uui-palette-space-cadet,#1b264f);
      }

      :host([overflow]) {
        overflow: unset;
      }

      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
        overflow: hidden;
        border-radius: 50%;
      }
    `
];
__decorateClass([
  property({ type: Boolean, attribute: true, reflect: true })
], UUIAvatarElement.prototype, "overflow", 2);
__decorateClass([
  property({ type: String, attribute: "img-src" })
], UUIAvatarElement.prototype, "imgSrc", 2);
__decorateClass([
  property({ type: String, attribute: "img-srcset" })
], UUIAvatarElement.prototype, "imgSrcset", 2);
__decorateClass([
  property({ type: String, reflect: true })
], UUIAvatarElement.prototype, "name", 1);
__decorateClass([
  state()
], UUIAvatarElement.prototype, "initials", 2);
UUIAvatarElement = __decorateClass([
  defineElement("uui-avatar")
], UUIAvatarElement);

export { UUIAvatarElement };
