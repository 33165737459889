import { defineElement } from '@umbraco-ui/uui-base/lib/registration';
import { demandCustomElement } from '@umbraco-ui/uui-base/lib/utils';
import { css, LitElement, html } from 'lit';
import { property, state } from 'lit/decorators.js';

var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp(target, key, result);
  return result;
};
let UUIFormLayoutItemElement = class extends LitElement {
  constructor() {
    super(...arguments);
    this.description = null;
    this._labelSlotHasContent = false;
    this._labelSlotChanged = (e) => {
      this._labelSlotHasContent = e.target.assignedNodes({ flatten: true }).length > 0;
    };
    this._descriptionSlotHasContent = false;
    this._descriptionSlotChanged = (e) => {
      this._descriptionSlotHasContent = e.target.assignedNodes({ flatten: true }).length > 0;
    };
  }
  connectedCallback() {
    super.connectedCallback();
    demandCustomElement(this, "uui-form-validation-message");
  }
  render() {
    return html`
      <div id="label" style=${this._labelSlotHasContent ? "" : "display: none"}>
        <slot name="label" @slotchange=${this._labelSlotChanged}></slot>
      </div>
      <div
        id="description"
        style=${this._descriptionSlotHasContent || this.description !== null ? "" : "display: none"}>
        ${this.description}
        <slot
          name="description"
          @slotchange=${this._descriptionSlotChanged}></slot>
      </div>
      <uui-form-validation-message>
        <slot></slot>
        <slot name="message" slot="message"></slot>
      </uui-form-validation-message>
    `;
  }
};
UUIFormLayoutItemElement.styles = [
  css`
      :host {
        position: relative;
        display: block;
        margin-top: var(--uui-size-space-5,18px);
        margin-bottom: var(--uui-size-space-5,18px);
      }
      #label {
        margin-top: -5px;
        margin-bottom: 5px;
      }
      #description {
        color: var(--uui-color-disabled-contrast,#c4c4c4);
        font-size: var(--uui-type-small-size,12px);
      }
      #label + #description {
        margin-top: -8px;
        min-height: 8px;
      }
    `
];
__decorateClass([
  property({ type: String })
], UUIFormLayoutItemElement.prototype, "description", 2);
__decorateClass([
  state()
], UUIFormLayoutItemElement.prototype, "_labelSlotHasContent", 2);
__decorateClass([
  state()
], UUIFormLayoutItemElement.prototype, "_descriptionSlotHasContent", 2);
UUIFormLayoutItemElement = __decorateClass([
  defineElement("uui-form-layout-item")
], UUIFormLayoutItemElement);

export { UUIFormLayoutItemElement };
