import { defineElement } from '@umbraco-ui/uui-base/lib/registration';
import { UUICardElement } from '@umbraco-ui/uui-card/lib';
import { css, html, nothing } from 'lit';
import { property } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { styleMap } from 'lit/directives/style-map.js';

var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __typeError = (msg) => {
  throw TypeError(msg);
};
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp(target, key, result);
  return result;
};
var __accessCheck = (obj, member, msg) => member.has(obj) || __typeError("Cannot " + msg);
var __privateAdd = (obj, member, value) => member.has(obj) ? __typeError("Cannot add the same private member more than once") : member instanceof WeakSet ? member.add(obj) : member.set(obj, value);
var __privateMethod = (obj, member, method) => (__accessCheck(obj, member, "access private method"), method);
var _UUICardBlockTypeElement_instances, renderButton_fn, renderLink_fn;
let UUICardBlockTypeElement = class extends UUICardElement {
  constructor() {
    super(...arguments);
    __privateAdd(this, _UUICardBlockTypeElement_instances);
    this.name = "";
  }
  render() {
    return html`
      <div
        id="portrait"
        style=${styleMap({ backgroundColor: this.background })}>
        <slot></slot>
      </div>
      ${this.href ? __privateMethod(this, _UUICardBlockTypeElement_instances, renderLink_fn).call(this) : __privateMethod(this, _UUICardBlockTypeElement_instances, renderButton_fn).call(this)}

      <slot name="tag"></slot>
      <slot name="actions"></slot>
    `;
  }
};
_UUICardBlockTypeElement_instances = new WeakSet();
renderButton_fn = function() {
  return html`
      <button
        id="open-part"
        tabindex=${this.disabled ? nothing : "0"}
        @click=${this.handleOpenClick}
        @keydown=${this.handleOpenKeydown}>
        <strong>${this.name}</strong><small>${this.description}</small>
      </button>
    `;
};
renderLink_fn = function() {
  return html`
      <a
        id="open-part"
        tabindex=${this.disabled ? nothing : "0"}
        href=${ifDefined(!this.disabled ? this.href : void 0)}
        target=${ifDefined(this.target || void 0)}
        rel=${ifDefined(
    this.rel || ifDefined(
      this.target === "_blank" ? "noopener noreferrer" : void 0
    )
  )}>
        <strong>${this.name}</strong><small>${this.description}</small>
      </a>
    `;
};
UUICardBlockTypeElement.styles = [
  ...UUICardElement.styles,
  css`
      :host {
        flex-direction: column;
        justify-content: flex-start;
      }

      :host(:hover) #info {
        color: var(--uui-color-interactive-emphasis,#3544b1);
      }

      #portrait {
        background-color: var(--uui-color-surface-alt,#f3f3f5);
        display: flex;
        justify-content: center;
        min-height: 150px;
        max-height: 150px;
      }

      slot:not([name])::slotted(*) {
        align-self: center;
        font-size: var(--uui-size-8,24px);
        border-radius: var(--uui-border-radius,3px);
        object-fit: cover;
        max-width: 100%;
        max-height: 100%;
      }

      #open-part {
        text-align: left;
        background-color: var(--uui-color-surface,#fff);
        cursor: pointer;
        color: var(--uui-color-interactive,#1b264f);
        border: none;
        border-top: 1px solid var(--uui-color-divider,#f6f6f7);
        border-radius: 0 0 var(--uui-border-radius,3px) var(--uui-border-radius,3px);
        font-family: inherit;
        font-size: var(--uui-type-small-size,12px);
        box-sizing: border-box;
        padding: var(--uui-size-2,6px) var(--uui-size-4,12px);
        display: flex;
        flex-direction: column;
        line-height: var(--uui-size-6,18px);
      }

      :host([disabled]) #open-part {
        pointer-events: none;
        background: var(--uui-color-disabled,#f3f3f5);
        color: var(--uui-color-contrast-disabled);
      }

      #open-part:hover strong {
        text-decoration: underline;
      }
      #open-part:hover {
        color: var(--uui-color-interactive-emphasis,#3544b1);
      }

      :host([image]:not([image=''])) #open-part {
        transition: opacity 0.5s 0.5s;
        opacity: 0;
      }
      slot[name='tag'] {
        position: absolute;
        top: var(--uui-size-4,12px);
        right: var(--uui-size-4,12px);
        display: flex;
        justify-content: right;
      }

      slot[name='actions'] {
        position: absolute;
        top: var(--uui-size-4,12px);
        right: var(--uui-size-4,12px);
        display: flex;
        justify-content: right;

        opacity: 0;
        transition: opacity 120ms;
      }
      :host(:focus) slot[name='actions'],
      :host(:focus-within) slot[name='actions'],
      :host(:hover) slot[name='actions'] {
        opacity: 1;
      }

      :host(
          [image]:not([image='']):hover,
          [image]:not([image='']):focus,
          [image]:not([image='']):focus-within,
          [selected][image]:not([image='']),
          [error][image]:not([image=''])
        )
        #open-part {
        opacity: 1;
        transition-duration: 120ms;
        transition-delay: 0s;
      }
    `
];
__decorateClass([
  property({ type: String })
], UUICardBlockTypeElement.prototype, "name", 2);
__decorateClass([
  property({ type: String })
], UUICardBlockTypeElement.prototype, "description", 2);
__decorateClass([
  property({ type: String, attribute: "background" })
], UUICardBlockTypeElement.prototype, "background", 2);
UUICardBlockTypeElement = __decorateClass([
  defineElement("uui-card-block-type")
], UUICardBlockTypeElement);

export { UUICardBlockTypeElement };
