import { defineElement } from '@umbraco-ui/uui-base/lib/registration';
import { demandCustomElement } from '@umbraco-ui/uui-base/lib/utils';
import { UUICardElement } from '@umbraco-ui/uui-card/lib';
import { css, html, nothing } from 'lit';
import { property, state } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';

var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __typeError = (msg) => {
  throw TypeError(msg);
};
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp(target, key, result);
  return result;
};
var __accessCheck = (obj, member, msg) => member.has(obj) || __typeError("Cannot " + msg);
var __privateAdd = (obj, member, value) => member.has(obj) ? __typeError("Cannot add the same private member more than once") : member instanceof WeakSet ? member.add(obj) : member.set(obj, value);
var __privateMethod = (obj, member, method) => (__accessCheck(obj, member, "access private method"), method);
var _UUICardContentNodeElement_instances, renderButton_fn, renderLink_fn;
let UUICardContentNodeElement = class extends UUICardElement {
  constructor() {
    super(...arguments);
    __privateAdd(this, _UUICardContentNodeElement_instances);
    this.name = "";
    this._iconSlotHasContent = false;
    this.fallbackIcon = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M396.441 138.878l-83.997-83.993-7.331-7.333H105.702v416.701h298.071V146.214l-7.332-7.336zM130.74 439.217V72.591h141.613c37.201 0 19.274 88.18 19.274 88.18s86-20.901 87.104 18.534v259.912H130.74z"></path></svg>';
  }
  _onSlotIconChange(event) {
    this._iconSlotHasContent = event.target.assignedNodes({ flatten: true }).length > 0;
  }
  _renderFallbackIcon() {
    demandCustomElement(this, "uui-icon");
    return html`<uui-icon .svg="${this.fallbackIcon}"></uui-icon>`;
  }
  render() {
    return html`
      ${this.href ? __privateMethod(this, _UUICardContentNodeElement_instances, renderLink_fn).call(this) : __privateMethod(this, _UUICardContentNodeElement_instances, renderButton_fn).call(this)}
      <!-- Select border must be right after #open-part -->
      <div id="select-border"></div>

      <slot></slot>
      <slot name="tag"></slot>
      <slot name="actions"></slot>
    `;
  }
};
_UUICardContentNodeElement_instances = new WeakSet();
renderButton_fn = function() {
  return html`<div
      id="open-part"
      tabindex=${this.disabled ? nothing : 0}
      @click=${this.handleOpenClick}
      @keydown=${this.handleOpenKeydown}>
      <span id="icon">
        <slot name="icon" @slotchange=${this._onSlotIconChange}></slot>
        ${this._iconSlotHasContent === false ? this._renderFallbackIcon() : ""}
      </span>
      <span id="name"> ${this.name} </span>
    </div>`;
};
renderLink_fn = function() {
  return html`<a
      id="open-part"
      tabindex=${this.disabled ? nothing : 0}
      href=${ifDefined(!this.disabled ? this.href : void 0)}
      target=${ifDefined(this.target || void 0)}
      rel=${ifDefined(
    this.rel || ifDefined(
      this.target === "_blank" ? "noopener noreferrer" : void 0
    )
  )}>
      <span id="icon">
        <slot name="icon" @slotchange=${this._onSlotIconChange}></slot>
        ${this._iconSlotHasContent === false ? this._renderFallbackIcon() : ""}
      </span>
      <span id="name"> ${this.name} </span>
    </a>`;
};
UUICardContentNodeElement.styles = [
  ...UUICardElement.styles,
  css`
      :host {
        min-width: 250px;
        flex-direction: column;
        justify-content: space-between;
        padding: var(--uui-size-3,9px) var(--uui-size-4,12px);
      }

      slot[name='tag'] {
        position: absolute;
        top: var(--uui-size-4,12px);
        right: var(--uui-size-4,12px);
        display: flex;
        justify-content: right;
      }

      slot[name='actions'] {
        position: absolute;
        top: var(--uui-size-4,12px);
        right: var(--uui-size-4,12px);
        display: flex;
        justify-content: right;

        opacity: 0;
        transition: opacity 120ms;
      }
      :host(:focus) slot[name='actions'],
      :host(:focus-within) slot[name='actions'],
      :host(:hover) slot[name='actions'] {
        opacity: 1;
      }

      slot:not([name]) {
        display: block;
        margin: var(--uui-size-1,3px);
        margin-top: var(--uui-size-3,9px);
      }

      slot:not([name])::slotted(*) {
        font-size: var(--uui-type-small-size,12px);
        line-height: calc(2 * var(--uui-size-3,9px));
      }

      #icon {
        font-size: 1.2em;
        margin-right: var(--uui-size-1,3px);
      }

      #open-part {
        display: flex;
        position: relative;
        font-weight: 700;
        align-items: center;
        cursor: pointer;
      }

      :host([disabled]) #open-part {
        pointer-events: none;
      }

      #open-part:hover {
        text-decoration: underline;
        color: var(--uui-color-interactive-emphasis,#3544b1);
      }

      #name {
        margin-top: 4px;
      }
    `
];
__decorateClass([
  property({ type: String })
], UUICardContentNodeElement.prototype, "name", 2);
__decorateClass([
  state()
], UUICardContentNodeElement.prototype, "_iconSlotHasContent", 2);
UUICardContentNodeElement = __decorateClass([
  defineElement("uui-card-content-node")
], UUICardContentNodeElement);

export { UUICardContentNodeElement };
