import { css, LitElement, html } from 'lit';
import { defineElement } from '@umbraco-ui/uui-base/lib/registration';
import { property } from 'lit/decorators.js';

var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp(target, key, result);
  return result;
};
let UUISymbolFileElement = class extends LitElement {
  constructor() {
    super(...arguments);
    this.type = "";
  }
  render() {
    return html`<svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        width="100%"
        id="icon">
        <path
          d="M396.441 138.878l-83.997-83.993-7.331-7.333H105.702v416.701h298.071V146.214l-7.332-7.336zM130.74 439.217V72.591h141.613c37.201 0 19.274 88.18 19.274 88.18s86-20.901 87.104 18.534v259.912H130.74z" />
      </svg>
      ${this.type ? html`<span id="file-type">${this.type.toUpperCase()}</span>` : ""} `;
  }
};
UUISymbolFileElement.styles = [
  css`
      :host {
        position: relative;
        display: block;
      }

      #file-type {
        position: absolute;
        bottom: 24%;
        left: 25.5%;
        margin-left: calc(var(--uui-size-3,9px) * -1);
        padding: 0px var(--uui-size-3,9px);
        font-weight: 700;
        background-color: var(--uui-color-surface-alt,#f3f3f5);
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      #icon {
        fill: var(--uui-color-border-standalone,#c2c2c2);
      }
    `
];
__decorateClass([
  property({ type: String })
], UUISymbolFileElement.prototype, "type", 2);
UUISymbolFileElement = __decorateClass([
  defineElement("uui-symbol-file")
], UUISymbolFileElement);

export { UUISymbolFileElement };
